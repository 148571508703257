import React from 'react';
import { Box, Typography } from '@mui/material';
import './ManageAppointmentCard.css';

interface AppointmentCardProps {
  imageSrc: string;
  title: string;
  date: string;
  location: any;
}
const ManageAppointmentCard: React.FC<AppointmentCardProps> = ({
  imageSrc,
  title,
  date,
  location,
}) => {
  return (
    <Box className="appointment-card">
      <Box className="card-content">
        <Box className="image-container">
          <img
            loading="lazy"
            src={imageSrc}
            alt={title}
            className="card-image"
          />
        </Box>
        <Box className="details-container">
          <Typography variant="h6" className="card-title">
            {title}
          </Typography>
          <Typography variant="body2" className="card-date">
            {date}
          </Typography>
          {location && (
            <Typography variant="body2" className="card-location">
              {location?.address_line_1 +
                ', ' +
                location?.address_line_2 +
                ', ' +
                location?.city_name +
                ', ' +
                location?.country_name}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ManageAppointmentCard;
