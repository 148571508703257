import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Grid,
  ListItemText,
  Checkbox,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import SearchItems from './SearchItems';
import { Map } from './Map';
import toast from 'react-hot-toast';
import { images } from '../../../src/Assets/images';
import { icons } from '../../Assets/icons';
import StarIcon from '@mui/icons-material/Star'; // MUI Star icon

import { getVendorListingReqInterface } from '../../../src/api/types/dashboard';
import useAppContext from '../../../src/hooks/useAppContext';
import './Search.css';
import './Map.css'; // Importing the external CSS for styling
import Slider from '@mui/material/Slider';
import { SelectChangeEvent } from '@mui/material/Select'; // Import this
import { getUserLocation } from '../../Common/CommonFunctions/common';

interface CategoryList {
  id: number;
  name: string;
  logo_link: string;
  sequence: number;
  is_premium_category: number;
}
interface MapProps {
  itemData: any; // Replace `any` with the actual type of `itemData` for better type safety
}
interface MapData {
  vendor_list: { data: any }[];
}
/* Map Import  */
const Search = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  //const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const { getVenorListingFunc } = useAppContext();
  const [dashboardData, setDashboardData] = useState<any>(null);
  const [mapData, setMapData] = useState<MapData | null>(null);
  const location = useLocation();
  const [customerDashboardListing, setCustomerDashboardListing] =
    useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const searchReq = location.state?.searchReq;
  const [selectedCategory, setSelectedCategory] = useState(
    searchReq?.category_id || '',
  );
  const hasMounted = useRef(false); // Track if component has mounted
  const [radius, setRadius] = useState('');
  const [rates, setRates] = useState( '');
  const [price, setPrice] = useState('');
  const [nearest, setNearest] = useState(false);
  const [sliderDisabled, setSliderDisabled] = useState(false);
  const apiBaseUrl =
    process.env.REACT_APP_API_BASE_URL + '/get-categories?use_for=2';
  //const [genderBy, setGenderBy] = useState('');
  const [genderBy, setGenderBy] = useState<string[]>([]);
  const [nearBy, setNearBy] = useState('');
  const [priceRange, setPriceRange] = useState<number[]>([0, 25000]);
  const [newData, setNewData] = useState({});
  const getInitialDashboardDataReq = () => ({
    search_keyword: searchReq?.search_keyword || '',
    //sort_by: 0,
    top_rated: '',
    //nearest: 0,
    near_by: '',
    salon_type: searchReq?.salon_type || 0,
    category_id: 0,
    professional_keyword: '',
    gender_type: '',
    min_price: 0,
    max_price: 0,
    latitude: searchReq?.latitude || 0,
    longitude: searchReq?.longitude || 0,
    rates: '',
    radius: '',
  });
  const [dashboardDataReq, setDashboardDataReq] =
    useState<getVendorListingReqInterface>(getInitialDashboardDataReq);
  const [catdata, setCatData] = useState<CategoryList[]>([]);
  const [plotData, setPlotData] = useState([]);
  const [locationAllowed, setLocationAllowed] = useState<boolean | null>(null);

  useEffect(() => {
    document.body.scrollTop = 0; // For most browsers
    document.documentElement.scrollTop = 0; // For Firefox
  }, []);
  // useEffect(() => {
  //   if (searchReq?.latitude && searchReq?.longitude) {
  //     setMapData(prevMapData => ({
  //       ...prevMapData,
  //       vendor_list: prevMapData?.vendor_list || [],
  //       latitude: searchReq.latitude,
  //       longitude: searchReq.longitude,
  //       page: currentPage,
  //       limit: 10,
  //     }));
  //   }
  // }, [searchReq]);
  const fetchSearchResults = async (data: getVendorListingReqInterface) => {
    setLoading(true);
    let requestData: getVendorListingReqInterface & {
      page?: number;
      limit?: number;
      in_app: 1;
    };
    if (searchReq.search_keyword === '') {
      requestData = {
        ...data,
        page: currentPage,
        limit: 10,
        in_app: 1,
      };
    } else {
      requestData = {
        ...data,
        page: currentPage,
        limit: 10,
        in_app: 1,
      };
    }
    const result: any = await getVenorListingFunc(requestData);
    if (result === null) {
      toast.error('Failed to filter data.');
      return;
    }
    if (result?.status_code === 200 && result?.data) {
      /* for glam9 */
      let dataObject: any = {
        vendor_list: [
          {
            title: `${result?.data?.total}`,
            data: result?.data?.data,
          },
        ],
      };
     
      
      /* for vreserv */
      // let dataObject: any = {
      //   vendor_list: [
      //     {
      //       title: `${result?.data?.total_records}`,
      //       data: result?.data?.records,
      //     },
      //   ],
      // };
      setCustomerDashboardListing(result?.data?.records);
      setTotalPages(result.data.total_pages || 1);
      setTotalRecords(result.data.total_records || 0);

      setLoading(false);
    } else {
      let dataObject: any = {
        vendor_list: [
          {
            title: `0`,
            data: [],
          },
        ],
      };
      setLoading(false);
      setDashboardData(dataObject);
    }
  };

  useEffect(() => {
    const searchReqFromState = location.state?.searchReq;
    if (searchReqFromState) {
      let genderType = searchReqFromState?.gender_type?.trim()
        ? searchReqFromState.gender_type.split(',')
        : [];
      setSelectedCategory(searchReqFromState.category_id || '');
      setGenderBy(searchReqFromState.gender_type || '');
      setGenderBy(genderType);
      setNearBy(searchReqFromState.top_rated || '');
      setRadius(searchReqFromState.radius || '');
      setRates(searchReqFromState.rates || '');
      setPriceRange([
        searchReqFromState.min_price || 0,
        searchReqFromState.max_price || 25000,
      ]);
      setCurrentPage(searchReqFromState.page || 1); // Set the current page from the state
      fetchSearchResults(searchReqFromState); // Fetch results with the updated searchReq
    }
  }, [location.state]);

  useEffect(() => {
    if (searchReq) {
      fetchSearchResults(searchReq);
      setCurrentPage.toString();
    } 
  }, [searchReq, currentPage]);

  // for glam9 build
  const backToHome = () => {
    setDashboardData(null);
    setMapData(null);
    navigate('/');
    setTimeout(() => {
      const section = document.getElementById('height');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  // for vreserv build
  // const backToHome = () => {
  //   setDashboardData(null);
  //   setMapData(null);
  //   navigate('/home');
  //   setTimeout(() => {
  //     const section = document.getElementById('height');
  //     if (section) {
  //       section.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }, 0);
  // };

  const handleCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value);
    setSliderDisabled(false);
    if (event.target.value == '') {
      setPriceRange([0, 25000]);
    }
  };
  const handleRatesChange = (event: any) => {
    setRates(event.target.value); // Update state when an option is selected
  };
  const handleRadiusChange = (event: any) => {
    setRadius(event.target.value); // Update state when an option is selected
  };
  const handlePriceChange = (event: SelectChangeEvent<string>) => {
    // if (!selectedCategory) {
    //   toast.error('Please select a category first.');
    //   setSliderDisabled(true);
    // }
    const selectedValue = event.target.value;
    setPrice(selectedValue); // Update the selected price in state

    // Define the min and max price based on the selected value
    let minPrice = 0;
    let maxPrice = 0;

    switch (selectedValue) {
      case '1':
        minPrice = 1;
        maxPrice = 5000;
        break;
      case '2':
        minPrice = 5000;
        maxPrice = 10000;
        break;
      case '3':
        minPrice = 10000;
        maxPrice = 15000;
        break;
      case '4':
        minPrice = 15000;
        maxPrice = 20000;
        break;
      case '5':
        minPrice = 20000;
        maxPrice = 25000;
        break;
      default:
        minPrice = 0;
        maxPrice = 0;
    }

    // Update the state with the selected price range
    setDashboardDataReq(prevState => ({
      ...prevState,
      min_price: minPrice,
      max_price: maxPrice,
    }));
  };

  const handleGenderChange = (event: any) => {
    //setGenderBy(event.target.value); // Update state when an option is selected
    const value = event.target.value;
    setGenderBy(typeof value === 'string' ? value.split(',') : value); // Update state when an option is selected
  };

  const handleNearBy = (event: any) => {
    setNearBy(event.target.value); // Update state when an option is selected
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (!selectedCategory) {
      return;
    }

    setPriceRange(newValue as number[]);
    // Use the newValue directly to update the dashboardDataReq
    setDashboardDataReq(prevState => ({
      ...prevState,
      min_price: (newValue as number[])[0],
      max_price: (newValue as number[])[1],
    }));
  };

  const handleMouseDown = () => {
    if (!selectedCategory) {
      toast.error('Please select a category first.');
      setSliderDisabled(true);
    }
  };

  const clearFilters = async () => {
    const resetData = getInitialDashboardDataReq(); // Get fresh initial state
    const { lat, long } = await getUserLocation(); 
    // Define finalResetData with required parameters
    const finalResetData: getVendorListingReqInterface = {
      ...resetData, // Preserve existing values
      category_id: 0,
      gender_type: '',
      top_rated: '',
      in_app: '1', // Ensure in_app is set
      radius: '',
      rates: '',
      near_by: '',
      page: 1, // Reset to page 1
      professional_keyword: '',
      salon_type: 0, // Ensure salon_type is a number
      min_price: 0,
      max_price: 0,
      latitude: lat || searchReq?.latitude || 0, // Preserve existing latitude if available
      longitude: long || searchReq?.longitude || 0, // Preserve existing longitude if available
      limit: 10, // Ensure limit is set to 10
      service_percentage: '',
      search_keyword: resetData.search_keyword || '', // Preserve or reset search_keyword
    };
  
    // ✅ Create a filtered version instead of mutating the object
    const cleanedData = Object.fromEntries(
      Object.entries(finalResetData).filter(([key, value]) =>
        key !== "search_keyword" && // Exclude search_keyword
        (["latitude", "longitude", "in_app", "page", "limit"].includes(key) || (value !== "" && value !== 0))
      )
    );
  
    console.log("Final Reset Data:", cleanedData);
  
    // ✅ Update state and navigate with the cleaned-up data
    setNewData(cleanedData as getVendorListingReqInterface);
    setPrice("");
    setRadius("");
    setDashboardDataReq(cleanedData as getVendorListingReqInterface); // Use cleaned data
    navigate("/search", {
      state: {
        searchReq: cleanedData, // Pass cleanedData to the search page
      },
    });
  };
  
  const minMarks = [
    {
      value: 0,
      label: '₹0',
    },
    {
      value: 25000,
      label: '₹25000',
    },
  ];

  useEffect(() => {
    navigator.permissions
      .query({ name: 'geolocation' })
      .then(result => {
        setLocationAllowed(result.state === 'granted');

        result.onchange = () => {
          setLocationAllowed(result.state === 'granted');
        };
      })
      .catch(() => setLocationAllowed(false));
  }, []);

  const vendorListingFilter22 = useCallback(
    async (data: getVendorListingReqInterface) => {
      try {
        setLoading(true); // Start loading
        let updatedData: any = {
          ...data,
          category_id: selectedCategory || 0,
          gender_type: genderBy.join(','), // Gender filter
          top_rated: nearBy === '1' ? '1' : '',
          in_app: '1',
          radius: radius,
          rates: rates,
          near_by: nearBy === '3' ? '2' : '',
          //page: currentPage,
          page: 1,
          limit: '10',
          service_percentage: '', // Price range filter
        };
  
        // Remove any keys that have an empty string value
        Object.keys(updatedData).forEach(
          (key) => (updatedData[key] === "" || updatedData[key] === 0) && delete updatedData[key]
        );
  
        setNewData(updatedData);
        const result: any = await getVenorListingFunc(updatedData);
        if (result === null) {
          toast.error('Failed to filter data.');
          return;
        }

        if (result?.status_code === 200 && result?.data) {
          setLoading(false);
          const dataObject = {
            vendor_list: [{ data: result?.data?.records }],
          };
          setCustomerDashboardListing(result?.data?.records);
          setTotalPages(result.data.total_pages || 1);
          setCurrentPage(result.data.current_page || 1);
          setTotalRecords(result.data.total_records || 0);
        } else {
          setLoading(false);
          setCustomerDashboardListing([]);
          setTotalRecords(0);
          setCurrentPage(1);
          toast.error(result?.message);
        }
      } catch (error) {
        setLoading(false);
        console.error('Error filtering vendor list:', error);
      }
    },
    [
      getVenorListingFunc,
      selectedCategory,
      mapData,
      genderBy,
      rates,
      radius,
      nearBy,
      priceRange[0],
      priceRange[1],
    ],
  );
  // Category list data get
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiBaseUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setCatData(result.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []); // Run this only once on mount
  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true; // Mark as mounted
      return; // Skip first render
    }
  
    const updateLocationAndFetch = async () => {
      let updatedData = { ...dashboardDataReq };

      // If latitude is 0, fetch user location
      if (dashboardDataReq.latitude === 0 && locationAllowed) {
        try {
          const { lat, long } = await getUserLocation();
          updatedData.latitude = lat; // ✅ Keep as number
          updatedData.longitude = long; // ✅ Keep as number
        } catch (error) {
          console.error('Failed to fetch user location:', error);
          return; // Exit if location fetch fails
        }
      }

      // Call API with updated latitude/longitude
      vendorListingFilter22(updatedData);
    };

    if (dashboardDataReq) {
      updateLocationAndFetch();
    }
  }, [nearBy]); // Runs whenever `nearBy` changes

  useEffect(() => {
    if (locationAllowed === true) {
      const timer = setTimeout(() => {
        setIsLoading(false); // Hide loader after 5 seconds
      }, 2000);
      return () => clearTimeout(timer); // Cleanup the timer
    } else {
      const timer = setTimeout(() => {
        setIsLoading(false); // Hide loader after 5 seconds
      }, 2000);
      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [isLoading]);
  const renderStars = (rating: number) => {
    return Array.from({ length: rating }, (_, index) => (
      <StarIcon key={index} sx={{ color: '#FFD700', fontSize: '16px' }} />
    ));
  };
  /* Map Code */
  if (isLoading) {
    return (
      <div className="loaderCcontainer">
        <img src={images.loader} />
      </div>
    );
  } else {
    return (
      <Box className="search">
        <Grid container spacing={2} className="grid-container">
          <Grid
            item
            xs={12}
            md={4.5}
            xl={4}
            sm={12}
            lg={4}
            className="scrollableLeft"
          >
            <div className="searchResultContainer">
              <div className="searchLeftWrap">
                <div className="backButton">
                  <img onClick={backToHome} src={icons.left} alt="home" />
                </div>
                <div className="resultMessage">
                  Showing <span>{totalRecords}</span> results for your selection
                </div>
              </div>
              <div className="sortByWrap">
                <FormControl className="sortBy">
                  <Select
                    value={nearBy}
                    onChange={handleNearBy}
                    displayEmpty
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        style: { maxHeight: 200 },
                      },
                    }}
                    className={`filterCategory ${nearBy ? 'selected' : ''}`}
                    sx={{
                      '& .MuiSelect-select': {
                        color: nearBy ? '#FFFFFF' : '#231f20',
                        background: nearBy ? '#4E297B' : '#FFFFFF',
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Sort By</em>
                    </MenuItem>{' '}
                    {/* Add a placeholder*/}
                    <MenuItem value="1">Top Rated</MenuItem>
                    <MenuItem value="3">Nearest</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            {loading ? (
              <div className="loaderCcontainer">
                <img src={images.loader} />
              </div>
            ) : customerDashboardListing?.length > 0 ? (
              <SearchItems
                dataForPagination={customerDashboardListing}
                setPlotData={setPlotData}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
                updateData={newData ? newData : ''}
              />
            ) : (
              <p>No records found</p>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={7.5}
            sm={12}
            lg={8}
            xl={8}
            className="mapRight"
          >
            <Grid container spacing={2} className="search-grid listingFilters">
              <Grid className="clearFilter" item xs={12} md={4} xl={2} lg={2}>
                <Button onClick={clearFilters}   
                    className={`clearSearch ${!nearBy && !selectedCategory && genderBy.length === 0 && !price && !rates && !radius ? 'disabled' : ''}`}
                    disabled={!nearBy && !selectedCategory && genderBy.length === 0 && !price && !rates && !radius}                                              >
                  Clear All
                </Button>
              </Grid>
              <Grid className="nearByFilter" item xs={12} md={4} xl={2} lg={2}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    //labelId="review-select-label"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    displayEmpty
                    MenuProps={{
                      // Disable scroll locking
                      disableScrollLock: true,
                    }}
                    className={`${selectedCategory ? 'selected' : ''}`}
                    sx={{
                      '& .MuiSelect-select': {
                        color: selectedCategory ? '#FFFFFF' : '#231f20',
                        background: selectedCategory ? '#4E297B' : '#FFFFFF',
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Category</em>
                    </MenuItem>
                    {catdata.length > 0 ? (
                      catdata.map(row => (
                        <MenuItem key={row.id} value={row.id}>
                          {row.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No categories available</MenuItem> // Fallback UI when data is empty
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid className="genderFilter" item xs={12} md={4} xl={2} lg={2}>
                <FormControl variant="outlined" fullWidth>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="sortByGender"
                  >
                    <Select
                      multiple
                      labelId="demo-simple-select-label"
                      value={genderBy}
                      displayEmpty
                      onChange={handleGenderChange}
                      MenuProps={{
                        // Disable scroll locking
                        disableScrollLock: true,
                      }}
                      className={`${genderBy.length > 0 ? 'selected' : ''}`}
                      sx={{
                        '& .MuiSelect-select': {
                          color: genderBy.length > 0 ? '#FFFFFF' : '#231f20',
                          background:
                            genderBy.length > 0 ? '#4E297B' : '#FFFFFF',
                        },
                      }}
                      renderValue={
                        () =>
                          genderBy.length > 0
                            ? `Gender (${genderBy.length})` // Show count
                            : 'Gender' // Placeholder remains
                      }
                    >
                      <MenuItem value="1">
                        <Checkbox checked={genderBy.includes('1')} />
                        <ListItemText primary={'Male'} />
                      </MenuItem>
                      <MenuItem value="2">
                        <Checkbox checked={genderBy.includes('2')} />
                        <ListItemText primary={'Female'} />
                      </MenuItem>
                      <MenuItem value="3">
                        <Checkbox checked={genderBy.includes('3')} />
                        <ListItemText primary={'Unisex'} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </FormControl>
              </Grid>
              <Grid className="priceFilter" item xs={12} md={4} xl={2} lg={2}>
                <>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      labelId="review-select-label"
                      value={price}
                      onChange={handlePriceChange}
                      displayEmpty
                      MenuProps={{
                        // Disable scroll locking
                        disableScrollLock: true,
                      }}
                      className={`${price ? 'selected' : ''}`}
                      sx={{
                        '& .MuiSelect-select': {
                          color: price ? '#FFFFFF' : '#231f20',
                          background: price ? '#4E297B' : '#FFFFFF',
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>Price</em>
                      </MenuItem>
                      <MenuItem value="1">1 - 5 K</MenuItem>
                      <MenuItem value="2">5 K - 10 K</MenuItem>
                      <MenuItem value="3">10 K - 15 K</MenuItem>
                      <MenuItem value="4">15 K - 20 K</MenuItem>
                      <MenuItem value="5">20 K - 25 K</MenuItem>
                    </Select>
                  </FormControl>
                </>
              </Grid>
              <Grid className="reviewsFilter" item xs={12} md={4} xl={2} lg={2}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    // labelId="review-select-label"
                    value={rates}
                    onChange={handleRatesChange}
                    displayEmpty
                    MenuProps={{
                      // Disable scroll locking
                      disableScrollLock: true,
                    }}
                    className={`${rates ? 'selected' : ''}`}
                    sx={{
                      '& .MuiSelect-select': {
                        color: rates ? '#FFFFFF' : '#231f20',
                        background: rates ? '#4E297B' : '#FFFFFF',
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Reviews</em>
                    </MenuItem>
                    {[5,4,3,2,1].map((rating) => (
                      <MenuItem key={rating} value={rating} sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                      {rating} {renderStars(1)} {rating < 5 && ' & above'}
                    </MenuItem>     
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                className="distanceFilter"
                item
                xs={12}
                md={4}
                xl={2}
                lg={2}
              >
                <FormControl variant="outlined" fullWidth>
                  <Select
                    labelId="demo-simple-select-label2"
                    value={radius}
                    className={`${radius ? 'selected' : ''}`}
                    sx={{
                      '& .MuiSelect-select': {
                        color: radius ? '#FFFFFF' : '#231f20',
                        background: radius ? '#4E297B' : '#FFFFFF',
                      },
                    }}
                    onChange={handleRadiusChange}
                    //onChange={handleRadiusChange}
                    displayEmpty
                    MenuProps={{
                      // Disable scroll locking
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem value="">
                      <em>Distance</em>
                    </MenuItem>
                    <MenuItem value="2">2 KM Radius</MenuItem>
                    <MenuItem value="4">4 KM Radius</MenuItem>
                    <MenuItem value="6">6 KM Radius</MenuItem>
                    <MenuItem value="8">8 KM Radius</MenuItem>
                    <MenuItem value="10">10 KM Radius</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid className="searchFilter" item xs={12} md={4} xl={2} lg={2}>
              <Button
                onClick={async () => {
                  try {
                    const { lat, long } = await getUserLocation(); // ✅ Get latitude & longitude

                    vendorListingFilter22({
                      ...dashboardDataReq,
                      latitude: lat, // ✅ Pass latitude
                      longitude: long, // ✅ Pass longitude
                    });
                  } catch (error) {
                    console.error("Failed to get location:", error);
                  }
                }}
                className="filterApply"
              >
                Apply
              </Button>

              </Grid>
              {/* <Grid className="clearFilter" item xs={12} md={4} xl={2} lg={2}>
                <Button onClick={clearFilters} className="mapSearch">
                  Clear
                </Button>
              </Grid> */}
            </Grid>
            <Map itemData={plotData} />
          </Grid>
        </Grid>
      </Box>
    );
  }
};
export default Search;
