import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileSidebar from './profileSidebar';
import AddressSection from './addressSection';
import PaymentMethodsSection from './PaymentMethodsSection';
import SocialLoginsSection from './SocialLoginsSection';
import NotificationsSection from './NotificationsSection';
import DeleteAccountSection from './DeleteAccountSection';
import QuickLinksSection from './QuickLinksSection';
import { Container, Grid, Box } from '@mui/material';
import './ProfilePage.css';
import CommonCookie from '../CommonCookie';
import { useCookies } from 'react-cookie';
import { CustomerAPI } from '../../hooks/customer';
import toast from 'react-hot-toast';
import { images } from '../../Assets/images';
const ProfilePage = () => {
  useEffect(() => {
    document.body.scrollTop = 0; // For most browsers
    document.documentElement.scrollTop = 0; // For Firefox
  }, []);
  const navigate = useNavigate();

  const [customerData, setCustomerData] = useState<any>(null);
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const [cookies, , removeCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  const {
    getCustomerProfileAPICall,
    removeCustomerProfileAPICall,
    logoutCustomerAPICall,
  } = CustomerAPI();
  useEffect(() => {
    // let customerInfo = localStorage.getItem('customer_details');
    // if (customerInfo) {
    //   customerInfo = JSON.parse(customerInfo);
    //   setCustomerData(customerInfo);
    // }
    getCustomerProfileFunc();
  }, []);

  const getCustomerProfileFunc = async () => {
    // const result: any = await getCustomerProfileAPICall();
    // if (result === null) {
    //   toast.error('Failed to get customer profile.');
    //   return;
    // }
    // if (result?.status_code === 200) {
    //   setCustomerData(result.data);
    // } else {
    //   toast.error(result?.message);
    // }

    setIsLoading(true);

    try {
      const response: any = await getCustomerProfileAPICall();

      if (response?.status_code === 200 && response.success) {
        setCustomerData(response.data);
      } else {
        // toast.error('Failed to get customer profile.');
      }
    } catch (error) {
      console.error('Error deleting account', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAccountDialogOpen = () => setDeleteAccountModalOpen(true);
  const handleDeleteAccountDialogClose = () => setDeleteAccountModalOpen(false);

  const handleLogoutDialogOpen = () => setLogoutModalOpen(true);
  const handleLogoutAccountDialogClose = () => setLogoutModalOpen(false);

  const handleDeleteAccount = async () => {
    setDeleteAccountModalOpen(false);
    setIsLoading(true);
    try {
      const response = await removeCustomerProfileAPICall();

      if (response?.status_code === 200 && response.success) {
        // for glam9 build
        navigate('/');

        // for vreserv build
        // navigate('/');
      } else {
        console.error('Failed to delete account');
      }
    } catch (error) {
      console.error('Error deleting account', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogoutAccount = async () => {
    setLogoutModalOpen(false);
    setIsLoading(true);
    try {
      const response = await logoutCustomerAPICall();

      if (response?.status_code === 200 && response.success) {
        // for glam9
        removeCookie('token', { path: '/' });
        localStorage.removeItem('customer_auth_token');
        localStorage.removeItem('customer_details');
        navigate('/');

        // for vreserv
        // removeCookie('token', { path: '/' });
        // localStorage.removeItem('customer_auth_token');
        // localStorage.removeItem('customer_details');
        // navigate('/');
      } else {
        console.error('Failed to logout account');
      }
    } catch (error) {
      console.error('Error logout account', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loaderCcontainer">
          <img src={images.loader} />
        </div>
      )}

      {!isLoading && (
        <Container
          className="profile"
          component="main"
          maxWidth="lg"
          sx={{ mt: { xs: 3, md: 8 }, mb: 4 }}
        >
          <Grid container spacing={3}>
            <Grid className="sidebarContent" item xs={12} md={4}>
              {customerData && (
                <ProfileSidebar
                  customerData={customerData}
                  getCustomerData={getCustomerProfileFunc}
                  logoutModalOpen={logoutModalOpen}
                  handleLogoutDialogOpen={handleLogoutDialogOpen}
                  handleLogoutAccountDialogClose={
                    handleLogoutAccountDialogClose
                  }
                  handleLogoutAccount={handleLogoutAccount}
                />
              )}
            </Grid>
            <Grid className="mainContent" item xs={12} md={8}>
              {/* <Box sx={{ mt: { xs: 3, md: 0 } }}>
              {customerData && (
                <AddressSection
                  customerData={customerData}
                  getCustomerData={getCustomerProfileFunc}
                />
              )}

              <PaymentMethodsSection />
              <SocialLoginsSection />
              <NotificationsSection />
              <DeleteAccountSection />
              <QuickLinksSection />
            </Box> */}
              <DeleteAccountSection
                deleteAccountModalOpen={deleteAccountModalOpen}
                handleDeleteAccountDialogOpen={handleDeleteAccountDialogOpen}
                handleDeleteAccountDialogClose={handleDeleteAccountDialogClose}
                handleDeleteAccount={handleDeleteAccount}
              />
              <QuickLinksSection />
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};
export default ProfilePage;
