import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import './EmptyFavorites.css';
import { icons } from '../../Assets/icons';
import { images } from '../../Assets/images';
import { useNavigate, Link } from 'react-router-dom';

interface EmptyFavoritesProps {
  onViewSaloon: () => void;
}

export const EmptyFavorites: React.FC = () => {
  const navigate = useNavigate();

  // for glam9 build
  function gotoHome() {
    navigate('/');
  }

  // for vreserv build
  // function gotoHome() {
  //   navigate('/home');
  // }

  return (
    <Box className="empty-favorites-container">
      <Box className="empty-favorites-content">
        <img
          loading="lazy"
          src={icons.bigRedHeart}
          alt="Favorites icon"
          className="favorites-icon"
        />
        <Typography variant="h4" component="h1" className="favorites-title">
          No Favorites
        </Typography>
        <Typography variant="body1" className="favorites-description">
          Your favourites list is empty. Let's fill it up!
        </Typography>
        <Button
          variant="contained"
          onClick={gotoHome}
          color="primary"
          className="view-saloon-button"
        >
          View Saloon
        </Button>
      </Box>
    </Box>
  );
};
