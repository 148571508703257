import React, { useEffect } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { icons } from '../../../Assets/icons';
import { Link } from 'react-router-dom';
import { VendorDetailData } from '../../../api/types/shop';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbContext';
interface VisitOptionProps {
  text: string;
  subtext: string;
  isSelected: boolean;
  setIsVisitedFirstTime: Function;
  visitedFirstTime: boolean;
}

interface ServiceSelectionProps {
  visitedFirstTime: boolean;
  setIsVisitedFirstTime: Function;
  vendorData?: VendorDetailData;
  bookPage: any;
}

const ServiceSelection: React.FC<ServiceSelectionProps> = ({
  visitedFirstTime,
  setIsVisitedFirstTime,
  vendorData,
  bookPage,
}) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  // useEffect(() => {
  //   setBreadcrumbs(prevBreadcrumbes =>
  //     prevBreadcrumbes.map(breadcrumb =>
  //       breadcrumb.id === bookPage
  //         ? { ...breadcrumb, isVisited: true }
  //         : breadcrumb,
  //     ),
  //   );
  // }, [setBreadcrumbs]);

  return (
    <>
      <Grid item xs={12} md={8}>
        <Box
          className="firstVisit"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <Typography variant="h4" sx={{ mt: 4, fontWeight: 'bold' }}>
            Is this your first visit to{' '}
            {vendorData?.vendor_detail?.data?.business_name || ''}
          </Typography>

          <Box sx={{ mt: 4, width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <VisitOption
                  setIsVisitedFirstTime={setIsVisitedFirstTime}
                  visitedFirstTime={visitedFirstTime}
                  text="YES"
                  subtext="This is my first visit"
                  isSelected={visitedFirstTime ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <VisitOption
                  setIsVisitedFirstTime={setIsVisitedFirstTime}
                  visitedFirstTime={visitedFirstTime}
                  text="No"
                  subtext="I've visited before"
                  isSelected={!visitedFirstTime ? true : false}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={1}></Grid>
    </>
  );
};

const VisitOption: React.FC<VisitOptionProps> = ({
  text,
  subtext,
  isSelected,
  setIsVisitedFirstTime,
  visitedFirstTime,
}) => {
  return (
    <Paper
      elevation={isSelected ? 4 : 1}
      sx={{
        padding: { xs: '16px', md: '32px' },
        textAlign: 'center',
        border: isSelected ? '2px solid #4E297B' : '2px solid #4A4A4A',
        borderRadius: '16px',
        width: '100%',
      }}
      onClick={() => {
        setIsVisitedFirstTime(!visitedFirstTime);
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        {text}
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        {subtext}
      </Typography>
    </Paper>
  );
};

export default ServiceSelection;
