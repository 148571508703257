import React,{useState} from 'react';
import { Box, Typography, Avatar, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { icons } from '../../../Assets/icons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// Interface
import { Service } from '../../../../src/api/types/vendor_services';
type ServiceCardProps = {
  serviceName: string;
  processTime: string;
  professionalName: string;
  getProfesstionalFunc?: Function;
  serviceObject?: Service;
};

const StyledCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  backgroundColor: 'white',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey[800]}`,
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    // maxWidth: '70%',
  },
}));

const StyledProfessional = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled', // Prevent passing 'disabled' to DOM
})<{ disabled?: boolean }>(({ theme, disabled }) => ({


  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: '#482E77',
  color: theme.palette.common.white,
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? 0.6 : 1, 
  pointerEvents: disabled ? 'none' : 'auto',
  padding: theme.spacing(1),
  borderRadius: '500px',
}));

const ServiceCard: React.FC<ServiceCardProps> = ({
  serviceName,
  processTime,
  professionalName,
  getProfesstionalFunc,
  serviceObject,
}) => {
  const [disabled, setDisabled] = useState(false);
  return (
    <StyledCard className="selectProfessional">
      <Box className="serviceCardLeft">
        <Typography
          variant="h6"
          color="textPrimary"
          fontWeight="medium"
          className="serviceTitle"
        >
          {serviceName}
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          mt={1}
          className="textTime"
        >
          Process Time: {processTime}
        </Typography>
      </Box>
      <StyledProfessional
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          getProfesstionalFunc && getProfesstionalFunc(serviceObject);
          setDisabled(true);
          setTimeout(() => {
            setDisabled(false);
          }, 3000); // 5 seconds
        }}
        className="serviceCardRight"
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Avatar
            src={icons.professional}
            alt={professionalName}
            sx={{ width: 44, height: 44 }}
            className="profileAvatar"
          />
          <p
            className="professionalName"
            style={{ color: 'white', fontSize: '14px', margin: 0, padding: 0 }}
          >
            {professionalName}
          </p>
          <KeyboardArrowDownIcon sx={{ marginRight: '10px' }} />
        </Box>
      </StyledProfessional>
    </StyledCard>
  );
};

export default ServiceCard;