import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography, Rating, Box, Paper } from '@mui/material';
import Slider from 'react-slick';
import { icons } from '../../Assets/icons';
import { DashboardResponse } from '../../api/types/dashboard';
import { getUserLocation } from '../../Common/CommonFunctions/common';
import useAppContext from '../../hooks/useAppContext';
import toast from 'react-hot-toast';
import Loading from '../../Common/Loader/Loading/Loading';
import './CustomerTestimonials.css';


function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      src={icons.right}
      alt="Next"
    />
  );
}
function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      src={icons.left}
      alt="Prev"
    />
  );
}

const settings = {
  dots: false,
  arrows: true,
  autoplay: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
  responsive: [
    {
      breakpoint: 1025,
      settings: { slidesToShow: 3 },
    },
    {
      breakpoint: 899,
      settings: {
        slidesToShow: 2,
        centerMode: false, // Ensures no side items are shown
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        // centerMode: true, // Ensures no side items are shown
      },
    },
  ],
};

const getInitials = (name: string) => {
  if (!name) return '';
  const nameParts = name.split(' ');
  let initials =
    nameParts.length > 1
      ? nameParts[0][0] + nameParts[nameParts.length - 1][0]
      : nameParts[0][0]; // Use only the first initial if there's one word
  if (name === 'Prakash Parekh (Bholabhai)') {
    initials = 'PP';
  }
  return initials.toUpperCase();
};

const CustomerTestimonials: React.FC = () => {
  const [customerTestimonialsData, setCustomerTestimonialsData] = useState<any>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false); // Loader state

  const { getDashboardDataFunc } = useAppContext();

  const getDashboardData = useCallback(async () => {
    try {
      setLoading(true);
      const { lat, long } = await getUserLocation();

      const result: DashboardResponse = await getDashboardDataFunc(lat, long);
      if (result === null) {
        toast.error('Failed to fetch city data.');
        return;
      }

      if (result?.status_code === 200 && result.success) {
        let resultData = result.data.review?.[0]?.data || [];

        setCustomerTestimonialsData(resultData);
      } else {
        // toast.error(result?.message);
        toast.error('Erro while getting customer testimonials');
      }
    } catch (error: unknown) {
      // Handle the error by updating the error state
      toast.error(
        error instanceof Error ? error.message : 'Something went wrong!',
      );
    } finally {
      setLoading(false); // Set loader to false after the API call is finished
    }
  }, [getDashboardDataFunc]);

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div className="customerTestimonials">
      {loading && <Loading />}
      {!loading &&
        (customerTestimonialsData.length > 0 ? (
          <Grid
            container
            className="testimonialContainer"
            sx={{
              marginTop: { xs: 5, md: 0 },
              width: 'auto',
              padding: { xl: '100px', xs: 0, sm: 1, md: 1.5, lg: '100px' },
              marginBottom: { xs: 4, md: 4, xl: 4 },
              paddingBottom: { xs: 4, md: 4, lg: 10, xl: 10 },
            }}
          >
            <Grid
              className="testimonial"
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: { xs: 4, md: 4, xl: 0 },
              }}
            >
              <Box
                className="testimonialFirst"
                sx={{
                  width: '100%',
                  height: 'auto', // Adjust height for responsiveness
                  position: 'relative',
                  textAlign: 'left',
                  fontSize: '1rem',
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
                <Box
                  className="testimonialSecond"
                  // sx={{
                  //   position: 'relative',
                  //   top: { xs: '2.5rem', md: '3rem' },
                  //   left: { xs: '1.25rem', md: '0.1rem' },
                  //   width: '100%',
                  //   padding: { xs: 2, md: 0 },
                  //   minHeight: '35.125rem',
                  // }}
                >
                  <Box
                    className="customerWordsBox"
                    display="flex"
                    justifyContent="center"                    
                  >
                    <Paper
                      className="customerWords"
                      sx={{
                        borderRadius: '31.25rem',
                        height: 'auto',
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'left',
                        boxShadow: '0',
                        background: 'transparent',
                      }}
                    >
                      <Typography className="customerTestimonialTitle">
                        Hear about the experience of our partners
                      </Typography>
                    </Paper>
                  </Box>

                  {/* Repeatable Review Boxes */}

                  <Slider {...settings}>
                    {customerTestimonialsData.map(
                      (data: any, index: number) => (
                        <Grid
                          className="testimonialGrid"
                          key={index}
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={3}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '30px',
                            paddingBottom: '50px',
                          }}
                        >
                          <Paper
                            className="testimonialArea"
                            elevation={2}
                            sx={{
                              borderRadius: '10px',
                              backgroundColor: '#f6f6f6',
                              width: '100%',
                              padding: '20px',
                              maxWidth: '100%', // Ensure a max width for the cards
                              height: { xl: 'auto', md: '20.625rem' },
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'center',
                            }}
                          >
                            <Box width="100%">
                              <Typography
                                className="testimonialDesc"
                                sx={{
                                  textAlign: 'left',
                                  fontSize: {
                                    xl: '16px', // extra large screen
                                  },
                                  fontWeight: '400',
                                  minHeight: {
                                    xl: '145px',
                                    lg: '170px',
                                    md: '80px',
                                    sm: '60px',
                                    xs: '40px',
                                  },
                                  color: '#636363',
                                  lineHeight: '28px',
                                }}
                                // className='customerReviewText'
                              >
                                {data.description}
                                <br />
                                <br />
                              </Typography>
                              <div className="testimonialBottom">
                                <Rating
                                  sx={{
                                    marginLeft: '0',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flexStart',
                                    marginBottom: '30px',
                                  }}
                                  name="simple-controlled"
                                  value={
                                    data?.rating ? parseInt(data?.rating) : 0
                                  }
                                  readOnly={true}
                                />
                                <hr
                                  style={{
                                    width: '100%',
                                    border: '1px solid #DCDCDC',
                                  }}
                                ></hr>
                                <Box
                                  className="userDetails"
                                  sx={{
                                    marginTop: {
                                      xs: '20px',
                                      sm: '10px',
                                      md: '20px',
                                    },
                                    fontSize: '1.125rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'left',
                                  }}
                                >
                                  <Box
                                    className="customeerCircle"
                                    sx={{
                                      width: '45px',
                                      height: '45px',
                                      borderRadius: '50%',
                                      backgroundColor: '#4E297B', // Change to desired background color
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      marginRight: '10px',
                                    }}
                                  >
                                    <Typography
                                      className="customerInitials"
                                      sx={{
                                        color: '#fff',
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {getInitials(data.name)}
                                    </Typography>
                                  </Box>
                                  <Box
                                    display="flex"
                                    justifyContent="left"
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    className="customerWrap"
                                  >
                                    <Typography className="customerName">
                                      {' '}
                                      {data.name}
                                    </Typography>
                                    <Typography className="customerLocation">
                                      {data.address}
                                    </Typography>
                                  </Box>
                                </Box>
                              </div>
                            </Box>
                          </Paper>
                        </Grid>
                      ),
                    )}
                  </Slider>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <></>
        ))}
    </div>
  );
};

export default CustomerTestimonials;
