import { Box, Grid, TextField, Typography, Button } from '@mui/material';
import React from 'react';

const ConnectionForm: React.FC = () => {
    return (
        <>
            <Box
                className='connection-form-section'
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display:'none',

                }}
            >
                <Box sx={{ width: '100%', maxWidth: '600px' }}>
                    <Typography variant="h4" gutterBottom align="center" className='grey-label' sx={{ fontSize: '18px', fontWeight: 500 }}>
                        INTERESTED IN WHAT TEAM IS UP TO? STAY UPDATED!
                    </Typography>
                    <Typography variant="h4" gutterBottom align="center" className='connection-label'>
                        Stay <span>Updated</span>
                    </Typography>
                    <Grid container spacing={3} justifyContent="center" sx={{ mt: 3 }}>
                        <Grid item xs={12} sm={6} md={12}>
                            <Typography gutterBottom>
                                Name <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12}>
                            <Typography gutterBottom>
                                Mobile Number <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12}>
                            <Typography gutterBottom>
                                Business Name <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12}>
                            <Typography gutterBottom>
                                Your Email <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={12}>
                            <Typography gutterBottom>
                                Business Location <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                multiline
                                rows={4} 
                            />
                        </Grid>
                    </Grid>
                    <Box textAlign={'left'}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className="register-btn"
                            sx={{
                                borderRadius: '10px',
                                backgroundColor: '#0278FB',
                                padding: '8px 16px',
                                fontWeight: 400,
                                marginTop: '20px',
                                '&:hover': {
                                    backgroundColor: '#025BB5',
                                }
                            }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ConnectionForm;
