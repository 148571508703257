import React, { useState } from 'react'; // Import useState
import { Box, Typography, Divider, Button, Grid } from '@mui/material';
// Interface
import { Service } from '../../../../src/api/types/vendor_services';
import { images } from '../../../Assets/images';
import { icons } from '../../../Assets/icons';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import './BookingSummary.css';
import { VendorDetailData } from '../../../../src/api/types/shop';
import moment from 'moment';
import { Navigate, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
// Props
interface SummaryItemProps {
  name: string;
  price: number;
  processTime: string;
}
interface summaryProps {
  data: any[];
  nextPage: Function;
  vendorData?: VendorDetailData;
  appointmentDate?: string;
  appointmentTime?: string;
  totalHours?: number;
  isSlotAvailable: Boolean;
  bookPage: number;
  bookAppointmentLoader: boolean;
}

// Item
const SummaryItem: React.FC<SummaryItemProps> = ({
  name,
  price,
  processTime,
}) => (
  <Grid
    className="summaryContainer"
    container
    spacing={2}
    justifyContent="space-between"
    sx={{ mt: 2 }}
  >
    <Grid item xs={8} className="summary8">
      <Typography variant="body1" fontWeight="medium">
        {name}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        Process Time: {processTime}
      </Typography>
    </Grid>
    <Grid item className="summary4" xs={4}>
      <Typography variant="body1" fontWeight="bold" align="right">
        {price === 0 ? 'Free' : '₹' + price}
      </Typography>
    </Grid>
  </Grid>
);

const Summary: React.FC<summaryProps> = ({
  vendorData,
  appointmentDate,
  appointmentTime,
  data,
  nextPage,
  totalHours,
  isSlotAvailable,
  bookPage,
  bookAppointmentLoader,
}) => {
  const navigate = useNavigate();
  function handleClose() {
    setopenConfirm(false);
  }
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  // const total = data.reduce(
  //   (sum: any, service: any) => sum + parseInt(service.charges),
  //   0,
  // );

  let total = 0;
  if (data.length > 0) {
    total = data.reduce(
      (sum: any, service: any) => sum + parseInt(service.charges),
      0,
    );
  }

  const [openConfirm, setopenConfirm] = useState(false);

  function formatTime(hours: number, minutes: number) {
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes
      ? minutes.toString().padStart(2, '0')
      : '00';
    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  function createTimeSlot(startTime: string, totalHours: number) {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    let currentHour = startHours;
    let currentMinute = startMinutes;

    currentHour += totalHours;
    const start = formatTime(startHours, startMinutes);
    const end = formatTime(currentHour, currentMinute);

    return `${start} - ${end} (Duration: ${totalHours} hour${totalHours > 1 ? 's' : ''})`;
  }
  // Example usage
  const letStartTime = appointmentTime ?? '00:00';
  const letTotalHours = totalHours ?? 1;

  const formattedStartTime = dayjs(letStartTime, ['hh:mm A', 'HH:mm']).format(
    'HH:mm',
  );
  const timeSlots = createTimeSlot(formattedStartTime, letTotalHours);

  // const timeSlots = createTimeSlot(letStartTime, letTotalHours);
  const genderType = vendorData?.vendor_detail?.data?.gender_type;
  const displayTime = (time: any) => dayjs(time, 'HH:mm').format('hh:mm A');

  return (
    <Box className="booking-summary">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openConfirm}
        className="conf"
      >
        <center className="confirmed">
          <div className="image">
            <img alt="rightTickImage" src={icons.rightTick}></img>
          </div>
          <h2>Appointment Confirmed!</h2>
          <p>
            Lorem Ipsum is simply dummy text of the<br></br> printing and
            typesetting industry.{' '}
          </p>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            sx={{
              textTransform: 'capitalize',
              marginTop: '15px',
              padding: '15px 150px',
            }}
            className="btn btn-primary"
          >
            Close
          </Button>
        </center>
      </BootstrapDialog>
      {vendorData && vendorData?.media_images && (
        <Box className="salon-info">
          <img
            loading="lazy"
            src={
              vendorData?.cover_photo
                ? vendorData.cover_photo
                : vendorData?.media_images?.[0]?.media_name
                  ? vendorData.media_images[0].media_name
                  : images.glam9512 // Use default image if media_name is undefined or not found
            }
            onError={e => {
              const target = e.target as HTMLImageElement;
              target.src = images?.glam9512;
            }}
            alt={vendorData?.vendor_detail?.title || 'Salon Image'}
            className="salon-image"
          />

          <Box className="salon-details">
            <Box className="salon-header">
              <Typography variant="subtitle2" className="salon-name">
                {vendorData?.vendor_detail.data.business_name}
              </Typography>
              <Box className="salon-rating">
                <Typography variant="subtitle2">
                  {vendorData?.vendor_detail.data.rating_value} (
                  {vendorData?.vendor_detail.data.total_review})
                </Typography>
                <img
                  loading="lazy"
                  src={icons.rating}
                  alt=""
                  className="rating-icon"
                />
              </Box>
            </Box>
            <Typography variant="body2" className="salon-type">
              {vendorData?.vendor_detail?.data?.gender_type === '1'
                ? 'Male Saloon'
                : vendorData?.vendor_detail?.data?.gender_type === '2'
                  ? 'Female Saloon'
                  : 'Unisex Saloon'}
            </Typography>
            <Box className="salon-location">
              <img
                loading="lazy"
                src={icons.yellowLocation}
                alt=""
                className="location-icon"
              />
              <Typography variant="body2">
                {vendorData?.vendor_detail.data.address}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {appointmentDate && appointmentTime && (
        <Box className="date-time">
          <Box className="date">
            <img
              loading="lazy"
              src={icons.calender}
              alt=""
              className="date-icon"
            />
            <Typography variant="body2">
              {moment(appointmentDate).format('dddd D, MMMM')}
            </Typography>
          </Box>
          <Box className="time">
            <img loading="lazy" src={icons.time} alt="" className="time-icon" />
            <Typography variant="body2">{timeSlots}</Typography>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          p: { xs: 2, md: 2 },
          mx: 'auto',
          width: '100%',
          maxWidth: 600,
          textAlign: 'left',
          bgcolor: 'white',
          borderRadius: 3,
          border: 1,
          borderColor: 'grey.800',
          marginTop: '20px',
        }}
        className="summary"
      >
        <Typography variant="h5" fontWeight="bold">
          Summary
        </Typography>
        <Divider sx={{ my: 2 }} />
        {data && data.length > 0 ? (
          <>
            {data.map((service, index) => (
              <SummaryItem
                key={index}
                name={service.service_name || service?.package_name}
                processTime={
                  service.service_time || service?.total_service_time
                }
                price={parseInt(service.charges)}
              />
            ))}
          </>
        ) : (
          <Typography variant="body1" fontWeight="bold">
            No Services Selected
          </Typography>
        )}
        <Divider sx={{ my: 2 }} />
        <Grid
          className="summaryTotal"
          container
          justifyContent="space-between"
          sx={{ mt: 2 }}
        >
          <Typography variant="body1" fontWeight="bold">
            Total
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            {data && data.length > 0 ? (total === 0 ? 'Free' : '₹' + total) : 0}
          </Typography>
        </Grid>

        {bookAppointmentLoader ? (
          <Button
            variant="contained"
            onClick={() => {
              nextPage();
            }}
            sx={{
              mt: 5,
              py: 2,
              px: { xs: 3, md: 8 },
              textTransform: 'capitalize',
              borderRadius: '12px',
              backgroundColor: '#4E297B',
            }}
            style={{ padding: '20px 0' }}
            fullWidth
            disabled={bookAppointmentLoader}
          >
            {bookPage >= 5 ? 'Book Appointment' : 'Continue'}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              nextPage();
            }}
            sx={{
              mt: 5,
              py: 2,
              px: { xs: 3, md: 8 },
              textTransform: 'capitalize',
              borderRadius: '12px',
              backgroundColor: '#4E297B',
            }}
            style={{ padding: '20px 0' }}
            fullWidth
            disabled={!isSlotAvailable ? true : data.length > 0 ? false : true}
          >
            {bookPage >= 5 ? 'Book Appointment' : 'Continue'}
          </Button>
        )}
      </Box>
    </Box>
  );
};
export default Summary;

{
  /* <Button
          variant="contained"
          onClick={() => {
            nextPage();
          }}
          sx={{
            mt: 5,
            py: 2,
            px: { xs: 3, md: 8 },
            textTransform: 'capitalize',
            borderRadius: '12px',
            backgroundColor: '#4E297B',
          }}
          fullWidth
          disabled={!isSlotAvailable ? true : data.length > 0 ? false : true}
        >
          {bookPage >= 5 ? 'Book Appointment' : 'Continue'}
        </Button> */
}
