import React, { useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add'; // Plus icon
import CloseIcon from '@mui/icons-material/Close'; // Minus icon
import './faq.css';

interface FAQItem {
  question: string;
  answer: string;
}

interface FAQProps {
  data: FAQItem[];
}

const FAQ: React.FC<FAQProps> = ({ data }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box className="faq-container">
      <Box className="faq-container-wrapper">
        <Typography className="faqTitle"
          sx={{
            fontSize: { xs: '25px', sm: '40px', lg: '60px' },
            color: '#231F20',
            fontWeight: 300,
          }}
        >
          Frequently asked
          <span style={{ fontWeight: '700' }}> questions</span>
        </Typography>

        <Typography className="faqSubTitle"
          sx={{
            fontSize: { xs: '14px', sm: '16px', lg: '18px' },
            color: '#7A7A7A',
            fontWeight: 400,
          }}
        >
          Glow All Year – Unlock Unlimited Pampering with Our Exclusive
          Subscription Plans!
        </Typography>
      </Box>

      <Box className="faq-content">
        {data.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            className="faq-content-wrapper"
          >
            <AccordionSummary
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              expandIcon={
                expanded === `panel${index}` ? (
                  <CloseIcon
                    sx={{
                      color: expanded === `panel${index}` ? '#0178FA' : '',
                      backgroundColor:
                        expanded === `panel${index}`
                          ? 'rgba(1, 120, 250, 0.4)'
                          : '#333333',
                      borderRadius: '50%',
                    }}
                  />
                ) : (
                  <AddIcon
                    sx={{
                      color:
                        expanded === `panel${index}` ? '#0178FA' : '#FFFFFF',
                      backgroundColor:
                        expanded === `panel${index}`
                          ? '#0178FA'
                          : 'rgba(51, 51, 51, 0.5)',
                      borderRadius: '50%',
                      padding: '1px',
                    }}
                  />
                )
              }
              sx={{
                borderBottom:
                  expanded === `panel${index}` ? '2px solid #25252B' : '',
                padding: '0px',
              }}
            >
              <Typography
                className="faq-question"
                sx={{
                  color: expanded === `panel${index}` ? '#0178FA' : '#333333', // Change color to blue when selected
                  fontSize: { xs: '14px', sm: '18px', lg: '20px' },
                  fontWidth: { xs: 500, sm: 400, lg: 500 },
                }}
              >
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px' }}>
              <Typography
                className="faq-answer"
                sx={{
                  fontSize: { xs: '12px', sm: '15px', lg: '16px' },
                  fontWidth: { xs: 500, sm: 400, lg: 500 },
                }}
              >
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default FAQ;
