import React from 'react';
import { Box, Avatar, Typography, Paper,Grid } from '@mui/material';
import './ShopTeam.css';
import { images } from '../../../Assets/images';

interface vendorData {
  vendorData: any; // Replace `any` with a specific type, e.g., `string`, `object`, etc.
}

const ShopTeam: React.FC<vendorData> = ({vendorData}) => {    
  return (
    <>
    {vendorData?.our_team && vendorData?.our_team?.data?.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={2} wrap="wrap" className='teamContainer' >
            <Grid item xs={12}>
              <h2>Our Team</h2>
            </Grid>
            {vendorData?.our_team &&
              vendorData?.our_team?.data?.length > 0 ? (
                vendorData?.our_team?.data?.map((item:any, index:any) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Box className="team" >
                    {/* Profile Image */}
                    <Box
                      component="img"
                      src={
                        item?.profile_image
                          ? item?.profile_image
                          : images?.glam9512
                      }
                      onError={e => {
                        const target = e.target as HTMLImageElement;
                        target.src = images?.glam9512;
                      }}
                    />
                    {/* Name */}
                    <Typography>
                      {item.first_name}{' '}
                      {item.last_name && item.last_name}
                    </Typography>
                  </Box>
                </Grid>
              ))
            ) : (
              <Typography className="teamNotFound">No team members found.</Typography>
            )}
          </Grid>
        </Grid>
      )}
      </>
  );
};

export default ShopTeam;
