import React, { FC, useEffect, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Header from '../Components/common/Header/Header';
import Footer from '../Components/common/Footer/Footer';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Otp from '../pages/Otp';
import Shop from '../pages/Shop';
import Booking from '../pages/Booking';
import SelectConfirm from '../pages/Confirm';
import Review from '../pages/Review';
import CreateAccount from '../Components/register/creatAccount';
import AppointmentPage from '../Components/appointment/AppointmentPage';
import ProfilePage from '../Components/profile/ProfilePage';
import FavoritesPage from '../Components/favorite/FavoritesPage';
import Reviews from '../Components/reviews/ReviewPage';
import SearchPage from '../Components/search/Search';
import SelectPricing from '../pages/Pricing';
import VenderHome from '../pages/VenderHome';
import PrivacyPolicy from '../Components/about/PrivacyPolicy';
import CookiesPolicy from '../Components/about/CookiesPolicy';
import ScrollToTop from '../Components/home/ScrollToTop';
import TermsOfUse from '../Components/about/TermsOfUse';
import Support from '../Components/about/Support';
import Business from '../Components/about/business';
import Refund from '../Components/about/Refund';
import Terms from '../Components/about/Terms';
import VendorOffers from '../Components/offer/VendorOffers';
import ProtectedOTPRoute from './ProtectedOTPRoute';
import PageNotFound from '../Components/PageNotFound/PageNotFound';
import CommonCookie from '../Components/CommonCookie';
import ShopDetails from '../pages/Shop';
import ShopDetail from '../pages/ShopDetail';
import GoogleMapsProvider from '../../src/GoogleMapsProvider';
import List from '../Components/search/CustomerDashboardListing/List';
import CommingSoon from '../../src/Components/CommingSoon/CommingSoon';

const Index: FC = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const [cookies, , removeCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  const navigate = useNavigate();
  useEffect(() => {
    // Check if the user is logged in
    const token = localStorage.getItem('customer_auth_token');
    if (token) {
      setIsAuthenticated(true);
      // Convert token existence to boolean
    } else {
      setIsAuthenticated(false);
    }
  }, []);
  const restrictedRoutes = [
    { path: '/login', component: <Login /> },
    { path: '/register', component: <CreateAccount /> },
    {
      path: '/otp',
      component: (
        <ProtectedOTPRoute>
          <Otp />
        </ProtectedOTPRoute>
      ),
    },
  ];
  return (
    <GoogleMapsProvider>
      <React.Fragment>
        <Header setIsAuthenticated={setIsAuthenticated} />
        <ScrollToTop />
        <main style={{ minHeight: '100vh' }}>
          {/* for glam9 build */}
          <Routes>
            <Route
              path="/login"
              element={
                isAuthenticated ? <Navigate to="/" replace /> : <Login />
              }
            />
            <Route
              path="/register"
              element={
                isAuthenticated ? (
                  <Navigate to="/" replace />
                ) : (
                  <CreateAccount />
                )
              }
            />
            <Route
              path="/otp"
              element={
                isAuthenticated ? (
                  <Navigate to="/" replace />
                ) : (
                  <ProtectedOTPRoute>
                    <Otp />
                  </ProtectedOTPRoute>
                )
              }
            />
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/confirm" element={<SelectConfirm />} />
            <Route path="/review" element={<Review />} />
            <Route path="/appointment" element={<AppointmentPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/favourite" element={<FavoritesPage />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/list" element={<List />} />
            <Route path="/pricing" element={<SelectPricing />} />
            <Route path="/about" element={<VenderHome />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/cookies-policy" element={<CookiesPolicy />} />
            <Route path="/support" element={<Support />} />
            <Route path="/business" element={<Business />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/terms-of-services" element={<Terms />} />
            <Route
              path="/vendor-offers/:offerType"
              element={<VendorOffers />}
            />
            <Route path="*" element={isAuthenticated && <PageNotFound />} />
          </Routes>

          {/* for vreserv build */}
          {/* <Routes>
            <Route
              path="/"
              element={
                isAuthenticated ? <Navigate to="/home" replace /> : <Login />
              }
            />
            <Route
              path="/register"
              element={
                isAuthenticated ? (
                  <Navigate to="/home" replace />
                ) : (
                  <CreateAccount />
                )
              }
            />
            <Route
              path="/otp"
              element={
                isAuthenticated ? (
                  <Navigate to="/" replace />
                ) : (
                  <ProtectedOTPRoute>
                    <Otp />
                  </ProtectedOTPRoute>
                )
              }
            />
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/confirm" element={<SelectConfirm />} />
            <Route path="/review" element={<Review />} />
            <Route path="/appointment" element={<AppointmentPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/favourite" element={<FavoritesPage />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/list" element={<List />} />
            <Route path="/pricing" element={<SelectPricing />} />
            <Route path="/about" element={<VenderHome />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/cookies-policy" element={<CookiesPolicy />} />
            <Route path="/support" element={<Support />} />
            <Route path="/business" element={<Business />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/terms-of-services" element={<Terms />} />
            <Route
              path="/vendor-offers/:offerType"
              element={<VendorOffers />}
            />
            <Route path="*" element={isAuthenticated && <PageNotFound />} />
          </Routes> */}
        </main>
        <Footer />
      </React.Fragment>
    </GoogleMapsProvider>
  );
};
export default Index;
