import React, { useState } from 'react';
import { Box, InputBase, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import 'react-phone-input-2/lib/style.css'; // Import the default styles
import './phoneInput.css';
import { validatePhoneNumber } from '../../../Common/CommonFunctions/common';
// import { default as CustomPhoneInput } from 'react-phone-input-2';
import { icons } from '../../../Assets/icons';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: '16px',
    padding: '16px',
    marginTop: '64px',
    maxWidth: '100%',
    fontSize: '16px',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    border: '1px solid rgba(82, 82, 82, 0.5)',
    color: '#525252',
    width: '450px',
    '@media (max-width: 768px)': {
      marginTop: '40px',
      width: '100%',
    },
  },
  flagContainer: {
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    boxShadow: '0px 1px 5px 0px #00000026',
    borderRadius: '10px',
    padding: '10px',
  },
  flagImg: {
    height: 'auto',
  },
  downArrow: {
    width: '17px',
    height: 'auto',
  },
  input: {
    flexGrow: 1,
    opacity: 1,
    width: '258px',
  },
  flagIcon: {
    marginRight: 8,
  },
  phoneInput: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

interface PhoneInputProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  emailError: string;
  setEmailError: React.Dispatch<React.SetStateAction<string>>;
  isBlurred: boolean;
  setIsBlurred: Function;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  email,
  setEmail,
  emailError,
  setEmailError,
  isBlurred,
  setIsBlurred,
}) => {
  // const [phone, setPhone] = useState('');
  // const handleOnChange = (value: any) => {
  //   // setPhone(value); // Update phone state
  // };

  // const handleChange = (value: string, data: any) => {
  //   setEmail(value); // Full phone number
  //   setCountryCode(`+${data.dialCode}`); // Extract country code
  // };

  const classes = useStyles();

  const [countryCode, setCountryCode] = useState('+91'); // Default country code
  // const [isBlurred, setIsBlurred] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    if (/^\d{0,10}$/.test(input)) {
      setEmail(input);
      if (isBlurred) {
        setEmailError(validatePhoneNumber(input));
        // validatePhoneNumber(input); // Validate in real-time
      }
    }
  };

  // const validatePhoneNumber = (input: string) => {
  // if (input === '') {
  //   setEmailError('Please enter phone number');
  // } else if (input.length !== 10) {
  //   setEmailError('Phone number should be 10 digits');
  // } else if (isNaN(Number(input))) {
  //   setEmailError('Phone number should be numeric');
  // } else {
  //   setEmailError('');
  // }
  // if (!input) {
  //   setEmailError('Please enter phone number phone input');
  // } else if (input.length < 10) {
  //   setEmailError('Phone number should be 10 digits phone input');
  // } else if (isNaN(Number(input))) {
  //   setEmailError('Phone number should be numeric phone input');
  // } else {
  //   setEmailError(''); // Clear error when valid
  // }
  // };

  const handleInputBlur = () => {
    setIsBlurred(true);
    setEmailError(validatePhoneNumber(email)); // Validate on blur
  };

  return (
    <>
      <div className="inputWrap">
        <Box className="makeStyles-container-1">
          <Box className={classes.flagContainer}>
            <img src={icons.indiaFlag} alt="" />
            <Typography className="countryCode">{countryCode}</Typography>
          </Box>
          <InputBase
            value={email}
            type="tel"
            id="phoneNumber"
            placeholder="Your Phone Number"            
            aria-label="Your Phone Number"
            className="inputPhone"
            // onChange={e => setEmail(`${e.target.value}`)}
            // onChange={e => {
            //   const input = e.target.value;
            //   // Allow only digits and limit to 10 characters
            //   if (/^\d{0,10}$/.test(input)) {
            //     setEmail(input);
            //   }
            // }}
            onChange={handleInputChange}
            onBlur={handleInputBlur} // Validate on blur
          />
        </Box>
        {isBlurred && emailError && (
          <p className="phoneInputError">{emailError}</p>
        )}
      </div>
    </>
  );
};

export default PhoneInput;

{
  /* <CustomPhoneInput
            country={'in'}
            preferredCountries={['in']} // Show India and USA at the top of the dropdown
            enableSearch={false} // Enable the search feature in the dropdown
            value={phone}
            onChange={handleChange}
            onlyCountries={['in']}
            disableDropdown={true}
            inputProps={{
              style: { display: 'none' }, // Hide the default input
            }}
            containerClass={classes.flagImg}
            buttonStyle={{
              position: 'static',
              border: 'none',
              textAlign: 'left',
            }}
            inputStyle={{
              textAlign: 'left', // Align text to the left
              paddingLeft: '10px', // Add padding to the left
              paddingRight: '10px', // Optional, for balance
            }}
          /> */
}

{
  /* <span className="dropIcon">
<svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.58579 9.29289C8.97631 9.68342 9.60948 9.68342 10 9.29289L17.4393 1.85355C17.6346 1.65829 17.6346 1.34171 17.4393 1.14645C17.2441 0.951184 16.9275 0.951185 16.7322 1.14645L10 7.87868C9.60948 8.2692 8.97631 8.2692 8.58579 7.87868L1.85355 1.14645C1.65829 0.951185 1.34171 0.951185 1.14645 1.14645C0.951184 1.34171 0.951185 1.65829 1.14645 1.85355L8.58579 9.29289Z" fill="#231F20"/>
<path d="M16.7322 1.14645L16.3787 0.792893L16.7322 1.14645ZM17.4393 1.85355L17.0858 1.5L17.4393 1.85355ZM10 7.87868L9.64645 7.52513L10 7.87868ZM10.3536 9.64645L17.7929 2.20711L17.0858 1.5L9.64645 8.93934L10.3536 9.64645ZM16.3787 0.792893L9.64645 7.52513L10.3536 8.23223L17.0858 1.5L16.3787 0.792893ZM1.5 1.5L8.23223 8.23223L8.93934 7.52513L2.20711 0.792893L1.5 1.5ZM8.93934 8.93934L1.5 1.5L0.792893 2.20711L8.23223 9.64645L8.93934 8.93934ZM0.792893 0.792893C0.402368 1.18342 0.402369 1.81658 0.792893 2.20711L1.5 1.5L1.5 1.5L0.792893 0.792893ZM2.20711 0.792893C1.81658 0.402369 1.18342 0.402369 0.792893 0.792893L1.5 1.5L1.5 1.5L2.20711 0.792893ZM17.7929 0.792893C17.4024 0.402369 16.7692 0.402369 16.3787 0.792893L17.0858 1.5L17.7929 0.792893ZM17.7929 2.20711C18.1834 1.81658 18.1834 1.18342 17.7929 0.792893L17.0858 1.5V1.5L17.7929 2.20711ZM9.64645 7.52513C9.45118 7.72039 9.1346 7.72039 8.93934 7.52513L8.23223 8.23223C8.81802 8.81802 9.76777 8.81802 10.3536 8.23223L9.64645 7.52513ZM8.23223 9.64645C8.81802 10.2322 9.76777 10.2322 10.3536 9.64645L9.64645 8.93934C9.45118 9.1346 9.1346 9.1346 8.93934 8.93934L8.23223 9.64645Z" fill="#231F20"/>
</svg>
</span> */
}

{
  /* <Box className="makeStyles-container-1">
        <Box className={classes.flagContainer}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc518136aec86745ac4efbf6fd06ac3f21561bdfa4a9e4008c57e6d65c9e7d50?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13"
            alt="flag"
            className={classes.flagImg}
          />
          <Typography>+91</Typography>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a383dcd90a35901ccd679fe3d6f6760336b7eb881346ff1a3b7ed6013ea7f40?placeholderIfAbsent=true&apiKey=4c2888e9b8b84b548fb4bc99e165dd13"
            alt="arrow down"
            className={classes.downArrow}
          />
        </Box>
        <InputBase
          type="tel"
          id="phoneNumber"
          placeholder="Your Phone Number"
          className="myClass"
          aria-label="Your Phone Number"
          onChange={e => setEmail(e.target.value)}
        />
      </Box> */
}
