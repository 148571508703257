import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Grid,
} from '@mui/material';
import AuthService from '../../../Services/auth.service';
import { useNavigate, Link } from 'react-router-dom';
import SearchItems from '../SearchItems';
import { Map } from '../Map';
import toast from 'react-hot-toast';
import { images } from '../../../Assets/images';
import { icons } from '../../../Assets/icons';
import { getVendorListingReqInterface } from '../../../api/types/dashboard';
import useAppContext from '../../../hooks/useAppContext';
import './List.css';
/* Map Import  */
import '../Map.css'; // Importing the external CSS for styling
import Slider from '@mui/material/Slider';
import ListSearchItems from './ListSearchItems';
import ListingFilters from './ListingFilters/ListingFilters';
import { ApiResponse, CategoryList } from '../../../api/types/common';

// interface CategoryList {
//   id: number;
//   name: string;
//   logo_link: string;
//   sequence: number;
//   is_premium_category: number;
// }
interface MapProps {
  itemData: any; // Replace `any` with the actual type of `itemData` for better type safety
}
interface MapData {
  vendor_list: { data: any }[];
}
/* Map Import  */
const List = () => {
  useEffect(() => {
    document.body.scrollTop = 0; // For most browsers
    document.documentElement.scrollTop = 0; // For Firefox
  }, []);
  const authService = AuthService();
  const navigate = useNavigate();
  const { getVenorListingFunc, getDashboardDataListFunc, getCategoryFunc } =
    useAppContext();
  const [dashboardData, setDashboardData] = useState<any>(null);
  const [mapData, setMapData] = useState<MapData | null>(null);
  const [plotData, setPlotData] = useState([]);

  const location = useLocation();

  const { filter_type, lattitude, longitude, title } = location.state;

  const [categoryData, setCategoryData] = useState<CategoryList[]>([]);
  const [locationAllowed, setLocationAllowed] = useState<boolean | null>(null);
  const [customerDashboardListing, setCustomerDashboardListing] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState<Record<string, any>>({});
  const [sortBy, setSortBy] = useState(''); // New state for Sort By selection
  const itemsPerPage = '10';
  let in_app = '1';

  const getCustomerDashboardListing = async (
    page: string,
    filters: Record<string, any>,
  ) => {
    setLoading(true);

    // // If sorting is applied, add sort_by key
    // if (sortBy === 'nearest') {
    //   filters.nearest = '1';
    // } else if (sortBy === 'top_rated') {
    //   filters.top_rated = '1';
    // }

    try {
      const result: any = await getDashboardDataListFunc(
        filter_type,
        page,
        itemsPerPage,
        filters,
      );

      if (result === null) {
        toast.error('Failed to fetch data.');
        return;
      }

      if (result?.status_code === 200) {
        setCustomerDashboardListing(result.data.records || []);
        setTotalPages(result.data.total_pages || 1);
        setTotalRecords(result.data.total_records || 0);
      } else {
        toast.error(result?.message);
      }
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Create a copy of appliedFilters to include sorting

    let filters = { ...appliedFilters };

    filters.in_app = in_app;

    // Add Remove sort_by key based on selected sorting option
    if (!sortBy) {
      delete filters.nearest;
      delete filters.top_rated;
    } else if (sortBy === 'nearest') {
      filters.nearest = '1';
    } else if (sortBy === 'top_rated') {
      filters.top_rated = '1';
    }

    if (lattitude && longitude) {
      getCustomerDashboardListing(currentPage.toString(), {
        ...filters,
        lattitude,
        longitude,
      });
    } else {
      getCustomerDashboardListing(currentPage.toString(), filters);
    }
  }, [currentPage, filter_type, sortBy]);

  // useEffect(() => {
  //   if (lattitude && longitude) {
  //     getCustomerDashboardListing(currentPage.toString(), {
  //       lattitude,
  //       longitude,
  //     });
  //   } else {
  //     getCustomerDashboardListing(currentPage.toString(), appliedFilters);
  //   }
  // }, [sortBy]);

  useEffect(() => {
    document.body.classList.add('noScroll');
    return () => {
      document.body.classList.remove('noScroll');
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Handle filter application
  const handleApplyFilters = (filters: Record<string, any>) => {
    const manageFilters = { ...filters };

    manageFilters.in_app = '1';

    if (sortBy === 'nearest') {
      manageFilters.nearest = '1';
    } else if (sortBy === 'top_rated') {
      manageFilters.top_rated = '1';
    }

    if (lattitude && longitude) {
      if (!manageFilters['lattitude'] && !manageFilters['longitude']) {
        manageFilters['lattitude'] = lattitude;
        manageFilters['longitude'] = longitude;
      }
      setAppliedFilters(manageFilters);
      setCurrentPage(1); // Reset to first page on new search
      getCustomerDashboardListing('1', manageFilters);
    } else {
      setAppliedFilters(manageFilters);
      setCurrentPage(1); // Reset to first page on new search
      getCustomerDashboardListing('1', manageFilters);
    }
  };

  const searchReq = location.state?.searchReq;

  useEffect(() => {
    if (searchReq?.latitude && searchReq?.longitude) {
      setMapData(prevMapData => ({
        ...prevMapData,
        vendor_list: prevMapData?.vendor_list || [],
        latitude: searchReq.latitude,
        longitude: searchReq.longitude,
      }));
    }
  }, [searchReq]);

  useEffect(() => {
    if (searchReq) {
      fetchSearchResults(searchReq);
    } else {
      //   toast.error('No search criteria provided.');
    }
  }, [searchReq]);

  const fetchSearchResults = async (data: getVendorListingReqInterface) => {
    const result: any = await getVenorListingFunc(data);
    if (result === null) {
      toast.error('Failed to filter data.');
      return;
    }
    if (result?.status_code === 200 && result?.data) {
      let dataObject: any = {
        vendor_list: [
          {
            title: `${result?.data?.total}`,
            data: result?.data?.data,
          },
        ],
      };
      setDashboardData(dataObject);
    } else {
      let dataObject: any = {
        vendor_list: [
          {
            title: `0`,
            data: [],
          },
        ],
      };
      setDashboardData(dataObject);
    }
  };
  // for glam9 build
  const backToHome = () => {
    setDashboardData(null);
    setMapData(null);
    navigate('/');
    setTimeout(() => {
      const section = document.getElementById('height');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  // for vreserve build
  // const backToHome = () => {
  //   setDashboardData(null);
  //   setMapData(null);
  //   navigate('/home');
  //   setTimeout(() => {
  //     const section = document.getElementById('height');
  //     if (section) {
  //       section.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }, 0);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Runs on component mount

  useEffect(() => {
    const fetchCategoryList = async () => {
      try {
        const categoryDataResult: ApiResponse<CategoryList> | null =
          await getCategoryFunc(1);
        if (categoryDataResult === null) {
          toast.error('Failed to fetch city data.');
          return;
        }
        if (categoryDataResult?.status_code === 200) {
          setCategoryData(categoryDataResult.data);
        } else {
          toast.error(categoryDataResult?.message);
        }
      } catch (error) {
        toast.error('An error occurred while fetching categories.');
      }
    };

    fetchCategoryList();
  }, [getCategoryFunc]);

  useEffect(() => {
    navigator.permissions
      .query({ name: 'geolocation' })
      .then(result => {
        setLocationAllowed(result.state === 'granted');

        result.onchange = () => {
          setLocationAllowed(result.state === 'granted');
        };
      })
      .catch(() => setLocationAllowed(false));
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (locationAllowed === true) {
      const timer = setTimeout(() => {
        setIsLoading(false); // Hide loader after 5 seconds
      }, 2000);
      return () => clearTimeout(timer); // Cleanup the timer
    } else {
      const timer = setTimeout(() => {
        setIsLoading(false); // Hide loader after 5 seconds
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [isLoading]);

  const handleSortChange = (e: any) => {
    setSortBy(e.target.value);
  };

  /* Map Code */
  if (isLoading) {
    return (
      <div className="loaderCcontainer">
        <img src={images.loader} />
      </div>
    );
  } else {
    return (
      <Box className="search">
        <Grid container spacing={2} className="grid-container">
          <Grid
            item
            xs={12}
            md={4.5}
            xl={4}
            sm={12}
            lg={4}
            className="scrollableLeft"
          >
            <div className="searchResultContainer searchListContainer">
              <div className="wrapResult">
                <div className="backButton">
                  <img onClick={backToHome} src={icons.left} alt="home" />
                </div>
                <div className="resultMessage">
                  Showing <span className="searchNumber">{totalRecords}</span>{' '}
                  results for your selection
                </div>
              </div>

              {/* Sort By Dropdown */}
              <FormControl variant="outlined" className="sortBy">
                <Select
                  MenuProps={{
                    // Disable scroll locking
                    disableScrollLock: true,
                  }}
                  className={`${sortBy ? 'selected' : ''}`}
                  sx={{
                    '& .MuiSelect-select': {
                      color: sortBy ? '#FFFFFF' : '#231f20',
                      background: sortBy ? '#4E297B' : '#FFFFFF',
                    },
                  }}
                  value={sortBy}
                  onChange={handleSortChange}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Sort by</em>
                  </MenuItem>
                  <MenuItem value="nearest">Nearest</MenuItem>
                  <MenuItem value="top_rated">Top Rated</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="listTitle">
              <h1>{title || ''}</h1>
            </div>

            {loading ? (
              <div className="loaderCcontainer">
                <img src={images.loader} />
              </div>
            ) : customerDashboardListing.length > 0 ? (
              <ListSearchItems
                dataForPagination={customerDashboardListing}
                setPlotData={setPlotData}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            ) : (
              <p>No Data Found</p>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={7.5}
            sm={12}
            lg={8}
            xl={8}
            className="mapRight"
          >
            <ListingFilters
              onApplyFilters={handleApplyFilters}
              categoryData={categoryData}
              setSortBy={setSortBy}
              filter_type={filter_type}
              sortBy={sortBy}
            />
            {/* <Map itemData={memoizedItemData} /> */}
            <Map itemData={plotData} />
          </Grid>
        </Grid>
      </Box>
    );
  }
};
export default List;
