import * as React from 'react';
import { Box, Typography, Grid, Button, Paper } from '@mui/material';
import './BeautySpot.css';
import { useNavigate } from 'react-router-dom';

import { icons } from '../../../src/Assets/icons';
import { images } from '../../../src/Assets/images';

interface VendorAddress {
  [key: string]: string | undefined; // Dynamic keys with string values
}

interface Address {
  [key: string]: string | undefined;
}

interface BeautySpotProp {
  photo: string;
  name: string;
  vendor_detail_id: string;
  rating: string;
  gender: string;
  address: Address;
  distance: string;
}

const BeautySpot: React.FC<BeautySpotProp> = ({
  photo,
  name,
  vendor_detail_id,
  rating,
  gender,
  address,
  distance,
}) => {
  const navigate = useNavigate();
  function gotoShop() {
    try {
      localStorage.setItem('shopID', vendor_detail_id.toString());
      navigate('/shop');
    } catch (error) {
      console.error('Error navigating to shop:', error);
    }
  }

  const hasVendorAddress = address && Object.keys(address).length > 0;

  // Function to construct the address
  const constructAddress = (address?: VendorAddress): string => {
    if (!address) return ''; // Return empty if no address

    const parts: string[] = [];

    // Iterate over the keys of the address object
    for (const key in address) {
      if (key !== 'lattitude' && key !== 'longitude' && address[key]) {
        // Check if the value is not empty
        parts.push(address[key]!); // Use non-null assertion since we checked for undefined
      }
    }

    return parts.join(', '); // Join the parts with a comma
  };

  let locationAddress = '';

  if (hasVendorAddress) {
    locationAddress = constructAddress(address);
  }

  return (
    <div onClick={gotoShop} className="beautyspotContainer">
      <Box className="imageSection">
        <img loading="lazy" src={photo} className="mainImage" alt={name} />
      </Box>
      <div className="contentSection">
        <Box className="infoSection">
          <Box className="titleContent">
            <Typography
              variant="body1"
              className="salonName"
              style={{
                fontSize: '18px',
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Typography>
          </Box>

          {distance !== '' && (
            <Box className="kmContainer">
              <Typography variant="body1" className="kmRange">
                {distance}
              </Typography>
            </Box>
          )}
        </Box>
        <Box className="locationContent">
          {locationAddress && (
            <Box className="locationContainer">
              {/* <img
                loading="lazy"
                src={images.location}
                className="location-icon"
                alt="Location pin icon"
              /> */}
              <Typography
                variant="body2"
                className="locationAddress"
                sx={{
                  marginTop: '0px',
                  fontSize: '14px',
                  color: 'rgba(35, 31, 32, 1)',
                  opacity: '30%',
                  fontWeight: '400',
                  height: '25px',
                }}
              >
                {locationAddress}
              </Typography>
            </Box>
          )}
        </Box>

        <div className="ratingContainer">
          <Typography
            className="ratingContent"
            variant="body2"
            style={{ color: 'rgba(35, 31, 32, 1)' }}
          >
            {rating == '0 (0)' ? (
              <img className="rating" src={icons.noStar} alt="Rating" />
            ) : (
              <img className="rating" src={icons.rating} alt="Rating" />
            )}

            <span className="ratingCount">{rating} </span>
          </Typography>

          {gender !== '' ? (
            <span className="salonTypes">
              {gender === '1' ? 'Male' : gender === '2' ? 'Female' : 'Unisex'}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default BeautySpot;
