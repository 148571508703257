import React from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import './LatestAppointmentCard.css';

type LatestAppointmentCard = {
  image: string;
  title: string;
  lattitude: string;
  longitude: string;
  date: string;
  total_hours: string;
  price: string;
  itemCount: number;
  currency_icon: string;
  updateAppointment: Function;
  appointmentData: any;
  latestAppointmentSelected: boolean;
  setLatestAppointmentSelected: Function;
};

const LatestAppointmentCard: React.FC<LatestAppointmentCard> = ({
  image,
  title,
  lattitude,
  longitude,
  date,
  total_hours,
  price,
  itemCount,
  currency_icon,
  updateAppointment,
  appointmentData,
  latestAppointmentSelected,
  setLatestAppointmentSelected,
}) => {
  const handleLocationClick = (lat: any, lng: any, name: any) => {
    const url = `https://www.google.com/maps?q=${lat},${lng}&ll=${lat},${lng}&z=15&t=m&label=${encodeURIComponent(name)}`;
    window.open(url, '_blank');
  };

  return (
    <Card
      className={`latestAppointment-card ${latestAppointmentSelected ? 'latestAppointment-cardSelected' : ''}`}
      onClick={() => {
        updateAppointment(appointmentData);
        setLatestAppointmentSelected(true);
      }}
    >
      <Box className="card-container">
        <Box className="image-container">
          <img
            loading="lazy"
            src={image}
            alt={title}
            className="appointment-image"
          />
        </Box>
        <CardContent className="content-container">
          <Typography variant="h6" className="title">
            {title}
          </Typography>
          <Typography variant="body2" className="date">
            {date}
          </Typography>
          <Typography variant="body2" className="price">
          {price != '0' && (
              currency_icon
          )}
            {price == '0' ? 'Free' : price}&nbsp;{total_hours}  {itemCount} Item
          </Typography>

          <Button
            onClick={() => handleLocationClick(lattitude, longitude, title)}
            className="directionBtn"
          >
            Get Direction
          </Button>
        </CardContent>
      </Box>
    </Card>
  );
};
export default LatestAppointmentCard;
