import React from 'react';
import { Box, Typography, Grid, Card, Avatar } from '@mui/material';
import CommonCookie from '../../CommonCookie';
import AppointmentOption from './AppointmentOption';
import { useNavigate, useLocation } from 'react-router-dom';
import { icons } from '../../../Assets/icons';
import { useCookies } from 'react-cookie';

interface AppointmentBookingProps {
  handleClose: () => void;
}

const AppointmentBooking: React.FC<AppointmentBookingProps> = ({
  handleClose,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, , removeCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);

  const token = localStorage.getItem('customer_auth_token');

  function gotoBooking() {
    // if (cookies.token) {
    if (token) {
      navigate('/booking');
    } else {
      navigate('/login', { state: { from: location.pathname } });
    }
  }

  return (
    <Box component="main">
      <Box component="section">
        <Card>
          <Grid container className="apptModal" spacing={3}>
            <Grid item xs={11} md={11}>
              <Box className="top">
                <Typography variant="h4" component="h1" fontWeight="bold">
                  Choose an Option
                </Typography>
              </Box>
            </Grid>
            <Grid item className="close" xs={1} md={1}>
              <Avatar
                alt="Appointment booking icon"
                src={icons.cancel}
                onClick={handleClose}
              />
            </Grid>
          </Grid>
          <AppointmentOption
            onClick={gotoBooking}
            title="Book an Appointment"
            description="Book an Appointment for Yourself"
          />
        </Card>
      </Box>
    </Box>
  );
};
export default AppointmentBooking;
