import React,{useState} from 'react';
import { Box, Typography, Card, CardMedia, Chip, Grid } from '@mui/material';
import './NearbySalon.css';
import { icons } from '../../../Assets/icons';
import { images } from '../../../Assets/images';
import { nearBySalonDetailData } from '../../../../src/api/types/shop';

type NearbySalonProps = {
  data: nearBySalonDetailData;
  onShopClick: any;
};



const NearbySalon: React.FC<NearbySalonProps> = ({ data, onShopClick }) => {
  //localStorage.setItem('shopID', data.vendor_detail_id);


  
  
  return (
   
    <div className="ShopContainer" onClick={() => onShopClick(data.vendor_detail_id)}>
            <div className="imageSaloon">
              <img
                src={
                  data.vendor_media.media_name
              ? data.vendor_media.media_name
              : images.glam9512
                }
                alt={data.business_name}
                className={
                  data.vendor_media.media_name
                    ? 'featureImage'
                    : 'featureImage smallG9'
                }
              />
            </div>
            <div className="contentSaloon">
              <Typography
                variant="body1"
                className="boxTitle"
                style={{
                  fontSize: '18px',
                  fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {data.salon_type === '5' && (
                  <img
                    className="businessListImage"
                    src={images.crown}
                    alt="Crown"
                    onError={() => 'image not exists'}
                    style={{
                      width: 30,
                      height: 30,
                      marginRight: 5,
                      position: 'relative',
                      // top: 8,
                    }} // Set size and margin
                  />
                )}
                {data.business_name}
              </Typography>
              <Typography
                className="location"
                variant="body2"
                sx={{
                  marginTop: '0px',
                  fontSize: '14px',
                  color: 'rgba(35, 31, 32, 1)',
                  opacity: '30%',
                  fontWeight: '400',
                  height: '25px',
                }}
              >
               <span className="kmRange">2.5km</span><span className="lineDivider">|</span>{data.vendor_address?.city_name}, {data.vendor_address?.state_name}
              </Typography>
              <div>
                <Box
                  // marginTop="15px"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  // bgcolor='red'
                >
                  <Typography
                    className="ratingContent"
                    variant="body2"
                    style={{ color: 'rgba(35, 31, 32, 1)' }}
                  >
                    {data.rating}{' '}
                    <img className="rating" src={icons.rating} alt="Rating" />
                  </Typography>
                  <CardMedia
                    component="img"
                    className="searchImage"
                    alt="Vector"
                    src={icons.recommendedSearch}
                    sx={{
                      width: 40,
                      height: 40,
                      // transform: 'translate(50%, 50%)', // Adjust to fit within the box
                      cursor: 'pointer',
                    }}
                    
                  />
                </Box>
              </div>
            </div>
          </div>
        

  
  );
};

export default NearbySalon;
