import { useState, useRef, useEffect } from 'react';
import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import './DateSelector.css';
import { icons } from '../../../Assets/icons';

type DateSelectorProps = {
  appointmentData: any;
  setTimeSlots: Function;
  setSelectedDate: Function;
  selectedDate: string; // Added selectedDate from parent
  setSelectedTime: Function;
};

const DateSelector: React.FC<DateSelectorProps> = ({
  appointmentData,
  setTimeSlots,
  setSelectedDate,
  selectedDate,
  setSelectedTime,
}) => {
  const [activeDateIndex, setActiveDateIndex] = useState<number | null>(0); // Store active index
  const datesContainerRef = useRef<HTMLDivElement>(null);

  // Update active index and scroll position when selectedDate changes
  useEffect(() => {
    const index = appointmentData.findIndex(
      (data: any) => data.date === selectedDate,
    );
    if (index !== -1) {
      setActiveDateIndex(index);
      setTimeSlots(appointmentData[index].slots); // Update time slots
      scrollToIndex(index); // Move scroll to the active date
    }
  }, [selectedDate, appointmentData, setTimeSlots]);

  useEffect(() => {
    setSelectedTime('');
  }, [selectedDate]);

  // Function to scroll the container to a specific index
  const scrollToIndex = (index: number) => {
    if (datesContainerRef.current) {
      const childElement = datesContainerRef.current.children[
        index
      ] as HTMLElement;
      if (childElement) {
        const containerWidth = datesContainerRef.current.offsetWidth;
        const childOffset =
          childElement.offsetLeft + childElement.offsetWidth / 2;
        const scrollPosition = childOffset - containerWidth / 2;
        datesContainerRef.current.scrollTo({
          left: scrollPosition,
          behavior: 'smooth',
        });
      }
    }
  };

  const scrollContainer = (direction: string) => {
   /*  if (datesContainerRef.current) {
      const scrollAmount = direction === 'left' ? -150 : 150;
      datesContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    } */

      if (datesContainerRef.current) {
        let newIndex = activeDateIndex ?? 0; // Ensure it's a valid number
    
        if (direction === 'left' && newIndex > 0) {
          newIndex -= 1; // Move left
        } else if (direction === 'right' && newIndex < appointmentData.length - 1) {
          newIndex += 1; // Move right
        }
    
        setActiveDateIndex(newIndex);
        setSelectedDate(appointmentData[newIndex].date); // Update selected date
        setTimeSlots(appointmentData[newIndex].slots); // Update slots
        scrollToIndex(newIndex); // Scroll to new position
      }
  };

  return (
    <Box className="date-selector-container" >
      <Button aria-label="Previous week" className="navigation-button left" onClick={() => scrollContainer('left')} >
        {activeDateIndex === 0 ? (
          <img loading="lazy" src={icons.grayStart} style={{cursor:'default'}} alt="Left arrow" className="icon-image" />
        ):(
          <img loading="lazy" src={icons.teamLeftArrow} alt="Left arrow" className="icon-image" />
        )}
        
      </Button>
      <Box
        className="dates-container"
        ref={datesContainerRef}
        sx={{
          '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar
        }}
      >
        {appointmentData.map((data: any, index: number) => {
          const dateObj = new Date(data.date); // Convert to Date object
          const monthName = dateObj.toLocaleString('en-US', { month: 'short' }); // Get short month name
          return (
            <Box
              key={index}
              className={`date-box ${activeDateIndex === index ? 'selected' : ''}`} // Apply active class
              onClick={() => {
                setSelectedDate(data.date); // Update selected date in parent
                setTimeSlots(data.slots); // Update slots
                setActiveDateIndex(index); // Update active index
              }}
              sx={{
                '&.selected': {
                  backgroundColor: '#4E297B',
                  color: '#fff',
                },
              }}
            >
              <Typography variant="h6" className="date-text">
                {`${data.short_date.split(' ')[0]}-${monthName}`}{' '}
              </Typography>
              <Typography variant="body2" className="day-text">
                {data.short_day_name}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Button aria-label="Next week" className="navigation-button right" onClick={() => scrollContainer('right')} >
      {activeDateIndex === appointmentData.length - 1 ? (
    <img loading="lazy" src={icons.grayEnd} style={{cursor:'default'}} alt="Right arrow" className="icon-image" />
  ) : (
    <img loading="lazy" src={icons.rightArrow} alt="Right arrow" className="icon-image" />
  )}
      </Button>
    </Box>
  );
};

export default DateSelector;
