import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import './DeleteAccountSection.css';
import { CustomerAPI } from '../../hooks/customer';
import { images } from '../../Assets/images';
import Loading from '../../Common/Loader/Loading/Loading';

interface DeleteSectionProps {
  deleteAccountModalOpen: boolean;
  handleDeleteAccountDialogOpen: () => void;
  handleDeleteAccountDialogClose: () => void;
  handleDeleteAccount: () => void;
}

const DeleteAccountSection: React.FC<DeleteSectionProps> = ({
  deleteAccountModalOpen,
  handleDeleteAccountDialogOpen,
  handleDeleteAccountDialogClose,
  handleDeleteAccount,
}) => {
  return (
    <>
      <Box className="deleteAccountSection">
        <Box className="textContainer">
          <Typography variant="h3" className="heading">
            Delete Account
          </Typography>
          <Typography variant="body1" className="description">
            Are you sure you want to leave Glam9?
          </Typography>
        </Box>
        <Button
          variant="outlined"
          className="deleteButton"
          onClick={handleDeleteAccountDialogOpen}
        >
          Delete Account
        </Button>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={deleteAccountModalOpen}
        onClose={handleDeleteAccountDialogClose}
      >
        <DialogContent>
          <Typography>Are you sure you want to delete your account?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteAccountDialogClose}
            style={{ color: '#4E297B' }}
          >
            Cancel
          </Button>
          <Button onClick={handleDeleteAccount} color="error">
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteAccountSection;
