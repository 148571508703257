import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Import slick carousel styles
import "slick-carousel/slick/slick-theme.css";
import { PartnerImages } from './PartnerImages';

const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 200,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1500, // For tablets
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1024, // For tablets
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768, // For small tablets and large phones
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,
            },
        },
        {
            breakpoint: 480, // For mobile devices
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                arrows: false, // Optional: Hide arrows for cleaner UI
            },
        },
    ],
};


const Partners: React.FC = () => {

    const isMobile = useMediaQuery('(max-width: 600px)');

    return (
        <>
            <Box sx={{
                paddingBottom: '50px'
            }}>
                <Slider {...sliderSettings} className="custom-slider">
                    {PartnerImages.map((src, index) => (
                        <div
                            key={index}
                            data-aos="zoom-in-up"
                            data-aos-delay={index * 100}
                            className=" partner-box"
                            style={{ outline: 'none' }}
                        >
                            <Box>
                                <img src={src} alt="partner-image" style={{maxWidth: isMobile ? '150px' : '235px'}} />
                            </Box>
                        </div>
                    ))}
                </Slider>
            </Box>
        </>
    )
}

export default Partners