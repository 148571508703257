import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Typography } from '@mui/material';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import './Map.css'; // Importing the external CSS for styling
import { useNavigate } from 'react-router-dom';
import { icons } from '../../Assets/icons/index';

interface CategoryList {
  id: number;
  name: string;
  logo_link: string;
  sequence: number;
  is_premium_category: number;
}

interface MapProps {
  itemData: any; // Replace `any` with the actual type of `itemData` for better type safety
}

interface MapData {
  vendor_list: { data: any }[];
}
export const Map: React.FC<MapProps> = ({ itemData }) => {
  const navigate = useNavigate();
  const [mapData, setMapData] = useState<MapData | null>(null);
  const gotoShop = (id: string) => {
    try {
      localStorage.setItem('shopID', id.toString());
      navigate('/shop', { replace: true });
    } catch (error) {
      console.error('Error navigating to shop:', error);
    }
  };
  const mapContainerStyle = {
    width: '100%',
    height: '100%',
  };
  const containerStyle = {
    width: '100%', // Full viewport width
    height: '100%', // Adjust height based on screen size
  };
  const [data, setData] = useState<CategoryList[]>([]);
  //   const [staticLatitude, setStaticLatitude] = useState(
  //   itemData[0]?.vendor_address?.lattitude
  //     ? parseFloat(itemData[0].vendor_address?.lattitude)
  //     : 0
  // );
  // const [staticLongitude, setStaticLongitude] = useState(
  //   itemData[0]?.vendor_address?.longitude
  //     ? parseFloat(itemData[0].vendor_address?.longitude)
  //     : 0
  // );

  /* code commited  for display same marker which is in pagination */
  //   const [staticLatitude, setStaticLatitude] = useState(
  //   itemData[0]?.vendor_address?.lattitude
  //     ? parseFloat(itemData[0].vendor_address?.lattitude)
  //     : 0
  // );
  // const [staticLongitude, setStaticLongitude] = useState(
  //   itemData[0]?.vendor_address?.longitude
  //     ? parseFloat(itemData[0].vendor_address?.longitude)
  //     : 0
  // );
  /* code for display samer marker which is in pagination */

  const [staticLatitude, setStaticLatitude] = useState(0);
  const [staticLongitude, setStaticLongitude] = useState(0);

  const [zoomLevel, setZoomLevel] = useState(13);

  useEffect(() => {
    if (!itemData || itemData.length === 0) {
      return;
    }

    if (itemData[0].vendor_address?.lattitude) {
      setZoomLevel(13);
    } else {
      setZoomLevel(6);
    }
    setStaticLatitude(
      parseFloat(itemData[0].vendor_address?.lattitude || 20.5937),
    );
    setStaticLongitude(
      parseFloat(itemData[0].vendor_address?.longitude || 78.9629),
    );
  }, [itemData]);

  const apiBaseUrl =
    process.env.REACT_APP_API_BASE_URL + '/get-categories?use_for=2';
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiBaseUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []); // Run this only once on mount
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [iconOptions, setIconOptions] = React.useState<any>(null);
  const onScriptLoad = () => {
    // Safely create Google Maps objects after the script has loaded
    setIconOptions({
      url: icons.marker,
      scaledSize: new window.google.maps.Size(40, 40),
      labelOrigin: new window.google.maps.Point(0, -40),
    });
  };

  return (
    <div className="searchMap" style={containerStyle}>
      {process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={zoomLevel}
          center={{ lat: staticLatitude, lng: staticLongitude }}
          onLoad={onScriptLoad}
        >
          {(mapData && mapData.vendor_list && mapData.vendor_list.length > 0
            ? mapData.vendor_list[0].data
            : itemData
          ).map((marker: any, index: number) => (
            <Marker
              key={index}
              position={{
                lat: parseFloat(marker.vendor_address?.lattitude) || 23.0225,
                lng: parseFloat(marker.vendor_address?.longitude) || 72.5714,
              }}
              icon={iconOptions}
              label={{
                text: marker.business_name,
                color: 'black',
                fontSize: '10px',
                fontWeight: 'normal',
                className: 'marker-label',
              }}
              onClick={() => {
                setSelectedMarker(marker);
                gotoShop(marker.vendor_detail_id);
              }}
            />
          ))}
        </GoogleMap>
      ) : (
        <Typography variant="body1" color="textSecondary">
          Unable to load the map. Please check the location details.
        </Typography>
      )}
    </div>
  );
};
