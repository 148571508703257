import React from 'react';
import { Card, CardContent, Typography, Avatar, Rating } from '@mui/material';
import './reviewCard.css';
import { images } from '../../../Assets/images';
import { ReviewDetailsData } from '../../../../src/api/types/shop';

type ReviewCardProps = {
  data: ReviewDetailsData;
};

const ReviewCard: React.FC<ReviewCardProps> = ({ data }) => {
  return (
    <div className="review-card">
      <Card className="card">
        <CardContent className="card-content">
          <Avatar
            alt={`${data.customer_name}'s avatar`}
            src={data.customer_profile || images.glam9512}
            className="avatar"
            sx={{
              width: {
                xs: '50px', // small screen
                sm: '30px', // small to medium screen
                md: '40px', // medium to large screen
                lg: '50px', // large screen
                xl: '50px', // extra large screen
              },
              height: {
                xs: '50px', // small screen
                sm: '30px', // small to medium screen
                md: '40px', // medium to large screen
                lg: '50px', // large screen
                xl: '50px', // extra large screen
              },
            }}
          />
          <div className="content">
            <div className="header">
              <Typography variant="h6" className="name">
                {data.customer_name}
                {}
              </Typography>
              <Rating
                value={data.rating}
                precision={0.5}
                readOnly
                sx={{ marginRight: 2, fontSize: '35px' }}
              />
            </div>
            <Typography variant="body2" className="date">
              {data.created_date}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginTop: {
                  xs: '12px', // small screen
                  sm: '10px', // small to medium screen
                  md: '20px', // medium to large screen
                  lg: '10px', // large screen
                  xl: '30px', // extra large screen
                },
              }}
              className="comment"
            >
              {data.description}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ReviewCard;
