import React, { useEffect, useState, useRef } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import DateSelector from './DateSelector/DateSelector';
import TimeSelector from './TimeSelector/TimeSelector';
import './Time.css'; // Import the separate CSS file
import {
  appointmentAPIResponse,
  appointmentObject,
  appointmentSlotRequestInterface,
} from '../../api/types/appointment_slots';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { appointmentDetailsObject } from '../../api/types/appointment_details';
import { ShopAPI } from '../../hooks/shop';
import { useBreadcrumbs } from '../../contexts/BreadcrumbContext';

interface timeProps {
  appointmentData: appointmentObject[] | null;
  checkAppointmentAvaibilityAPICallFunc: Function;
  bookPage: any;
  getAppoinmentSlotsAPICallFunc: Function;
  vendorID: number;
}

const Time: React.FC<timeProps> = ({
  appointmentData,
  checkAppointmentAvaibilityAPICallFunc,
  bookPage,
  getAppoinmentSlotsAPICallFunc,
  vendorID,
}) => {
  const { getAppoinmentSlotsAPICall } = ShopAPI();

  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [timeSlots, setTimeSlots] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const datePickerRef = useRef<HTMLDivElement | null>(null);

  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [firstDate, setFirstDate] = useState<Dayjs | null>(null);
  const [lastDate, setLastDate] = useState<Dayjs | null>(null);
  const [isSelecting, setIsSelecting] = useState(false);

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    if (!appointmentData) return;

    if (appointmentData && appointmentData.length > 0) {
      const availableData = appointmentData
        .filter(item => item.is_available === '1')
        .map(item => item.date);

      if (availableData.length > 0) {
        setFirstDate(dayjs(availableData[0]));
        setLastDate(dayjs(availableData[availableData.length - 1]));
      }

      if (!selectedDate) {
        setSelectedDate(availableData[0]);
        setTimeSlots(
          appointmentData.find(item => item.date === availableData[0])?.slots ||
            [],
        );
      }

      setAvailableDates(availableData || []);
    }
  }, [appointmentData]);

  useEffect(() => {
    if (!selectedDate) return;

    const selectedAppointment = appointmentData?.find(
      item => item.date === selectedDate,
    );
    if (selectedAppointment) {
      setTimeSlots(selectedAppointment.slots);
    }
  }, [selectedDate, appointmentData]);

  useEffect(() => {
    setBreadcrumbs(prevBreadcrumbes =>
      prevBreadcrumbes.map(breadcrumb =>
        breadcrumb.id === bookPage
          ? { ...breadcrumb, isVisited: true }
          : breadcrumb,
      ),
    );
  }, []);

  const shouldDisableDate = (date: Dayjs) => {
    if (!firstDate || !lastDate) return false;

    if (date.isBefore(firstDate, 'day')) return true;
  if (date.isAfter(lastDate, 'day')) return true;

    return !availableDates.includes(date.format('YYYY-MM-DD'));
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.format('YYYY-MM-DD');
      const isUnavailable = !availableDates.includes(date.format('YYYY-MM-DD'));

      if (isUnavailable) {
        const slotDate = date.format('DD') || new Date().getDate();
        const slotMonth = date.format('MM') || new Date().getMonth() + 1;
        const slotYear = date.format('YYYY') || new Date().getFullYear();
        getAppoinmentSlotsAPICallFunc(vendorID, slotMonth, slotYear);
      }

      setSelectedDate(formattedDate);
      setOpen(false);
    }
  };

  const handleContainerClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (open && datePickerRef.current) {
      const inputElement = datePickerRef.current.querySelector('input');

      if (inputElement instanceof HTMLInputElement) {
        setTimeout(() => {
          inputElement.focus();
          inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length); // Set cursor to the end
        }, 0);
      }
    }
  }, [open]);

  return (
    <Grid item xs={12} md={9} className="team-selection">
      <Box
        className="appointment-booking"
        sx={{ pb: { xs: 3, md: 12 }, backgroundColor: 'white' }}
      >
        <Container className="bookingTime" maxWidth="lg">
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mt: 2,
                    }}
                  >
                    <Typography variant="h4" className="time-selection-title">
                      Select Time
                    </Typography>
                    <div onClick={handleContainerClick}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            ref={datePickerRef}
                            format="DD/MM/YYYY"
                            value={selectedDate ? dayjs(selectedDate) : null}
                            open={open}
                            disablePast
                            onChange={handleDateChange}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            shouldDisableDate={shouldDisableDate}
                            className="custom-date-picker"
                            slotProps={{
                              textField: {
                                variant: 'outlined',
                                fullWidth: true,
                                onFocus: (e) => e.target.select(), // Prevent text selection
                              },
                              day: ownerState => ({
                                sx: {
                                  ...(selectedDate &&
                                  dayjs(selectedDate).isSame(
                                    ownerState.day,
                                    'day',
                                  )
                                    ? {
                                        backgroundColor: '#4E297B',
                                      }
                                    : {}),
                                },
                              }),
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <DateSelector
                    setSelectedDate={setSelectedDate}
                    setTimeSlots={setTimeSlots}
                    setSelectedTime={setSelectedTime}
                    appointmentData={appointmentData}
                    selectedDate={selectedDate}
                  />
                  {timeSlots && timeSlots.length > 0 ? (
                    <TimeSelector
                      setSelectedTime={setSelectedTime}
                      timeSlots={timeSlots}
                      selectedTime={selectedTime}
                      selectedDate={selectedDate}
                      checkAppointmentAvaibilityAPICallFunc={
                        checkAppointmentAvaibilityAPICallFunc
                      }
                    />
                  ) : (
                    ''
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Grid>
  );
};

export default Time;