import React from 'react';
import { Box, Typography } from '@mui/material';

interface AppointmentOptionProps {
  title: string;
  description: string;
  onClick: () => void;
}

const AppointmentOption: React.FC<AppointmentOptionProps> = ({
  title,
  description,
  onClick,
}) => (
  <Box onClick={onClick} className="appointmentOptionContainer" >
    <Typography variant="h5" align="center" fontWeight="bold">
      {title}
    </Typography>
    <Typography variant="body1" >
      {description}
    </Typography>
  </Box>
);

export default AppointmentOption;
