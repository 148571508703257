import React from 'react';
import { Box, Typography, Button, Card, CardContent } from '@mui/material';
import './CouponCard.css';

interface CouponCardProps {
  title: string;
  description: string;
  className: string;
  code: string;
  isLocked?: boolean;
  unlockMessage?: string;
  onApplyCoupon: () => void; // Function to handle apply coupon
  handleClose: () => void; // Function to handle closing the dialog
}

const CouponCard: React.FC<CouponCardProps> = ({
  title,
  description,
  code,
  className,
  isLocked,
  unlockMessage,
  onApplyCoupon,
  handleClose,
}) => {
  const handleApplyClick = () => {
    onApplyCoupon(); // Trigger background color change to blue
    handleClose(); // Close the popup
  };

  return (
    <Card className="coupon-card">
      <CardContent className={className}>
        <Box className="coupon-content">
          <Box className="coupon-text">
            <Typography variant="h5" className="coupon-title">
              {title}
            </Typography>
            <Typography
              variant="body2"
              className={`coupon-description ${isLocked ? 'locked' : ''}`}
            >
              {description}
            </Typography>
            <Box className="coupon-code">{code}</Box>
            {isLocked && unlockMessage && (
              <Typography variant="caption" className="unlock-message">
                {unlockMessage}
              </Typography>
            )}
          </Box>
          <Box className="coupon-button-container">
            <Button
              variant="contained"
              color="primary"
              className={className}
              onClick={handleApplyClick} // Apply the coupon and close the popup
            >
              Apply Now
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CouponCard;
