import React, { createContext, useContext, useState } from 'react';

type Breadcrumb = {
  id: number;
  label: string; // The text to display
  componentKey: string; // Unique key for each breadcrumb (e.g., "dashboard", "profile")
  isVisited: boolean; //key that matches visted or not visited
};

const initialBreadcrumbs: Breadcrumb[] = [
  {
    id: 1,
    label: 'Select Service',
    componentKey: 'selectService',
    isVisited: true,
  },
  { id: 2, label: 'Select Team', componentKey: 'selectTeam', isVisited: false },
  { id: 3, label: 'Time', componentKey: 'time', isVisited: false },
  { id: 4, label: 'Confirm', componentKey: 'confirm', isVisited: false },
];

type BreadcrumbContextType = {
  breadcrumbs: Breadcrumb[];
  // setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Breadcrumb[]>>;
  visitedSteps: number[];
  setVisitedSteps: (step: number[]) => void;
  appointMentJourney: {};
  setAppointMentJourney: (journey: any) => void;
};

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(
  undefined,
);

export const BreadcrumbProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [breadcrumbs, setBreadcrumbs] =
    useState<Breadcrumb[]>(initialBreadcrumbs);
  const [currentStep, setCurrentStep] = useState<number>(1);

  const [visitedSteps, setVisitedSteps] = useState<number[]>(() => {
    return JSON.parse(localStorage.getItem('visitedSteps') || '[]');
  });

  const [appointMentJourney, setAppointMentJourney] = useState(() => {
    return JSON.parse(localStorage.getItem('appointMentJourney') || '{}');
  });

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbs,
        setBreadcrumbs,
        visitedSteps,
        setVisitedSteps,
        appointMentJourney,
        setAppointMentJourney,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumbs = () => {
  const context = useContext(BreadcrumbContext);
  if (!context) {
    throw new Error('useBreadcrumbs must be used within a BreadcrumbProvider');
  }
  return context;
};
