import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  Checkbox,
  ListItemText,
  Chip,
} from '@mui/material';
import './ListingFilters.css';
import StarIcon from '@mui/icons-material/Star'; // MUI Star icon
interface FiltersProps {
  onApplyFilters: (filters: Record<string, any>) => void;
  categoryData: { id: number; name: string; logo_link: string }[];
  setSortBy: Function;
  filter_type: any;
  sortBy: any;
}

const ListingFilters: React.FC<FiltersProps> = ({
  onApplyFilters,
  categoryData,
  setSortBy,
  filter_type,
  sortBy,
}) => {
  const [nearest, setNearest] = useState(false);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [reviews, setReviews] = useState('');
  const [radius, setRadius] = useState('');
  const [priceRange, setPriceRange] = useState('');
  const [locationEnabled, setLocationEnabled] = useState(true);
  const [category, setCategory] = useState('');
  // const [gender, setGender] = useState('');
  const [gender, setGender] = useState<string[]>([]);

  const getLocation = () => {
    if (!navigator.geolocation) {
      alert('Geolocation is not supported by your browser.');
      // setLocationEnabled(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      position => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      // error => console.error('Error getting location:', error),
      error => {
        alert('Please enable location services to use this filter.');
        setLocationEnabled(false);

        // if (nearest) {
        //   setNearest(false);
        // }
        setRadius('');

        console.error('Error getting location:', error);
      },
    );
  };

  // Handle "Radius" selection
  const handleRadiusChange = (event: any) => {
    if (!locationEnabled) {
      getLocation(); // Prompt user for location if not enabled
      return;
    }

    const selectedRadius = event.target.value;
    setRadius(selectedRadius);

    if (selectedRadius || nearest) {
      getLocation(); // Fetch location when "Nearest" or "Radius" is selected
    } else {
      setLatitude(null);
      setLongitude(null);
    }
  };

  const [clicked, setClicked] = useState(false);
  // Apply selected filters
  const handleSearch = () => {
    setClicked(true);
    const appliedFilters: Record<string, any> = {};
    // if (nearest && latitude && longitude) {
    //   appliedFilters.nearest = '1';
    //   appliedFilters.lattitude = latitude.toString();
    //   appliedFilters.longitude = longitude.toString();
    // }

    if ((radius || latitude) && latitude && longitude) {
      appliedFilters.lattitude = latitude.toString();
      appliedFilters.longitude = longitude.toString();
    }
    if (reviews) appliedFilters.rates = reviews;
    if (radius) appliedFilters.radius = radius;
    if (priceRange) {
      const [min, max] = priceRange.split('-');
      appliedFilters.min_price = min;
      appliedFilters.max_price = max;
    }
    if (category) appliedFilters.category_id = category;
    // if (gender) appliedFilters.gender_type = gender;
    if (gender.length > 0) {
      // appliedFilters.gender_type = gender.join(','); // Convert array to comma-separated string
      appliedFilters.gender_type = decodeURIComponent(gender.join(',')); // Convert array to comma-separated string
    }

    onApplyFilters(appliedFilters);
  };

  const handleClearFilters = () => {
    setLatitude(null);
    setLongitude(null);
    setReviews('');
    setRadius('');
    setPriceRange('');
    setCategory('');
    setGender([]);
    setSortBy('');
    setClicked(false);

    // Pass an empty object to reset API filters
    onApplyFilters({});
  };

  const genderOptions = [
    { value: '1', label: 'Male' },
    { value: '2', label: 'Female' },
    { value: '3', label: 'Unisex' },
  ];

  const handleGenderChange = (event: any) => {
    const value = event.target.value;
    setGender(typeof value === 'string' ? value.split(',') : value);
    // setSelectedGenders(event.target.value);
  };

  const renderStars = (rating: number) => {
    return Array.from({ length: rating }, (_, index) => (
      <StarIcon key={index} sx={{ color: '#FFD700', fontSize: '16px' }} />
    ));
  };

  return (
    <>
      <Grid container spacing={2} className="search-grid listingFilters">
        <Grid className="clearFilter" item xs={12} md={4} xl={2} lg={2}>
          <Button
            onClick={handleClearFilters}
            className={`clearSearch ${!sortBy && !category && gender.length === 0 && !priceRange && !reviews && !radius ? 'disabled' : ''}`}
            disabled={
              !sortBy &&
              !category &&
              gender.length === 0 &&
              !priceRange &&
              !reviews &&
              !radius
            }
          >
            Clear All
          </Button>
        </Grid>

        <Grid className="categoryFilter" item xs={12} md={4} xl={2} lg={2}>
          <FormControl variant="outlined" fullWidth>
            <Select
              labelId="review-select-label"
              value={category}
              onChange={e => setCategory(e.target.value.toString())}
              displayEmpty
              MenuProps={{
                // Disable scroll locking
                disableScrollLock: true,
              }}
              className={`${category ? 'selected' : ''}`}
              sx={{
                '& .MuiSelect-select': {
                  color: category ? '#FFFFFF' : '#231f20',
                  background: category ? '#4E297B' : '#FFFFFF',
                },
              }}
            >
              <MenuItem value="">
                <em>Category</em>
              </MenuItem>
              {categoryData.map(row => (
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* MultiSelect with Checkmarks */}
        <Grid className="genderFilter" item xs={12} md={4} xl={2} lg={2}>
          <FormControl fullWidth>
            <Select
              multiple
              displayEmpty
              className={`${gender.length > 0 ? 'selected' : ''}`}
              sx={{
                '& .MuiSelect-select': {
                  color: gender.length > 0 ? '#FFFFFF' : '#231f20',
                  background: gender.length > 0 ? '#4E297B' : '#FFFFFF',
                },
              }}
              value={gender}
              onChange={handleGenderChange}
              renderValue={
                () =>
                  gender.length > 0
                    ? `Gender (${gender.length})` // Show count
                    : 'Gender' // Placeholder remains
              }
              MenuProps={{
                // Disable scroll locking
                disableScrollLock: true,
              }}
            >
              {genderOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={gender.includes(option.value)} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid className="genderFilter" item xs={12} md={4} xl={2} lg={2}>
          <FormControl variant="outlined" fullWidth>
            <Select
              labelId="review-select-label"
              value={gender}
              onChange={e => setGender(e.target.value)}
              className={`${gender ? 'selected' : ''}`}
              sx={{
                '& .MuiSelect-select': {
                  color: gender ? '#FFFFFF' : '#231f20',
                  background: gender ? '#4E297B' : '#FFFFFF',
                },
              }}
              displayEmpty
              MenuProps={{
                // Disable scroll locking
                disableScrollLock: true,
              }}
            >
              <MenuItem value="">
                <em>Gender</em>
              </MenuItem>
              <MenuItem value={'1'}>Male</MenuItem>
              <MenuItem value={'2'}>Female</MenuItem>
              <MenuItem value={'3'}>Unisex</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        <Grid className="priceFilter" item xs={12} md={4} xl={2} lg={2}>
          <>
            <FormControl variant="outlined" fullWidth>
              <Select
                labelId="review-select-label"
                value={priceRange}
                className={`${priceRange ? 'selected' : ''}`}
                sx={{
                  '& .MuiSelect-select': {
                    color: priceRange ? '#FFFFFF' : '#231f20',
                    background: priceRange ? '#4E297B' : '#FFFFFF',
                  },
                }}
                onChange={e => {
                  setPriceRange(e.target.value);
                }}
                displayEmpty
                MenuProps={{
                  // Disable scroll locking
                  disableScrollLock: true,
                }}
              >
                <MenuItem value="">
                  <em>Price</em>
                </MenuItem>
                <MenuItem value={'1-5000'}>1 - 5 K</MenuItem>
                <MenuItem value={'5000-10000'}>5 K - 10 K</MenuItem>
                <MenuItem value={'10000-15000'}>10 K - 15 K</MenuItem>
                <MenuItem value={'15000-20000'}>15 K - 20 K</MenuItem>
                <MenuItem value={'20000-25000'}>20 K - 25 K</MenuItem>
              </Select>
            </FormControl>
          </>
        </Grid>

        <Grid className="reviewsFilter" item xs={12} md={4} xl={2} lg={2}>
          <FormControl variant="outlined" fullWidth>
            <Select
              labelId="review-select-label"
              value={reviews}
              onChange={e => setReviews(e.target.value)}
              className={`${reviews ? 'selected' : ''}`}
              sx={{
                '& .MuiSelect-select': {
                  color: reviews ? '#FFFFFF' : '#231f20',
                  background: reviews ? '#4E297B' : '#FFFFFF',
                },
              }}
              displayEmpty
              MenuProps={{
                // Disable scroll locking
                disableScrollLock: true,
              }}
            >
              <MenuItem value="">
                <em>Reviews</em>
              </MenuItem>

              {filter_type == '3' || filter_type == '4'
                ? [5, 4].map(rating => (
                    <MenuItem
                      key={rating}
                      value={rating}
                      sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}
                    >
                      {rating} {renderStars(1)} {rating < 5 && ' & above'}
                    </MenuItem>
                  ))
                : [5, 4, 3, 2, 1].map(rating => (
                    <MenuItem
                      key={rating}
                      value={rating}
                      sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}
                    >
                      {rating} {renderStars(1)} {rating < 5 && ' & above'}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid className="distanceFilter" item xs={12} md={4} xl={2} lg={2}>
          <FormControl variant="outlined" fullWidth>
            <Select
              labelId="demo-simple-select-label2"
              value={radius}
              // onChange={e => setRadius(e.target.value)}
              onChange={handleRadiusChange}
              className={`${radius ? 'selected' : ''}`}
              sx={{
                '& .MuiSelect-select': {
                  color: radius ? '#FFFFFF' : '#231f20',
                  background: radius ? '#4E297B' : '#FFFFFF',
                },
              }}
              displayEmpty
              MenuProps={{
                // Disable scroll locking
                disableScrollLock: true,
              }}
            >
              <MenuItem value="">
                <em>Distance</em>
              </MenuItem>
              <MenuItem value="2">2 KM Radius</MenuItem>
              <MenuItem value="4">4 KM Radius</MenuItem>
              <MenuItem value="6">6 KM Radius</MenuItem>
              <MenuItem value="8">8 KM Radius</MenuItem>
              <MenuItem value="10">10 KM Radius</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid className="searchFilter" item xs={12} md={4} xl={2} lg={2}>
          <Button className={`filterApply`} onClick={handleSearch}>
            Apply
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default ListingFilters;

{
  /* <Grid
          className="nearByFilter"
          item
          xs={12}
          md={4}
          xl={2}
          lg={2}
          // onClick={handleNearestClick}
        >
          <div
            className={`nearestFilter ${nearest ? 'selected' : ''}`}
            onClick={handleNearestClick}
          >
            Nearest
          </div>
        </Grid> */

  {
    /* <Box>
              <Slider
                getAriaLabel={() => 'Price range'}
                value={priceRange}
                onChange={handlePriceChange}
                // onChange={(_, newValue) => setPriceRange(newValue as number[])}
                // onMouseDown={handleMouseDown}
                valueLabelDisplay="auto"
                marks={marks}
                min={0}
                max={25000}
                step={1}
                getAriaValueText={(value: any) => `${value}`}
                // disabled={sliderDisabled}
              />
            </Box> */
  }

  // if (priceRange[0] !== 0 || priceRange[1] !== 25000) {
  //   appliedFilters.min_price = priceRange[0].toString();
  //   appliedFilters.max_price = priceRange[1].toString();
  // }

  // Handle "Nearest" selection
  // const handleNearestClick = () => {
  //   if (!locationEnabled) {
  //     getLocation(); // Prompt user for location if not enabled

  //     return;
  //   }

  //   const newNearest = !nearest;
  //   setNearest(newNearest);

  //   if (newNearest || radius) {
  //     getLocation(); // Fetch location when "Nearest" or "Radius" is selected
  //   } else {
  //     setLatitude(null);
  //     setLongitude(null);
  //   }
  // };

  // Handle PriceRange Change
  // const handlePriceChange = (_: any, newValue: number | number[]) => {
  //   let [min, max] = newValue as number[];
  //   if (min >= max) {
  //     // min = max;
  //     min = max - 100;
  //   } // Prevent min from exceeding max

  //   if (min === 0 && max === 0) {
  //     min = 0;
  //     max = 25000;
  //   }

  //   setPriceRange([min, max]);
  // };
}
