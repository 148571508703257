import { Box, Typography } from '@mui/material';
import React from 'react';
import "./Leadership.css"
import {useLocation, Link} from 'react-router-dom';
import { images } from '../../../src/Assets/images'
import LinkedIcon from '../../Assets/icons/linkedIn.svg'
//import Linkedin from '../../../src/Assets/images/Linkedin.png'


const leadershipData = [
    { name: "Mehul Savani", image: images.firstLeadership , position: "Founder & Director"},
    { name: "Gaurav Thaker", image: images.secondLeadership , position: "Founder & Director"},
 /*   { name: "Dhruv Shah", image: images.thirLeadership, position: "CMO – Chief Marketing Officer" }, */
];

const linkdinURL = [
    "https://www.linkedin.com/in/mehul-savani-32417b6/",
    "https://www.linkedin.com/in/gaurav-thaker-93b59a2/",
   /* "https://www.linkedin.com/in/dhruv-shah-digital-marketer-seo-expert-google-ads-expert-social-media-marketer",  */
];

const LeaderShip: React.FC = () => {
    return (
        <>
            <Box className='leadership-wrapper'>
                <Box className='leadership-container'>
                    <Typography className='para-leadership-label'>
                        The brains behind this endeavor
                    </Typography>
                    <Typography className='head-leadership-label'>
                        Meet Our Visionary <span>Leaderships</span>
                    </Typography>

                    {/* Cards Section */}
                    <Box className='cards-container'>
                        {leadershipData.map((leader, index) => (
                            <Box className="leadership-card" key={index} sx={{
                                backgroundColor: 'white'
                            }}>
                                <img
                                    src={leader.image}
                                    alt={leader.name}
                                    className="leadership-image"
                                />
                                <Box className="leader-container" sx={{
                                }}>
                                    <Box className="leader-wrapper" >
                                        <Typography className="leader-name-text" sx={{
                                            fontSize: '22px',
                                            fontWeight: 600
                                        }}>{leader.name}</Typography>

                                        <Typography className="gray-label"  sx={{
                                            fontWeight: 500,
                                            fontSize: '15px',
                                            color:'#000',
                                            opacity:'50%'
                                        }}>{leader.position}</Typography>

                                       {/* <img src={LinkedIcon} alt="LinkedIn Icon" /> */}
                                       <a href={linkdinURL[index]} target="_blank" rel="noopener noreferrer">
                                            <img src={images.linkedin} alt="LinkedIn Icon" />
                                        </a>
                                        

                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default LeaderShip;
