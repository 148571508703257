import React from 'react'
import { icons } from '../../Assets/icons';
import './ComminSoon.css';
import { Link } from 'react-router-dom/dist';

const CommingSoon = () => {
  return (
    <div className="commingSoon">
        <div className="noContent">
            <div className="mapIcon">
                <img alt="iOS" src={icons.mapPurple} />
            </div>
            <div className="commingSoonTitle">Stay tuned! We’re coming your way soon!</div>
            <div className="commingSoonText">Sorry, we’re not available in your area right now.
            We’ll let you know as soon as we are!</div>
            <div className="followUs">
                <div className="followText">Follow Us</div>
                    <ul className="socialIcons">
                        <li><Link to="https://www.facebook.com/glam9official" target='_blank'><img alt="iOS" src={icons.fbColor} /></Link></li>
                        <li><Link to="https://x.com/glam9_beauty" target='_blank'><img alt="iOS" src={icons.twitterColor} /></Link></li>
                        <li><Link to="https://www.linkedin.com/company/glam9-solutions-private-limited/" target='_blank'><img alt="iOS" src={icons.linkedinColor} /></Link></li>
                        <li><Link to="https://www.instagram.com/glam9.beauty/" target='_blank'><img alt="iOS" src={icons.instagramColor} /></Link></li>
                        <li><Link to="https://www.youtube.com/@Glam9.Beauty" target='_blank'><img alt="iOS" src={icons.youtubeColor} /></Link></li>
                    </ul>                
            </div>
        </div>
    </div>
  )
}

export default CommingSoon
