// src/api/apiClient.ts

// Common interface for all API requests
const { REACT_APP_API_BASE_URL } = process.env;


export interface ApiRequestOptions {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: any; // For POST/PUT requests
  headers?: Record<string, string>;
}

const BASE_URL = REACT_APP_API_BASE_URL; // Replace with your API base URL

// Common function to call any API
export const apiClient = async <T>(endpoint: string, options: ApiRequestOptions,): Promise<T> => {
  const customerAuth = localStorage.getItem('customer_auth_token');
  const { method, body, headers = {} } = options;
  const config: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${customerAuth}`,
      ...headers,
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
  };
  const response = await fetch(`${BASE_URL}${endpoint}`, config);
  
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  
  const data = await response.json();
    return data as T;
  /* if(BASE_URL != 'https://glam9.ybtest.co.in/api/v1'){
    const proxyUrl = 'https://admin.vreserv.com/api/v1';
    const response = await fetch(`${proxyUrl}${endpoint}`, config);   
    const data = await response.json();
    return data as T;
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  }else{
    const response = await fetch(`${BASE_URL}${endpoint}`, config);
    const data = await response.json();
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return data as T;
  } */

};

export const apiClientForFiles = async <T>(endpoint: string, options: ApiRequestOptions,): Promise<T> => {
  const customerAuth = localStorage.getItem('customer_auth_token');
  const { method, body, headers = {} } = options;
  const config: RequestInit = {
    method,
    headers: {
      Authorization: `Bearer ${customerAuth}`,
      ...headers,
    },
    ...(body ? { body: body } : {}),
  };
  const response = await fetch(`${BASE_URL}${endpoint}`, config);

  const data = await response.json();
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return data as T;
  /* if(BASE_URL != 'https://glam9.ybtest.co.in/api/v1'){
    const proxyUrl = 'https://admin.vreserv.com/api/v1';
    const response = await fetch(`${proxyUrl}${endpoint}`, config);
    const data = await response.json();
    return data as T;
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  }else{
    const response = await fetch(`${BASE_URL}${endpoint}`, config);
    const data = await response.json();
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return data as T;
  } */

};
