import React from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import "./BoxComponent.css";

interface BoxComponentProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  buttonText: string;
  onButtonClick: () => void;
}

const BoxComponent: React.FC<BoxComponentProps> = ({
  icon,
  title,
  description,
  buttonText,
  onButtonClick,
}) => {
  return (
    <Box className="box-container">
      {/* Icon */}
      <IconButton className="box-icon">{icon}</IconButton>

      <Box className="box-content">
        <Typography className="box-title">{title}</Typography>

        {/* Description */}
        <Typography className="box-description">{description}</Typography>
      </Box>

      {/* Button */}
      <Button onClick={onButtonClick} className="box-button">
        {buttonText}
      </Button>
    </Box>
  );
};

export default BoxComponent;
