import React, { useState } from 'react';
import {
  Box,
  Typography,
  Checkbox,
  Button,
  TextField,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Grid,
} from '@mui/material';
import './creatAccount.css';
import BrandLogo from '../login/brandLogo/brandLogo';
import PhoneInput from '../login/phoneInput/phoneInput';
import AuthService from '../../Services/auth.service';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useCookies } from 'react-cookie';
import CommonCookie from '../../Components/CommonCookie';
import { images } from '../../../src/Assets/images';
import { icons } from '../../Assets/icons';
import Spinner from '../../../src/Common/Loader/Spinner/Spinner';
import { setUserDetails } from '../../redux/features/userSlice';
import { useAppDispatch } from '../../redux/hooks';
import { validatePhoneNumber } from '../../Common/CommonFunctions/common';
const CreateAccount = () => {
  const authService = AuthService();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  // for glam9 build
  // const shopPath = location?.state?.from === '/shop' && '/booking';
  const shopPath = location?.state?.from || '/';

  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  // const [passwordError, setPassworderror] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  let [first, setFirst] = useState('');
  const [firstError, setFirstError] = useState('');
  let [last, setLast] = useState('');
  const [lastError, setLastError] = useState('');
  let [number, setNumber] = useState(location?.state?.email || '');
  const [isBlurred, setIsBlurred] = useState<boolean>(false);
  const [numberError, setNumberError] = useState('');
  let [frmemail, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [terms, setTerms] = useState<string | boolean>('');
  const [termsError, setTermsError] = useState('');
  const dispatch = useAppDispatch();

  const handleChange = (event: any) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setTerms('1');
    }
  };

  const validateEmail = (frmemail: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(frmemail);
  };

  function addAccountInfo() {
    setFirstError('');
    setLastError('');
    // setPassworderror("");
    // setNumberError('');
    setTermsError('');
    setEmailError('');

    let isValid = true;
    first = first.trim();
    last = last.trim();
    number = number.trim();
    frmemail = frmemail.trim();

    setNumberError(validatePhoneNumber(number));
    setIsBlurred(true);

    if (first === '') {
      setFirstError('Please enter your first Name');
      isValid = false;
    }

    if (last === '') {
      setLastError('Please enter your last Name');
      isValid = false;
    }

    if (!validateEmail(frmemail)) {
      setEmailError('Invalid Email! Please enter valid Email');
      isValid = false;
    }
    if (frmemail === '') {
      setEmailError('Please enter your email');
      isValid = false;
    }

    if (number.length !== 10 || isNaN(parseInt(number)) || number === '') {
      isValid = false;
    }

    // if (number.length !== 10) {
    //   setNumberError('phone number should be 10 digits');
    //   isValid = false;
    // }
    // if (isNaN(parseInt(number))) {
    //   setNumberError('Phone Number should be numeric');
    //   isValid = false;
    // }
    // if (number === '') {
    //   setNumberError('Please enter your Phone Number');
    //   isValid = false;
    // }

    if (!isChecked) {
      setTermsError('Please Accept Terms and Contitions');
      isValid = false;
    }

    if (isValid) {
      registerCustomer();

      setFirst('');
      setLast('');
      setNumber('');
      setEmail('');
      setFirstError('');
      setLastError('');
      setEmailError('');
      setNumberError('');
      setTermsError('');
      setIsBlurred(false);
    }
  }

  const sendOtpForRegister = async () => {
    const fd = new FormData();
    fd.append('country_code', '91');
    fd.append('country_iso', 'in');
    fd.append('mobile', number || '');
    fd.append('is_web', '1');

    try {
      const response = await authService().sendOtp(fd);

      //return false;
      if (response.data.success) {
        localStorage.setItem('otp_verification', 'true');
        // toast.success(response.data.message);

        // for glam9 build
        // shopPath need to enable and can find from line 30 to 40
        navigate('/otp', {
          state: { number: number, prevPage: '/register', from: shopPath },
        });

        // for vreserv build
        // navigate('/otp', {
        //   state: { number: number, prevPage: '/register' },
        // });
      } else {
        navigate('/register');
      }
    } catch (error: any) {}
  };

  const registerCustomer = async () => {
    const fd = new FormData();
    fd.append('country_code', '91');
    fd.append('country_iso', 'in');
    fd.append('mobile', number || '');
    fd.append('email', frmemail || '');
    fd.append('first_name', first || '');
    fd.append('last_name', last || '');
    try {
      setLoading(true);
      const response = await authService().register(fd);
      if (response.data.success) {
        //toast.success(response.data.message);

        localStorage.setItem('customer_auth_token', response.data.token);
        dispatch(setUserDetails(response.data.data));
        setLoading(false);
        localStorage.setItem(
          'customer_details',
          JSON.stringify(response.data.data),
        );
        setCookie(CommonCookie.token, response.data?.data?.token, {
          path: '/',
          secure: true,
          sameSite: 'lax',
        });
        // navigate('/profile');
        sendOtpForRegister();
      } else {
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (error: any) {
      toast.error(error?.data?.message || 'Oops, Something went wrong.');
      setLoading(false);
    }
  };
  return (
    <Box className="register login">
      <Grid container spacing={2} className="grid-container">
        <Grid item xs={12} md={6} className="login-section">
          <Box className="form-container">
            <Box className="form-card">
              <form className="form">
                <div className="formContent">
                  <Box className="topBar">
                    {/* for glam9 build */}
                    <Link to="/login">
                      <img
                        loading="lazy"
                        src={icons.purpleBack}
                        alt=""
                        className="login-form-logo"
                      />
                    </Link>

                    {/* for vreserv build */}
                    {/* <Link to="/">
                      <img
                        loading="lazy"
                        src={icons.purpleBack}
                        alt=""
                        className="login-form-logo"
                      />
                    </Link> */}
                    <div className="logoMobile">
                      <img src={images.logo} />
                    </div>
                  </Box>
                  <Typography
                    variant="h4"
                    component="h1"
                    className="form-title"
                  >
                    Create Account
                  </Typography>
                  {/* <Typography variant="body1" className="form-subtitle">
                    You're almost there! Create your new account for
                    company@gmail.com by completing these details
                  </Typography> */}
                  <Box className="input-group">
                    <TextField
                      // label="First Name"
                      placeholder="First Name"
                      type="text"
                      fullWidth
                      value={first}
                      error={!!firstError} // Boolean for error prop
                      helperText={firstError} // Show error message (optional)
                      onChange={e => setFirst(e.target.value)}
                    />
                    <TextField
                      // label="Last Name"
                      placeholder="Last Name"
                      type="text"
                      fullWidth
                      value={last}
                      error={!!lastError} // Boolean for error prop
                      helperText={lastError} // Show error message (optional)
                      onChange={e => setLast(e.target.value)}
                    />
                  </Box>
                  <div className="input-group">
                    <TextField
                      // label="Email"
                      placeholder="Email Address"
                      type="email"
                      fullWidth
                      value={frmemail}
                      error={!!emailError} // Boolean for error prop
                      helperText={emailError} // Show error message (optional)
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                  <PhoneInput
                    email={number}
                    setEmail={setNumber}
                    emailError={numberError}
                    setEmailError={setNumberError}
                    isBlurred={isBlurred}
                    setIsBlurred={setIsBlurred}
                  />

                  <FormControl error={!!termsError} className="checkbox-field">
                    <FormControlLabel
                      control={
                        <Checkbox
                          //  onChange={e => setTerms(e.target.checked)}
                          onChange={handleChange}
                          checked={isChecked} // Use `checked` instead of `value`
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          onClick={e => e.stopPropagation()}
                        >
                          I accept the{' '}
                          <a
                            href="/terms-of-services"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms and Conditions
                          </a>{' '}
                          and confirm that I have read the{' '}
                          <a
                            href="/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy Policy
                          </a>
                          .
                        </Typography>
                      }
                    />
                    {termsError && (
                      <FormHelperText>{termsError}</FormHelperText> // Display error text if termsError is present
                    )}
                  </FormControl>

                  <FormControl className="checkbox-field">
                    <FormControlLabel
                      control={
                        <Checkbox
                        //  onChange={e => setTerms(e.target.checked)}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          I want to receive information about Glam9 promotions
                          and services, including business tips.
                        </Typography>
                      }
                    />
                  </FormControl>

                  <center>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ backgroundColor: '#0178FA' }}
                      onClick={() => addAccountInfo()}
                      className={`submit-btn ${loading ? 'btnDisable' : ''} `}
                    >
                      Continue {loading ? <Spinner /> : ''}
                    </Button>
                  </center>
                </div>
              </form>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className="logo-section">
          <Box className="brand-container">
            <img
              loading="lazy"
              src={icons.loginLogo}
              alt=""
              className="brand-image"
            />
            {/* <BrandLogo /> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CreateAccount;
