import React from 'react';
import './Loading.css';
import { images } from '../../../Assets/images';

const Loading: React.FC = () => {
  return (
    <div className="loading">
      <img src={images.loader} />
    </div>
  );
};

export default Loading;
