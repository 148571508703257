import React from 'react';
import BookingAppointment from '../Components/booking/booking';
// import BookingAppointmentCopy from '../../src/Components/booking/BookingAppointmentCopy';
import { BreadcrumbProvider } from '../contexts/BreadcrumbContext';
const Booking: React.FC = () => {
  // return <BookingAppointment />;
  // return <BookingAppointmentCopy />;
  return (
    <BreadcrumbProvider>
      {/* <BookingAppointmentCopy /> */}
      <BookingAppointment />
    </BreadcrumbProvider>
  );
};

export default Booking;
