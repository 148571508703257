import React, { useEffect } from 'react';
import { Box, Typography, Container, Link } from '@mui/material';
import './CookiesPolicy.css'; // Assuming you'll add any additional custom styles

const CookiesPolicy = () => {
  useEffect(() => {
    document.body.scrollTop = 0; // For most browsers
    document.documentElement.scrollTop = 0; // For Firefox
  }, []);
  return (
    <Container maxWidth="lg" className="cookies-policy-container">
      <Typography
        variant="h4"
        component="h1"
        sx={{ textAlign: 'center', mb: 4, mt: 4 }}
      >
        COOKIES POLICY
      </Typography>

      <Box
        sx={{
          padding: 2,
          marginBottom: 20,
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h6" paragraph sx={{ fontWeight: '400' }}>
          <strong>Introduction to Tracking Technologies and Cookies:</strong>
          <br />
          At Glam9, we are committed to enhancing your online experience and
          ensuring the functionality and optimization of our website. To achieve
          this, we employ various tracking technologies, including cookies.
        </Typography>

        <Typography variant="h6" paragraph>
          <strong>What are Cookies?</strong>
          <br />
          Cookies are small text files stored on your device by a website's
          server. These files are designed to hold a modest amount of data
          specific to you and our website, enabling us to deliver a more
          personalized and efficient service.
        </Typography>

        <Typography variant="h6" paragraph>
          <strong>Use of Cookies on Our Website</strong>
          <br />
          Our website utilizes cookies to:
          <ul>
            <li>
              Remember your preferences and settings, providing a customized
              browsing experience.
            </li>
            <li>
              Analyze user behavior and trends on our website, helping us
              understand what content and features resonate most with our
              audience.
            </li>
            <li>
              Facilitate the functioning of essential website features, ensuring
              smooth navigation and access to various services.
            </li>
            <li>
              Enable seamless navigation and booking processes for services.
            </li>
            <li>
              Deliver personalized advertisements based on your browsing
              behavior on Glam9 and other platforms.
            </li>
          </ul>
        </Typography>

        <Typography variant="h6" paragraph>
          <strong>Types of Cookies We Use</strong>
          <br />
          <ul>
            <li>
              <strong>Session Cookies:</strong> Temporary cookies that remain in
              the cookie file of your browser until you leave the site.
            </li>
            <li>
              <strong>Persistent Cookies:</strong> These remain in the cookie
              file of your browser for a longer period, depending on the
              lifetime of the specific cookie. These cookies help us recognize
              you as a repeat visitor and adapt our content to match your
              preferences.
            </li>
          </ul>
        </Typography>

        <Typography variant="h6" paragraph>
          <strong>Third-Party Cookies</strong>
          <br />
          In addition to our own cookies, we may also use various third-party
          cookies to report usage statistics of the website, deliver
          advertisements on and through the website, and so on. We work with
          trusted third-party service providers such as Google Analytics and
          advertising networks to collect information about your interactions
          with our platform.
          <ul>
            <li>
              <strong>Google Analytics:</strong> Tracks user behavior and
              provides insights to improve our platform. Learn more about their
              policies:{' '}
              <Link href="https://policies.google.com/privacy" target="_blank">
                Google Privacy Policy
              </Link>
              .
            </li>
            <li>
              <strong>Advertising Partners:</strong> Deliver targeted ads and
              monitor their performance.
            </li>
          </ul>
        </Typography>

        <Typography variant="h6" paragraph>
          <strong>Your Choices Regarding Cookies</strong>
          <br />
          You have the option to either accept or decline cookies. Most web
          browsers automatically accept cookies, but you can usually modify your
          browser setting to decline cookies if you prefer. This may prevent you
          from taking full advantage of the website. To decline cookies, adjust
          the settings within your browser. For more detailed information about
          cookie management with specific web browsers, refer to your browser's
          help section or visit these resources:
          <ul>
            <li>
              <Link
                href="https://support.google.com/chrome/answer/95647"
                target="_blank"
              >
                Google Chrome
              </Link>
            </li>
            <li>
              <Link
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                target="_blank"
              >
                Mozilla Firefox
              </Link>
            </li>
            <li>
              <Link
                href="https://support.apple.com/en-us/HT201265"
                target="_blank"
              >
                Safari
              </Link>
            </li>
          </ul>
        </Typography>

        <Typography variant="h6" paragraph>
          By continuing to browse our site without adjusting your browser
          settings to block cookies, you are agreeing to our use of cookies.
        </Typography>

        <Typography variant="h6" paragraph>
          <strong>Automated Cookie Management</strong>
          <br />
          We provide a cookie preference tool on our website to help you easily
          manage your consent. You can adjust your preferences at any time using
          this tool.
        </Typography>

        <Typography variant="h6" paragraph>
          <strong>Please Note:</strong>
          <br />
          Rejecting cookies may limit your access to some areas of our website
          and reduce the functionality of certain features. By continuing to
          browse our site without adjusting your browser settings to block
          cookies, you are agreeing to our use of cookies.
        </Typography>

        <Typography variant="h6" paragraph>
          <strong>Changes to Our Cookies Policy</strong>
          <br />
          We may update this Cookies Policy from time to time in response to
          changing legal, technical, or business developments. When we update
          our Cookies Policy, we will take appropriate measures to inform you,
          consistent with the significance of the changes we make.
        </Typography>

        <Typography variant="h6" paragraph>
          <strong>Contact Us</strong>
          <br />
          If you have any questions about this Cookies Policy or wish to
          exercise your rights under applicable data protection laws, please
          contact us at:
          <br />
          Email:{' '}
          <Link href="mailto:customerservice@glam9.com">
            customerservice@glam9.com
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default CookiesPolicy;
