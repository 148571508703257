import React from 'react';
import {
  Button,
  Typography,
  Box,
  TextField,
  Container,
  colors,
} from '@mui/material';
import PhoneInput from '../phoneInput/phoneInput';
import './loginForm.css';
import { useNavigate, Link } from 'react-router-dom';
import { icons } from '../../../Assets/icons';
import { images } from '../../../Assets/images';
import Spinner from '../../../Common/Loader/Spinner/Spinner';

interface LoginFormProps {
  addEmail: () => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  emailError: string;
  setEmailError: React.Dispatch<React.SetStateAction<string>>;
  goBackPath: string;
  loading: boolean;
  isBlurred: boolean;
  setIsBlurred: Function;
}

const LoginForm: React.FC<LoginFormProps> = ({
  addEmail,
  email,
  setEmail,
  emailError,
  setEmailError,
  goBackPath,
  loading,
  isBlurred,
  setIsBlurred,
}) => {
  return (
    <Container className="login-form" maxWidth="sm">
      <Box className="topBar">
        {/* for vreserv build, when vreserv build comment below link code */}

        {/* for glam9 biild */}
        <Link to={goBackPath}>
          <img
            loading="lazy"
            src={icons.purpleBack}
            alt=""
            className="login-form-logo"
          />
        </Link>

        <div className="logoMobile">
          <img src={images.logo} />
        </div>
      </Box>
      <div className="loginContent">
        <Typography variant="h4" component="h1" className="login-form-title">
          Login
        </Typography>
        <Typography variant="body1" className="login-form-subtitle">
          Enter your phone number to login
        </Typography>
        <center>
          <PhoneInput
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            setEmailError={setEmailError}
            isBlurred={isBlurred}
            setIsBlurred={setIsBlurred}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={addEmail}
            fullWidth
            sx={{ backgroundColor: '#0178FA' }}
            className="login-form-button"
            disabled={loading}
          >
            Continue {loading ? '' : ''}
          </Button>
        </center>
        <Typography variant="body2" className="login-form-footer">
          Have a business account?
          <Link to={`${process.env.REACT_APP_PRICING_URL}`}>
            Sign in as a Business
          </Link>
        </Typography>
      </div>
      <Typography variant="body2" className="login-form-footer dontAccount">
        Don't have an account ? <Link to="/register">Sign Up</Link>
      </Typography>
    </Container>
  );
};

export default LoginForm;
