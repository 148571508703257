import React from 'react';
import { Box, Typography, Grid,Paper } from '@mui/material';
import { images } from '../../../Assets/images';
import { icons } from '../../../Assets/icons';
import { Link} from 'react-router-dom';
import './AppDownloadSection.css';

const AppDownloadSection: React.FC = () => {
  return (
    <Grid container className='appDownload'>
        <Grid item xs={12} lg={6} xl={6}>
          <Box className="box" >
            <Box className="group" >
              <Paper />
              {/* Group Wrapper for the second image */}
              <Typography className="download-the" >
                Download the <span>Glam9 app</span>                
              </Typography>
              <Typography className="manage-your-schedule" >
                Manage your schedule effortlessly, and indulge in self-care that
                fits your lifestyle
              </Typography>
              <Box className="group-wrapper">
                <p>Available on </p>
              </Box>
              <div className='storeImage'>
              <Link target="_blank" to='https://play.google.com/store/apps/details?id=com.glam9customers.app&hl=en'><img className="img" alt="Play Store" src={icons.playstore} /></Link>
              <Link target="_blank" to='https://apps.apple.com/in/app/glam9-for-customers/id6740204328'><img className="group-2" alt="App Store" src={icons.appstore} /></Link>
              </div>
            </Box>
          </Box>  
        </Grid>      
        <Grid item xs={12} lg={6} xl={6}>
              {/* Group Wrapper for the first image */}
          <div className='imageWrap'>
          <div className='ios'>
            <img alt="iOS" src={images.glam_iOS} ></img>
            </div>
            <div className='android'>
              <img alt="Andriod" src={images.glam_android} ></img>
            </div>
            
          </div>
        </Grid>
      </Grid>
  );
};
export default AppDownloadSection;