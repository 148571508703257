import React, { useEffect, useState, useCallback } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Tooltip,
  Paper,
  Rating,
} from '@mui/material';
import { images } from '../../../src/Assets/images';
import { icons } from '../../../src/Assets/icons';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import toast from 'react-hot-toast';
import './business.css';
import useAppContext from '../../../src/hooks/useAppContext';
import { CategoryList, ApiResponse } from '../../../src/api/types/common';
import { DashboardResponse } from '../../../src/api/types/dashboard';
import { getUserLocation } from '../../../src/Common/CommonFunctions/common';
import { Link, useNavigate } from 'react-router-dom';
import CustomerTestimonials from '../CustomerTestimonials/CustomerTestimonials';

const Business = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.scrollTop = 0; // For most browsers
    document.documentElement.scrollTop = 0; // For Firefox
  }, []);
  // Hooks should be declared at the top level
  const [categoryData, setCategoryData] = useState<CategoryList[]>([]);
  const [reviewData, setReviewData] = useState<any>([]);
  const { getCategoryFunc, getDashboardDataFunc } = useAppContext();

  useEffect(() => {
    getDashboardData();
  }, []);

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
        src={icons.left}
        alt="Prev"
      />
    );
  }

  const getDashboardData = useCallback(async () => {
    const { lat, long } = await getUserLocation();

    const result: DashboardResponse = await getDashboardDataFunc(lat, long);
    if (result === null) {
      toast.error('Failed to fetch city data.');
      return;
    }
    if (result?.status_code === 200) {
      setReviewData(result.data.review);
    } else {
      toast.error(result?.message);
    }
  }, [getDashboardDataFunc]);

  const getInitials = (name: string) => {
    if (!name) return '';
    const nameParts = name.split(' ');
    let initials =
      nameParts.length > 1
        ? nameParts[0][0] + nameParts[nameParts.length - 1][0]
        : nameParts[0][0]; // Use only the first initial if there's one word
    if (name == 'Prakash Parekh (Bholabhai)') {
      initials = 'PP';
    }
    return initials.toUpperCase();
  };

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
        src={icons.right}
        alt="Next"
      />
    );
  }

  const settings = {
    dots: false,
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false, // Ensures no side items are shown
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: false, // Ensures no side items are shown
        },
      },
    ],
  };

  useEffect(() => {
    const fetchCategoryList = async () => {
      try {
        const categoryDataResult: ApiResponse<CategoryList> | null =
          await getCategoryFunc(1);
        if (categoryDataResult === null) {
          toast.error('Failed to fetch city data.');
          return;
        }
        if (categoryDataResult?.status_code === 200) {
          setCategoryData(categoryDataResult.data);
        } else {
          toast.error(categoryDataResult?.message);
        }
      } catch (error) {
        toast.error('An error occurred while fetching categories.');
      }
    };

    fetchCategoryList();
  }, [getCategoryFunc]);

  // Go to pricing plan page
  const handleSubscribe = () => {
    // navigate('/business');

    window.open(`${process.env.REACT_APP_PRICING_URL}/pricingPlans`, '_blank');
  };

  return (
    <>
     <Grid
  container
  spacing={2}
  className="businessBanner"
  sx={{
    marginTop: { xl: '50px', xs: '20px' },
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center align items
  }}
>
  <Typography variant="h3">
    Your Go-to Software to <span>grow your business</span>
    <br /> and earn more revenue.
  </Typography>
  <Typography variant="h6">
    Smart, Accessible, and Seamless website to fulfill your professional needs.
  </Typography>

  {/* Button and text below */}
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, mt: 2 }}>
    <Button onClick={() => handleSubscribe()} variant="contained" color="primary">
      SUBSCRIBE TO GLAM9
    </Button>

    {/* Already Subscribed text */}
    <Typography variant="body1" sx={{ mt: 1 }}>
      Already Subscribed?
    </Typography>

    {/* Login text */}
    <Typography
      variant="body2"
      sx={{ color: '#2e144e', fontWeight: 'bold', cursor: 'pointer',textDecoration: 'underline'  }}
      onClick={() =>
        (window.location.href =
          process.env.REACT_APP_PRICING_URL || '/default-url')
      }
    >
      Click here to Login
    </Typography>
  </Box>
</Grid>

      <Grid
        container
        spacing={2}
        className="businessCalender"
        sx={{ marginTop: { xl: '50px', xs: '20px' } }}
      >
        <img className="calender" src={images.businessCalender} />
      </Grid>
      <div className="businessCounterWrap">
        <div className="counterContainer">
          <Grid container spacing={2} className="businessCounter">
            <Typography variant="h2">
              Your ultimate Platform for Your beauty and self-care.
            </Typography>
            <Typography variant="h6">
              Loved by millions of beauty professionals provided with endless
              opportunities, the best in the beauty and wellness industry.
            </Typography>
            <br></br>
          </Grid>
          <Grid container spacing={2} className="businessCounter counterWrap">
            <Grid item xl={3} xs={12} sm={6} md={3}>
              <Box>
                <Typography variant="h5">
                  <CountUp start={0} end={1200} duration={2} />+
                </Typography>
                <Typography variant="body1">Partnered businesses</Typography>
              </Box>
            </Grid>
            <Grid item xl={3} xs={12} sm={6} md={3}>
              <Box>
                <Typography variant="h5">
                  <CountUp start={0} end={5000} duration={2} />+
                </Typography>
                <Typography variant="body1">
                  Stylists and professionals
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={3} xs={12} sm={6} md={3}>
              <Box>
                <Typography variant="h5">
                  <CountUp start={0} end={1000} duration={2} />+
                </Typography>
                <Typography variant="body1">Appointment booked</Typography>
              </Box>
            </Grid>
            <Grid item xl={3} xs={12} sm={6} md={3}>
              <Box>
                <Typography variant="h5">
                  <CountUp start={0} end={100} duration={2} />+ Cities
                </Typography>
                <Typography variant="body1">glam9 available</Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid
        container
        spacing={2}
        className="businessCalender"
        sx={{ marginTop: { xl: '50px', xs: '20px' } }}
      >
        <Typography variant="h4">
          A complete platform to elevate and grow your business
        </Typography>
        <Typography variant="h3">
          Curated with all the necessary solutions to generate growth, manage
          appointments, and build a strong relationship with clients.
        </Typography>
      </Grid>
      <Grid
        container
        spacing={3.8}
        className="sixBox"
        sx={{ marginTop: { xl: '50px', xs: '20px' } }}
      >
        <Grid item xl={4} xs={12} sm={6} md={4}>
          <Box className="sixBoxContent">
            <img src={icons.scheduleAddpointment} />
            <Typography variant="h2">Scheduling of Appointments</Typography>
            <Typography variant="body1">
              We offer an easy-to-use website and application designed for salon
              and spa professionals who work with all types of devices like
              phones and laptops. It helps vendors to manage and schedule
              appointments without any hassle. Our motto is that one must not be
              interrupted in the appointment scheduling process when it comes to
              Glam9.
            </Typography>
          </Box>
        </Grid>

        <Grid item xl={4} xs={12} sm={6} md={4}>
          <Box className="sixBoxContent">
            <img src={icons.processPayment} />
            <Typography variant="h2">Process of Payment</Typography>
            <Typography variant="body1">
              We provide a secure and seamless payment process designed with
              convenience. Businesses can easily process the payment of clients
              via different payment methods such as UPI, Credit/Debit cards as
              well as cash.
            </Typography>
          </Box>
        </Grid>

        <Grid item xl={4} xs={12} sm={6} md={4}>
          <Box className="sixBoxContent">
            <img src={icons.checkoutSystsem} />
            <Typography variant="h2">Checkout System</Typography>
            <Typography variant="body1">
              Our system helps beauty professionals easily check out the
              client's payments without any wait and can easily handle salon and
              spa sales with the help of tools like QR codes for fast checkout,
              a Receipt printer to print the sale receipt of the customers, and
              many more other features.
            </Typography>
          </Box>
        </Grid>

        <Grid item xl={4} xs={12} sm={6} md={4}>
          <Box className="sixBoxContent">
            <img src={icons.salesPromotion} />
            <Typography variant="h2">Sales Promotion</Typography>
            <Typography variant="body1">
              To beauty professionals and vendors, our website offers to promote
              features for their salons and spas which will likely help attract
              more customers and grow the businesses with new clients.
            </Typography>
          </Box>
        </Grid>

        <Grid item xl={4} xs={12} sm={6} md={4}>
          <Box className="sixBoxContent">
            <img src={icons.stockManagement} />
            <Typography variant="h2">Stock Management</Typography>
            <Typography variant="body1">
              Beauty professionals can easily organize, track, and manage their
              stock and supplies used for the services with the help of our
              Glam9 software.
            </Typography>
          </Box>
        </Grid>

        <Grid item xl={4} xs={12} sm={6} md={4}>
          <Box className="sixBoxContent">
            <img src={icons.financialReporting} />
            <Typography variant="h2">Finanicial Planning</Typography>
            <Typography variant="body1">
              Our Glam9 tools provide valuable insights into beauty
              professionals' financial, growth, client trends, and the overall
              growth of their businesses.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        className="businessOnlineBooking"
        sx={{ marginTop: { xl: '120px', xs: '20px' } }}
      >
        <Grid item xl={5} xs={12} sm={12} md={5} className="twoBoxContent">
          <img src={images.onlineBooking} className="phoneImg01" />
          {/* <img src={images.businessPhone1} className="phone1" />
          <img src={images.businessPhone2} className="phone2" /> */}
        </Grid>
        <Grid item xl={7} xs={12} sm={12} md={7}>
          <Typography variant="h3">Online Booking</Typography>
          <Typography variant="h2">
            Attracts new clients and Earn more revenue
          </Typography>
          <Typography variant="body1">
            Join Glam9 by creating your vendor profile and become a favorite
            beauty partner to thousands of clients. Our tech marketplace
            provides you the opportunity to be seen and attract new clients.
            Grow revenue by building a strong connection online by adding our
            links in the bio on Instagram, WhatsApp, and Facebook. Through this,
            the clients can easily book the services and professionals can
            easily get a good amount of exposure.
          </Typography>
          <Box className="businessCheck">
            <img src={icons.businessCheck} /> <span>Gain more Exposure</span>
          </Box>
          <Box className="businessCheck">
            <img src={icons.businessCheck} />{' '}
            <span>Build a strong brand online</span>
          </Box>
          <Box className="businessCheck">
            <img src={icons.businessCheck} />{' '}
            <span>Receive honest ratings and reviews</span>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        className="businessOnlineBooking"
        sx={{ marginTop: { xl: '200px', xs: '20px' } }}
      >
        <Grid item xl={7} xs={12} sm={12} md={7}>
          <Typography variant="h3">Automated Advertising</Typography>
          <Typography variant="h2">
            Earn the chance to be engaged with Automated advertising and
            campaigns.
          </Typography>
          <Typography variant="body1">
            Beauty professionals and businesses can earn the chance to be part
            of unlimited advertising and campaigns that help to attract new
            clients and turn new clients to be their most loyal clients. Through
            advertising, we also offer various discounts on special occasions
            like birthdays. As well our campaigns help bring back old clients
            re-engaging them through advertising and promotions.
          </Typography>
          <Box className="businessCheck">
            <img src={icons.businessCheck} />{' '}
            <span>Customized messages for clients</span>
          </Box>
          <Box className="businessCheck">
            <img src={icons.businessCheck} />{' '}
            <span>Top-notch marketing to attract clients</span>
          </Box>
          <Box className="businessCheck">
            <img src={icons.businessCheck} />{' '}
            <span>Personalized message formats for individuals</span>
          </Box>
        </Grid>
        <Grid
          item
          xl={5}
          xs={12}
          sm={12}
          md={5}
          className="twoBoxContent imgRight"
        >
          <img src={images.automatedAdvertising} className="phoneImg02" />
          {/* <img src={images.businessPhone4} className="phone4" />
          <img src={images.businessPhone3} className="phone3" /> */}
        </Grid>
      </Grid>

      <CustomerTestimonials />

      <Grid
        container
        spacing={2}
        className="businessCategory"
        sx={{ marginTop: { xl: '50px', xs: '20px' } }}
      >
        <Typography variant="h4">
          Choose the profession you want to get started with
        </Typography>
        {/* Example usage of categoryData */}
        <Grid container spacing={2} className="categoryWrap">
          {categoryData.map((row: CategoryList) => (
            <Grid item xl={2} xs={6} sm={4} md={2} className="catagoryBox">
              <div
                className="catImg"
                style={{
                  height: '120px',
                  display: 'flex',
                  background: 'transparent',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={row?.logo_link}
                  alt={row?.id.toString()}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div
                className="catName"
                style={{
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 8px',
                }}
              >
                <Tooltip title={row?.name} arrow>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: {
                        xl: '16px',
                        lg: '14px',
                        md: '14px',
                        xs: '12px',
                      },
                      fontWeight: 600,
                      textAlign: 'center',
                      wordBreak: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      cursor: 'pointer',
                    }}
                  >
                    {row?.name}
                  </Typography>
                </Tooltip>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        className="businessDownloadWrap"
        sx={{ marginTop: { xl: '50px', xs: '20px' } }}
      >
        <Typography variant="h4">Download the App</Typography>
        <Typography variant="body1">
          Schedule an appointment with your first client with India’s Super
          Magic Platform Glam9.
        </Typography>
      </Grid>
      <div className="downloadWrap">
        <div className="downloadBox purple">
          <div className="innerWrap">
            <div className="title">Glam9</div>
            <div className="subTitle">Booking app for customers</div>
            <div className="appBtnWrap">
              <div className="googlePlay">
                <Link
                  to="https://play.google.com/store/apps/details?id=com.glam9business.app&hl=en"
                  target="_blank"
                >
                  <img src={icons.appGoogleCustomer} />
                </Link>
              </div>
              <div className="appleStore">
                <Link
                  to="https://apps.apple.com/in/app/glam9-for-business/id6740204115"
                  target="_blank"
                >
                  <img src={icons.appAppleCustomer} />
                </Link>
              </div>
            </div>
          </div>
          <div className="appImg">
            <img src={images.downloadImagePurple} />
          </div>
        </div>
        <div className="downloadBox grey">
          <div className="innerWrap">
            <div className="title">Glam9</div>
            <div className="subTitle">Booking app for professionals</div>
            <div className="appBtnWrap">
              <div className="googlePlay">
                <Link
                  to="https://play.google.com/store/apps/details?id=com.glam9business.app&hl=en"
                  target="_blank"
                >
                  <img src={icons.appGoogleBusiness} />
                </Link>
              </div>
              <div className="appleStore">
                <Link
                  to="https://apps.apple.com/in/app/glam9-for-business/id6740204115"
                  target="_blank"
                >
                  <img src={icons.appAppleBusiness} />
                </Link>
              </div>
            </div>
          </div>
          <div className="appImg">
            <img src={images.downloadImageGray} />
          </div>
        </div>
      </div>
      {/*<Grid
        container
        className="businessDownload"
        spacing={2}
        sx={{ marginTop: { xl: '50px', xs: '20px' } }}
      >        
         <Grid className="first" item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Grid
            className="businessdownloadPurpleFirst"
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <Typography variant="h2">Glam9</Typography>
            <Typography variant="h3">Booking apps for Customers</Typography>
            <img src={images.downloadGrayButton} />
          </Grid>
          <Grid
            className="businessdownloadPurpleSecond"
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <img src={images.downloadImagePurple} />
          </Grid>
        </Grid>
        <Grid className="second" item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Grid
            className="businessdownloadGrayFirst"
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <Typography variant="h2">Glam9</Typography>
            <Typography variant="h3">Booking apps for Customers</Typography>
            <img src={images.downloadGrayButton} />
          </Grid>
          <Grid
            className="businessdownloadGraySecond"
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <img src={images.downloadImageGray} />
          </Grid>
        </Grid> 
      </Grid>*/}
    </>
  );
};

export default Business;
