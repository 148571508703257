import React from 'react';
import ServiceCard from './ServiceCard/ServiceCard';
import TeamMember from '../team/Teammember/TeamMember';
import './professional.css';
import { Box, Typography, Grid } from '@mui/material';
// Interface
import { Service } from '../../../src/api/types/vendor_services';
import { professtionalObject } from '../../../src/api/types/proffestional';
import { images } from '../../Assets/images';
interface professionalProps {
  data: any[];
  getProfesstionalFunc: Function;
  vendorServiceMembers: professtionalObject[];
  selectProfesstionalFunc: Function;
}
const Professional: React.FC<professionalProps> = ({
  data,
  getProfesstionalFunc,
  vendorServiceMembers,
  selectProfesstionalFunc,
}) => {
  // Group services by category
  // const groupedData = data.reduce((acc: Record<string, any[]>, service) => {
  //   const categoryName =
  //     service?.services?.category?.name || service.category.name;
  //   if (!acc[categoryName]) {
  //     acc[categoryName] = [];
  //   }
  //   acc[categoryName].push(service);
  //   return acc;
  // }, {});

  // Group services by category, handling nested services
  // (acc: Record<string, Service[]>, service) => {   -->>> old defination
  const groupServicesByCategory = (
    data: Service[],
  ): Record<string, Service[]> => {
    const groupedData = data.reduce(
      //new defination
      (acc, service) => {
        // If the service object has a `services` array, process it recursively
        if (service.services && service.services.length > 0) {
          const nestedGroupedData = groupServicesByCategory(service.services);

          // Merge nested grouped data into the main grouped data
          for (const [nestedCategoryName, nestedServices] of Object.entries(
            nestedGroupedData,
          )) {
            if (!nestedCategoryName) continue; // Skip empty or invalid category names
            if (!acc[nestedCategoryName]) {
              acc[nestedCategoryName] = [];
            }
            // acc[nestedCategoryName].push(...nestedServices);
            acc[nestedCategoryName].push(service);
          }
          // Skip adding the current object itself since its services are processed
          return acc;
        }

        // Process the current service normally if no `services` array exists
        const categoryName = service.category?.name || 'Uncategorized';
        if (!categoryName) return acc; // Skip services with invalid categories

        if (!acc[categoryName]) {
          acc[categoryName] = [];
        }
        acc[categoryName].push(service);
        return acc;
      },
      {} as Record<string, Service[]>,
    );

    return groupedData;
  };

  // Example usage
  const groupedData = groupServicesByCategory(data);

  return (
    <Grid item xs={12} md={9} className="team-selection">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        {/* <Typography variant="h2" className="title">
          Select Professional
        </Typography> */}
        {/* <Typography
          variant="h5"
          component="h3"
          sx={{ mt: 2, fontWeight: 'bold', color: 'black' }}
        >
          Mark
        </Typography> */}
        {!vendorServiceMembers || vendorServiceMembers.length === 0 ? (
          <Grid
            container
            className="professionalContainer"
            sx={{ flexDirection: { xs: 'column', md: 'row' } }}
          >
            <Grid item xs={12} sm={12} md={12} lg={8}>
              {data && data.length > 0 ? (
                <>
                  {Object.entries(groupedData).map(
                    ([categoryName, services]) => {
                      let findlastRecordForPackage =
                        services[services.length - 1];

                      if (
                        findlastRecordForPackage.hasOwnProperty('package_name')
                      ) {
                        return (
                          <div key={categoryName}>
                            {/* Render category header */}
                            <Typography
                              variant="h5"
                              component="h3"
                              sx={{ mt: 2, fontWeight: 'bold', color: 'black' }}
                            >
                              {categoryName}
                            </Typography>

                            {/* Render services for this category */}

                            <ServiceCard
                              key={`service_card_key_${findlastRecordForPackage.id}_${findlastRecordForPackage.id}`}
                              serviceName={
                                findlastRecordForPackage.package_name || ''
                              }
                              processTime={
                                findlastRecordForPackage.total_service_time ||
                                ''
                              }
                              professionalName={'Select Professional'}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div key={categoryName}>
                            {/* Render category header */}
                            <Typography
                              variant="h5"
                              component="h3"
                              sx={{ mt: 2, fontWeight: 'bold', color: 'black' }}
                            >
                              {categoryName}
                            </Typography>

                            {/* Render services for this category */}
                            {services.map((service, index) => {
                              const serviceName =
                                service.service_name ||
                                service.package_name ||
                                'Default Service Name'; // Fallback if missing
                              const processTime =
                                service.service_time ||
                                service.total_service_time ||
                                '0'; // Fallback if missing

                              return (
                                <ServiceCard
                                  key={`service_card_key_${service.id}_${index}`}
                                  serviceName={serviceName}
                                  processTime={
                                    processTime
                                    // service?.total_service_time
                                  }
                                  professionalName={
                                    service?.selectedProfessional ??
                                    'Select Professional'
                                  }
                                  getProfesstionalFunc={getProfesstionalFunc}
                                  serviceObject={service}
                                />
                              );
                            })}
                          </div>
                        );
                      }
                    },
                  )}
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
            <Grid item xs={12} md={12}>
              {vendorServiceMembers && vendorServiceMembers.length > 0 ? (
                <Grid
                  container
                  sx={{
                    flexDirection: { xs: 'column', md: 'row' },
                    marginTop: '10px',
                  }}
                >
                  {vendorServiceMembers.map(
                    (row: professtionalObject, index: number) => (
                      <Grid
                        item
                        xs={12}
                        md={3}
                        style={{ marginBottom: '20px', marginRight: '10px' }}
                      >
                        <TeamMember
                          selectProfesstionalFunc={selectProfesstionalFunc}
                          key={`service_team_member_key_${index}`}
                          name={`${row.first_name} ${row.last_name}`}
                          image={row.profile_image || images.glam9512}
                          description={''}
                          isSpecial={false}
                          professtionalObject={row}
                        />
                      </Grid>
                    ),
                  )}
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Grid>
  );
};
export default Professional;
