import React from "react";
import { LoadScriptNext, Libraries  } from "@react-google-maps/api";

const libraries: Libraries = ["places"];

const GoogleMapsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!} libraries={libraries} loadingElement={<></>}>
      <>{children}</>
    </LoadScriptNext>
  );
};

export default GoogleMapsProvider;
