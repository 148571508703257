import React, { useEffect, useState } from 'react';
import Header from '../common/Header/Header';
import Footer from '../common/Footer/Footer';
import { Container, Grid, Typography, Box } from '@mui/material';
import { images } from '../../../src/Assets/images';
import './Refund.css';
const Terms = () => {
  useEffect(() => {
    document.body.scrollTop = 0; // For most browsers
    document.documentElement.scrollTop = 0; // For Firefox
  }, []);
  return (
    <>
      <Grid container spacing={2} className="cmdBanner">
        <Typography variant="h2">
          Privacy <span>Policy</span>
        </Typography>
      </Grid>
      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h2">&nbsp;</Typography>
        <Typography sx={{ paddingBottom: '10px' }} variant="body1">
          <b>
            DISCLAIMER: The use of this website is subject to the Privacy Policy
            stated below. By accessing this website, you agree to abide by these
            terms of the Privacy Policy.
          </b>
        </Typography>
        <Typography variant="body1">
          This Portal:{' '}
          <a style={{ color: '#4e297b' }} href="https://glam9.com/">
            https://glam9.com/
          </a>{' '}
          (includes its pages, extensions etc., but not limited to) and other as
          may be launched from time to time (hereinafter referred to as
          "Website”, “Platform" and/or “Application”, which shall mean and be
          used interchangeably having the same meaning) is developed, operated
          and maintained by Glam9 Solution Pvt. Ltd. ("The Company"), having
          office at: 205, Iscon Elegance SG Hwy Service Rd, Prahlad Nagar,
          Ahmedabad, Gujarat 380015. In the Privacy Policy, "we", "our" and "us"
          means "the company"; “user”, "you" and "your" means any person who
          accesses and uses this Platform; This Privacy Policy covers this
          Platform’s treatment of personally identifiable information that the
          company collects when you are on this platform and afterwards, and
          when you use this platform’s services. This policy also covers the
          company's treatment of any personally identifiable information (“PII”)
          that users share and/or the PII which the company may fetch/collect
          from various service providers with whom the company has integrated
          its systems to provide the necessary services to you. This policy does
          not apply to the practices of companies that the company does not own
          or control or to people that the company does not employ or manage.
        </Typography>
      </Grid>
      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">1. IN BRIEF</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          Protecting your privacy is at the highest importance for the Company,
          and we are committed to safeguarding it. This policy outlines how
          personal data we collect from you, or that you provide to us or that
          we may fetch/collect from various service providers with whom the
          company has integrated its systems, will be processed. Please read the
          following policy to understand the information we collect, how we will
          use that information and the circumstances where we will share it with
          third parties. This policy should be read together with our terms &
          condition. If you use any of our Services, you will be regarded as
          having read and accepted this Policy. You must not use this platform
          or our Services if you do not accept this Policy. By mere use of this
          platform, you expressly consent to our use and disclosure of your
          personal information in accordance with this Policy. Our privacy
          policy is subject to change at any time without notice. To make sure
          you are aware of any changes, please review this policy periodically.
          Your consent to use the cookies in accordance with the terms of this
          policy when you first visit our application permits us to use cookies
          every time you visit our application. Further, your consent shall
          enable our browser extension feature to enable us to store your last
          visits, conduct data analytics etc.
        </Typography>
      </Grid>
      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">
          2. COLLECTION OF AND PROCESSING OF YOUR PERSONAL DATA
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          The use of this platform may result in the collection and processing
          of your personal data. The term "personal data" under data protection
          law refers to all information relating to a specific or personally
          identifiable person (PII). An IP address can also be considered
          personal data. An IP address is assigned to each device connected to
          the internet by the internet service provider so that it can send and
          receive data. When you use the platform, we collect data that you
          provide yourself. In addition, when you use the platform, we also
          automatically collect certain information about your use of it. If
          Glam9 collects information about customer preferences (e.g., skin
          type, hair type, product usage, or allergies): We may collect and use
          your beauty and wellness preferences (e.g., skin type, hair type, or
          specific concerns) to recommend tailored products and services. This
          data helps us personalize your shopping experience. When you subscribe
          to our services, we collect your email address, phone numbers,
          addresses and preferences to send updates, promotions, and
          personalized content. You may opt out of these communications at any
          time by clicking the “Unsubscribe” link in our emails or contacting us
          directly. We process personal data in compliance with the applicable
          data protection regulations. We will only process data where we are
          legally permitted to do so. When you use this platform, we will
          process personal data only with your consent, for the performance of a
          contract to which you are a party, or in order to take steps at your
          request prior to entering into a contract, for compliance with a legal
          obligation, or if the processing is necessary for the purposes of our
          legitimate interests or the legitimate interests of a third party,
          except where such interests are overridden by your interests or
          fundamental rights and freedom which require the protection of
          personal data.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">3. COLLECTING PERSONAL INFORMATION</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          We may collect the following personal information, including but not
          limited to the following:
        </Typography>

        <ul className="noPadding noBullet">
          <li>
            1. information about your computer/mobile including your IP address,
            geographical location for connecting with the nearby rider/delivery
            partners, checking serviceable areas, accurate delivery/performance
            and tracking of the services, browser type and version, and
            operating system;{' '}
          </li>
          <li>
            2. information about your visits to and use of this Application
            including the referral source, length of visit, page views, and
            navigation paths;
          </li>
          <li>
            3. information, such as your email address, that you enter when you
            register with our application, to set up subscriptions to our emails
            and/or newsletters (if any), etc.
          </li>
          <li>
            4. information such as your government identity proofs for the
            purpose of verification, parent consent in case of age verification
            (if required), if required and submitted to site for legal or
            application purpose from time to time.
          </li>
          <li>
            5. information that you enter when you create a profile on our
            application for example, your name, profile picture (if any),
            gender, age, birthday, etc., which may be requested on portal from
            time to time.
          </li>
          <li>
            6. information that you enter while using the services on our
            application/website.
          </li>
          <li>
            7. information that is generated while using our application,
            including when, how often, and under what circumstances you use it;
          </li>
          <li>
            8. information relating to the services you use, or transactions you
            make through our application/website, which includes your name,
            address including delivery and pickup addresses, telephone number,
            email address, and payment/credit card/UPI/other details.
          </li>
          <li>
            9. information contained in any communications that you send to us
            by email or through our application/website, including its
            communication content, metadata and hashed data.
          </li>
          <li>
            10. Information related to your communication with the
            rider/delivery partner.
          </li>
          <li>
            11. Information that you may have provided/published on the platform
            such as ratings, photos, comments, likes, etc. These contributions
            from your end shall be posted on the platform at your voluntary
            consent and your own risk. The Company cannot control the actions of
            other users of the Platform with whom you share such information.
            Hence, the Company cannot guarantee that such information will not
            be viewed by unauthorized persons.
          </li>
          <li>
            12. Our application also provides an option of referral and hence
            shall enable collection of referral email id, phone number etc.
            However, users entering such PII on behalf of the referral shall
            completely hold consent of the referral for such act and any
            liability that may arise on us for using such referral’s PII for
            marketing or otherwise, shall have to be completely indemnify us by
            you.{' '}
          </li>
          <li>
            13. Any calls that may be made by you to the rider/delivery partner
            or the customer care service provider or vice versa pertaining to
            any orders or services may be recorded for internal training and
            quality purposes by the Company or any third party appointed by the
            Company. In case you don’t consent for the recording, kindly refrain
            from using the calling facility on the platform. Certain areas and
            features of the application/website are available to you without the
            need to provide us with any information. However, other features of
            the Site or the Service will require providing the
            application/website with details such as your name, email address,
            address, mobile number etc., personally identifying information, a
            username and a password (collectively the "Registration
            Information"). There are no user IDs & passwords of any government
            or other institution asked for on the platform other than for the
            purpose of verification which may be required. Any of your
            information that has been availed from any third party, basis on
            this consent, shall be used only in accordance with specific
            internal procedures as well as the statutory stipulations and
            safeguards governing access, in order to operate, develop or improve
            the service. Before you disclose to us the personal information of
            another person, you must obtain that person's consent to both the
            disclosure and the processing of that personal information in
            accordance with this policy. In case of any discrepancies and/or
            issues arising due to such “on behalf” submission, the submitter
            shall be liable and obligated under the then existing PII and other
            applicable rules and regulations.
          </li>
        </ul>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">
          4. PROCESSING PERSONAL INFORMATION:
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          The company and its partners collect and use your personal information
          and other information only as follows ("The purpose"):{' '}
        </Typography>

        <ul className="noPadding noBullet">
          <li>
            i. To send you any administrative notices, offer alerts and other
            Transactional Communications relevant to your use of the Service.{' '}
          </li>
          <li>
            ii. To enable you to apply for certain services for which you have
            chosen to apply.{' '}
          </li>
          <li>
            iii. For market research, project planning, troubleshooting issues,
            detecting and protecting against error, fraud or other criminal
            activity.{' '}
          </li>
          <li>
            iv. To third-party contractors that provide services to the company
            and are bound by these same privacy restrictions & confidentiality
            obligations.
          </li>
          <li>
            v. To enforce Company’s terms & conditions. vi. To use data in
            connection with legal claims, compliance, regulatory and
            investigative purposes as necessary (including disclosure of such
            information in connection with legal process or litigation).;{' '}
          </li>
          <li>
            vii. To analyze usage of the application/site and to improve the
            Service/s and security.{' '}
          </li>
          <li>viii. To otherwise as set forth in this Privacy Policy.</li>
          <li>
            ix. To perform various analytics for the betterment of the user
            experience/services
          </li>
          <li>
            x. To carry out obligations arising from any contracts entered into
            between the User and company and to provide User facilities and
            services as per Users interest.
          </li>
          <li>
            xi. To provide User with information about other services, events
            and services that companies offer that are similar to this User have
            already purchased or inquired about or other communications
            containing information about new services or upcoming events of the
            company, company’s affiliates and non-affiliated third parties such
            as societies and sponsors, if User has consented to receive this
            information.
          </li>
          <li>
            xii. To notify the User about changes or updates to the
            application’s User account. xiii. To respond to the Users to
            requests, inquiries, comments or concerns. xiv. To allow the User to
            participate in interactive features of our service when User chooses
            to do so.
          </li>
          <li>
            xiii. To respond to the Users to requests, inquiries, comments or
            concerns. xiv. To allow the User to participate in interactive
            features of our service when User chooses to do so.
          </li>
        </ul>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">5. CONSENT TO OVERRIDE DND:</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          By using the platform and its services, you specifically provide
          consent to the company to send you transaction, service explicit &
          service implicit communications which are essential to continue the
          services like sending OTP for log-in, Status of Application etc.,
          irrespective of your Do Not Disturb Registration with TRAI, DoT and/or
          any other Appropriate Authority. Similarly, you provide your explicit
          consent for sending across various communications on your registered
          email as well as phone number through various communication
          applications like WhatsApp as may be integrated with portal in future.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">6. SHARING OF PERSONAL DATA:</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          As a policy we do not disclose any personal information to anyone
          other than those to our rider/delivery partners (only when required to
          provide the services to the particular user), employees, officers,
          professional advisors', third party contractors, third party service
          providers as reasonably necessary for the purposes set out in this
          policy. We may disclose your personal information to any member of our
          group of companies (this means our subsidiaries, our ultimate holding
          company /ies and all its subsidiaries) as reasonably necessary for the
          purposes set out in this policy.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">
          7. SECURITY OF YOUR PERSONAL INFORMATION:
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          We will take reasonable technical and organizational precautions to
          prevent the loss, misuse, or alteration of your personal information.
          We follow generally accepted standards to protect the personal
          information submitted to us, both during transmission and once we
          receive it. We use a combination of firewalls, encryption techniques
          and authentication procedures, among others, to maintain the security
          of your online session and to protect the accounts and systems from
          unauthorized access. For instance, the company may share your phone
          number with riders/delivery partner in an encrypted format to protect
          your personal information and ensure security. All electronic
          financial transactions entered into through our website will be
          protected by encryption technology. When you register for the Service,
          this platform requires a password from you for your privacy and
          security. The platform transmits information such as your Registration
          Information for the company/ this platform or Account Credentials
          securely. Our databases are protected from general employee access
          both physically and logically. We encrypt your Service password so
          that your password cannot be recovered, even by us. You acknowledge
          that no method of transmission over the Internet, or method of
          electronic storage, is 100% secure, however, therefore, we cannot
          guarantee its absolute security. If you have any questions about
          security on our Web site, you can contact us on the email provided in
          contact us segment on our website.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">8. RIGHTS OF USERS</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          We value your privacy and are committed to protecting your personal
          data in compliance with Indian laws, including the Information
          Technology Act, 2000, and associated rules. Below are your rights
          concerning your personal information:
        </Typography>
        <ul className="noMarker">
          <li>
            1. Right to Be Informed You have the right to be informed about:
            <ul>
              <li>
                The purpose for which your personal information is collected.
              </li>
              <li>The type of personal data collected.</li>
              <li>The intended recipients of your data, if any.</li>
            </ul>
          </li>
          <li>
            2. Right to Access You may request access to the personal
            information that we hold about you. This includes:
            <ul>
              <li>Confirmation of whether we process your personal data.</li>
              <li>Details of the data being processed.</li>
            </ul>
          </li>

          <li>
            3. Right to Correction You can request correction, updating, or
            modification of any inaccurate or incomplete personal information.{' '}
          </li>
          <li>
            Right to Withdraw Consent Where processing of your data is based on
            consent, you have the right to withdraw it at any time. Upon
            withdrawal of consent:
            <ul className="noMarker">
              <li>
                We may cease processing your personal data unless required
                otherwise under law.
              </li>
              <li>
                Certain services dependent on your consent may no longer be
                available.{' '}
              </li>
            </ul>
          </li>
          <li>
            4. Right to Restriction of Processing You have the right to restrict
            the processing of your personal information in the following
            circumstances:
            <ul className="noMarker">
              <li>
                If the information being processed is inaccurate or disputed.
              </li>
              <li>
                If the processing is unlawful, but you do not want the data to
                be deleted.
              </li>
            </ul>
          </li>

          <li>
            5. Right to Erasure You may request the deletion of your personal
            information where:
            <ul className="noMarker">
              <li>
                The purpose for which it was collected has been fulfilled.
              </li>
              <li>The data is no longer required, or you withdraw consent.</li>
              <li>The information has been unlawfully processed.</li>
            </ul>
          </li>

          <li>
            6. Right to File a Complaint If you believe your data protection
            rights have been violated, you have the right to lodge a complaint
            with:
            <ul className="noMarker">
              <li>Our grievance officer, whose details are provided below.</li>
              <li>
                Relevant authorities, such as the Ministry of Electronics and
                Information Technology (MeitY)
              </li>
            </ul>
          </li>

          <li>
            7. Right to Security of Information You have the right to expect
            that your personal information will be securely stored and processed
            in accordance with reasonable security practices and procedures
            under Indian law.
          </li>
        </ul>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">9. DATA TRANSFERS</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          Presently the platform is operational across various states and
          territories across India. Information that we collect may be stored,
          processed in, and transferred between any of the states in which we
          may operate in the future in order to enable us to use the information
          in accordance with this policy. You expressly agree to the transfers
          of personal information described in this Section. We hereby declare
          that the data localization is not practiced as the platform is
          independent of territories.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">8. COPYRIGHT AND DATABASE RIGHTS</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          All content included on the Website, such as photographs, text,
          graphics, logos, button icons, images and software, is the exclusive
          property of the Company, its affiliates or its content suppliers.
        </Typography>
        <Typography variant="body1">
          The compilation of all content on the Website is the exclusive
          property of the Company.
        </Typography>
        <Typography variant="body1">
          All software used on this Website is the property of the Company or
          its software suppliers. You shall not systematically extract and/or
          re-utilize parts of the contents of the Website without the Company’s
          express written consent. In particular, you shall not utilize any data
          mining, robots, or similar data gathering and extraction tools to
          extract (either once or a number of times) for re-utilization of any
          substantial parts of this Website, without the Company’s prior and
          express written consent.
        </Typography>
        <Typography variant="body1">
          You shall not create and/or publish your own database that features
          substantial parts of this Website [(e.g. prices and product listings)]
          without the Company’s express written consent. You further agree not
          to access or use this Website in any manner that may be harmful to the
          operation of this Website or its content.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">9. TRADENAMES</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          Synerger and other marks indicated on the Website are trademarks to be
          registered or registered trademarks of the Company in India. All other
          trademarks not owned by the Company that appear on this Website are
          the property of their respective owners, who may or may not be
          affiliated with, connected to, or sponsored by the Company.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">
          10. USING YOUR INFORMATION FOR MARKETING PURPOSES:
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          {' '}
          From time to time, we may request more personal information in order
          to provide you with other benefits of the Service. The company may
          aggregate personal information and disclose such data in a manner to:{' '}
        </Typography>
        <ul className="noPadding noBullet">
          <li>
            {' '}
            1. Third parties for their marketing and promotional purposes.
          </li>
          <li>
            2. Users of the Service for the purpose of comparing with relative
            to the broader community.{' '}
          </li>
          <li>
            3. Such information may not identify you individually. We may also
            use third party service providers to help us provide the Service to
            you, such as sending e-mail messages on our behalf or hosting and
            operating a particular feature or functionality of the Service. Our
            contracts with these third parties outline the appropriate use and
            handling of your information and prohibit them from using any of
            your personal information for purposes unrelated to the product or
            service they're providing.
          </li>
        </ul>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">
          11. RETENTION OF PERSONAL INFORMATION:
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          We need your personal data to:  
        </Typography>
        <ul className="withPadding">
          <li>Provide our services to you.</li>
          <li>Manage our business for our legitimate interest </li>
          <li>omply with legal obligations </li>
          <li> Comply with legal obligations if any</li>
        </ul>
        <Typography variant="body1">
          You may choose not to share personal data or withdraw consent but
          doing so may limit the services we are able to provide you. However,
          once you choose to withdraw the consent the personal data which has
          been shared shall continue to remain with us for as long as the
          applicable law provides. We may retain your Personal Data for a period
          of time consistent with the purpose of collecting. We determine the
          appropriate retention period for Personal Data based on the amount,
          nature, and sensitivity of your Personal Data, the potential risk of
          harm from unauthorized use or disclosure, and whether we can achieve
          the purposes of the processing through other means, as well as the
          applicable legal requirements (such as applicable statutes of
          limitation). If there is any information that we are unable, for
          technical reasons, to delete entirely from our systems post
          appropriate retention period, we will put in place appropriate
          measures to prevent any further use of the data.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">
          12. CHANGES TO YOUR PERSONAL INFORMATION
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          If your personal Information provided when you applied for a service
          on platform, changes, you may update it whenever you apply for a new
          service. To review and update your personal information to ensure it
          is accurate while your application is in process. We will retain your
          information for as long as your account is active or as needed to
          provide you services or as may be required for internal analysis
          purpose or as may be required by law, whichever is later. If you wish
          to cancel your account or request that we no longer use your
          information to provide you services, contact us at email provided in
          contact us section of application/website. We will retain and use your
          information as necessary to comply with our legal obligations, resolve
          disputes, and enforce our agreements.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">13. LOG FILES:</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          As is true of most web sites, we gather certain information
          automatically and store it in log files. This information may include
          internet protocol (IP) addresses, browser type, internet service
          provider (ISP), referring/exit pages, operating system, date/time
          stamp, and/or clickstream data. We may combine this automatically
          collected log information with other information we collect about you.
          We do this to improve services we offer you, to improve marketing,
          analytics, or site functionality
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">
          14. TRACKING TECHNOLOGIES / COOKIES:
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          If enabled, we may place cookies on your machine that store small
          amounts of data on your computer/mobile phone about your visit to any
          of the pages of this application/website. Cookies are small electronic
          files that collect information when someone visits a website. They can
          identify the pages that are being viewed and this can assist us in
          tracking which of our features appeal the most to you and what content
          you may have viewed on past visits. Some cookies only exist whilst
          viewers are online, but 'persistent' cookies are not session-based and
          remain on the viewer's computer. When you visit this site again,
          'persistent' cookies can enable us to customize our content according
          to your preferences. Technologies such as: cookies, beacons, tags and
          scripts are used by Company and our partner entities, affiliates,
          analytics or service providers such as google Ad sense, google
          analytics etc. These technologies are used in analyzing trends,
          administering the site, tracking users' movements around the site and
          gathering demographic information about our user base as a whole. We
          may receive reports based on the use of these technologies by these
          companies on an individual as well as aggregated basis. We use cookies
          for and on our platform in order to personalize our service for you.
          Users can control the use of cookies at the individual browser level.
          If you reject cookies, you may still use our site, but your ability to
          use some features or areas of our site may be limited. You may refuse
          to accept cookies by activating the setting on your browser which
          allows you to refuse the setting of cookies. Unless you have adjusted
          your browser setting so that it will refuse cookies, our system will
          issue cookies when you log on to the Website.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">
          15. TESTIMONIALS, BLOGS, AND OTHER FORUMS ON THIS WEBSITE:{' '}
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          With this consent of yours, we may post your testimonial along with
          your name. If you want your testimonial removed, please contact us at
          email mentioned in contact us on application/website. If you use a
          blog or other public forum on our Site, any information you submit
          there can be read, collected, or used by other users and could be used
          to send you unsolicited messages. We are not responsible for the
          personal information you choose to submit in these forums. These
          forums may be hosted by the Company by one of our third-party service
          providers on the Company's behalf. To request removal of your personal
          information from our blog or community forum, contact us on the email
          mentioned in contact us segment of the website. In some cases, we may
          not be able to remove your personal information, in which case we will
          let you know if we are unable to do so and why.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">
          16. BEHAVIORAL TARGETING / RE-TARGETING
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          We may partner with a third party to either display advertising on our
          platform or to manage our advertising on other sites. Our third-party
          partner may use technologies such as cookies to gather information
          about your activities on this site and other sites in order to provide
          you advertising based upon your browsing activities and interests.
          There are a number of products and services that may be offered by
          third parties on our Site, (collectively, "Offers"). If you choose to
          apply for these separate products or services, disclose information to
          the providers, or grant them permission to collect information about
          you, then their use of your information is governed by their privacy
          policies and they will be acting as data controllers of your
          information. You should evaluate the practices of these external
          services providers and should view their privacy policies or contact
          them directly for further information before deciding to use their
          services. The Company is not responsible for their privacy practices.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">17. SINGLE SIGN-ON</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          In future, there may be options wherein you can log in to our site
          using sign-in services such as Facebook, Google or an Open ID
          provider. These services will authenticate your identity and provide
          you the option to share certain personal information with us such as
          your sign-in information, name and email address to link between the
          sites.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">18. LIKE / SHARE BUTTON</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          If you use the "Like" / “Share” button to share something, that item
          will appear on your Social Media profile page and also on your
          friends' newsfeed, depending on your social media’s privacy settings.
          You may also receive updates in your social media account’s newsfeed
          from this site or item in the future. Such social media companies also
          collect information such as which pages you have visited on this and
          other sites that have implemented the "Like" button.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">19. LINKS TO THIRD PARTY SITES</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          Our Site includes links to other Web sites whose privacy practices may
          differ from those of the company. If you submit personal information
          to any of those sites, your information is governed by their privacy
          policies, and they will be acting as data controllers of your
          information. We encourage you to carefully read the privacy policy of
          any Web site you visit.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">20. OPTING OUT:</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          We provide our registered Users with periodic email and SMS alerts. We
          also allow users to unsubscribe to email newsletters and from time to
          time may transmit emails promoting the company or third-party goods or
          services. The company's subscribers may opt-out of receiving our
          promotional emails and terminate their newsletter subscriptions by
          following the instructions in the emails. Opting out in this manner
          will not end transmission of service-related emails/SMS, such as
          email/SMS alerts.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">21. YOUR RESPONSIBILITY</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          You shall not disclose to any other person, in any manner whatsoever,
          any information relating to the Company of a confidential nature
          obtained in the course of availing the services through the website of
          the Company. Failure to comply with this obligation shall be deemed a
          serious breach of the terms herein and shall entitle the Company to
          any damages, to which you may be entitled otherwise. If you have any
          complaints, security related concern, please contact nodal officer at
          the email mentioned on contact us segment on our application/website.
          We will work closely with you to ensure a rapid and personal response
          to your concerns. You are responsible for maintaining the
          confidentiality of your login id and password. You are responsible for
          maintaining the security of your Login ID and Password and may not
          provide these credentials to any third party. If you believe that they
          have been stolen or been made known to others, you must contact us
          immediately at details mentioned in contact us segment of website. We
          are not responsible if someone else accesses your account through
          Registration Information they have obtained from you or through a
          violation by you of this Privacy and Security Policy or the Company’s
          Terms & Conditions.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">22. CHANGES TO THE POLICY</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          We may update this Privacy Policy to reflect changes to our
          information practices. We encourage you to periodically review this
          page for the latest information on our privacy practices. We will send
          you a notification regarding the recent change and update as we deem
          necessary.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">
          23. CONTACT US WITH QUESTIONS OR CONCERNS:
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          If you have questions, comments, concerns or feedback regarding this
          Privacy and Security Policy or any other privacy or security concern,
          send an e-mail to Nodal Officer, as per details mentioned in contact
          us segment of our website. This Policy shall be governed by and
          construed in accordance with the laws of the Republic of India and the
          courts at Ahmedabad, Gujarat, India shall have sole and exclusive
          jurisdiction in relation to any disputes arising out of or in
          connection with this Policy.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent privacyContact">
        <Typography variant="h3">24. IPR OWNERSHIP</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          You shall not copy, modify, transmit, distribute / redistribute,
          reverse engineer etc., but not limited to, or in any way exploit the
          Products or any other copyrighted materials and content provided other
          than for your individual training. Any other purpose is expressly
          prohibited under these terms. You shall also not permit anyone else to
          copy, use, modify, transmit, distribute or in any way exploit the
          Products or any other copyrighted materials, trademark/ registered
          mark, the designs, innovations, business ideas etc., which together
          shall be known as IPR and the ownership of the same belongs to the
          company. Any infringement, theft etc., of the IPR of the company shall
          result in criminal proceedings against you at your risk, cost and
          consequences. You will not (and will not allow any third party to)
          attempt to copy, record or reverse any Videos or Documentation
          supplied by Company or stored on the website (or related) websites,
          outside the application in any manner.
        </Typography>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ marginBottom: '100px' }}
        className="cmdContent privacyContact"
      >
        <Typography variant="h3">25. CHILDREN’S PRIVACY</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          Our Services do not address anyone under the age of 18. We do not
          knowingly collect personal identifiable information from children
          under 18. In the case we discover that a child under 18 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to do necessary actions. Users below 18 years of
          age are advised to use this platform/ Services under guardian ship of
          their parent/s and/or legal guardian. Version 1.0 
        </Typography>
      </Grid>
    </>
  );
};

export default Terms;
