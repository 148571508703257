import React, { useCallback, useEffect, useState } from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import Breadcrumb from './Breadcrumb';
import SalonCard from './salonCard';
import './FavoritesPage.css';
import { EmptyFavorites } from './EmptyFavorites';
import { ShopAPI } from '../../../src/hooks/shop';
import toast from 'react-hot-toast';
import Loading from '../../../src/Common/Loader/Loading/Loading';
const FavoritesPage = () => {
  useEffect(() => {
    document.body.scrollTop = 0; // For most browsers
    document.documentElement.scrollTop = 0; // For Firefox
  }, []);
  const [favList, setFavList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { getFavVendorListAPICall } = ShopAPI();
  const getFavList = useCallback(async () => {
    setLoading(true);
    const result = await getFavVendorListAPICall();
    if (result.success && result.status_code === 200) {
      setFavList(result.data);
    } else {
      // toast.error(result.message);
    }
    setLoading(false);
  }, [getFavVendorListAPICall]);
  useEffect(() => {
    getFavList();
  }, []);

  const handleRemoveFavorite = (vendorId: string) => {
    setFavList((prevList: any) =>
      prevList.filter((salon: any) => salon.vendor_detail_id !== vendorId),
    );
  };

  return (
    <Box className="favorites-page">
      <Container maxWidth="xl">
        <Breadcrumb currentPage="Favourites" />
        <Typography variant="h4" component="h1" className="favorites-title">
          Favorites
        </Typography>
        {loading ? (
          <Loading />
        ) : favList.length > 0 ? (
          <Box className="salon-list">
            <Grid container spacing={3}>
              {favList.map((salon: any, index: number) => (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={index}>
                  <SalonCard
                    data={salon}
                    onRemoveFavorite={handleRemoveFavorite}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <EmptyFavorites />
        )}
      </Container>
    </Box>
  );
};

export default FavoritesPage;
