import React from 'react';
import Shop from '../../src/Components/shop/shop';
// import ShopCopy from '../../src/Components/shop/ShopCopy';
import VendorDetailsDataProvider from '../contexts/VendorDetailsContext';
const ShopDetails: React.FC = () => {
  return (
    <VendorDetailsDataProvider>
      {' '}
      <Shop />      
    </VendorDetailsDataProvider>
  );
};
export default ShopDetails;
