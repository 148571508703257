import {
  getDashboardData,
  getBannerImages,
  getVendorListing,
  getVendorOffers,
  getDashboardDataListing,
} from '../../src/api/dashboardAPI';
import {
  DashboardResponse,
  BannerResponse,
  getVendorListingReqInterface,
  offersDataResponse,
} from '../../src/api/types/dashboard';
export const DashboardAPI = () => {
  const getDashboardDataListAPICall = async (
    filter_type: string,
    page: string,
    limit: string,
    options: {
      in_app?: string;
      lattitude?: string;
      longitude?: string;
      nearest?: string;
      radius?: string;
      rates?: string;
      min_price?: string;
      max_price?: string;
      category_id?: string;
      top_rated?: string;
      gender_type?: string;
    } = {},
  ): Promise<DashboardResponse> => {
    try {
      const response: DashboardResponse = await getDashboardDataListing(
        filter_type,
        page,
        limit,
        options,
      );
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as DashboardResponse;
    } catch (err: any) {
      return {
        data: {},
        message: err.toString(),
        status_code: 500,
        success: false,
      } as DashboardResponse;
    }
  };

  const getDashboardDataAPICall = async (
    lat: number,
    long: number,
  ): Promise<DashboardResponse> => {
    try {
      const response: DashboardResponse = await getDashboardData(lat, long);
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as DashboardResponse;
    } catch (err: any) {
      return {
        data: {},
        message: err.toString(),
        status_code: 500,
        success: false,
      } as DashboardResponse;
    }
  };

  const getBannerImagesAPICall = async (): Promise<BannerResponse> => {
    try {
      const response: any = await getBannerImages();
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as BannerResponse;
    } catch (err: any) {
      return {
        data: [],
        message: err.toString(),
        status_code: 500,
        success: false,
      } as BannerResponse;
    }
  };

  const getVendorListingAPICall = async (
    data: getVendorListingReqInterface,
  ): Promise<DashboardResponse> => {
    try {
      const response: DashboardResponse = await getVendorListing(data);
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as DashboardResponse;
    } catch (err: any) {
      return {
        data: {},
        message: err.toString(),
        status_code: 500,
        success: false,
      } as DashboardResponse;
    }
  };

  const getVendorOffersAPICall = async (): Promise<offersDataResponse> => {
    try {
      const response: offersDataResponse = await getVendorOffers();
      return {
        data: response.data,
        message: response.message,
        status_code: response.status_code,
        success: response.success,
      } as offersDataResponse;
    } catch (err: any) {
      return {
        data: {},
        message: err.toString(),
        status_code: 500,
        success: false,
      } as offersDataResponse;
    }
  };

  return {
    getDashboardDataListAPICall,
    getDashboardDataAPICall,
    getBannerImagesAPICall,
    getVendorListingAPICall,
    getVendorOffersAPICall,
  };
};
