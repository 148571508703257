import { images } from "../../Assets/images/index";

export type PartnerImagesType = string[];

// Array of partner images
const PartnerImages: PartnerImagesType = [
    images.firstPartner, 
    images.secondPartner,
    images.thirdPartner,
    images.fourthPartner,
    images.fifthPartner,
    images.sixthPartner,
];

export { PartnerImages };
