import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';

import en from './en.json';
import fr from './fr.json';

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: en,
      //  fr: fr,
    },
    lng: 'en', // Default language
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

// Function to change language
export const changeLanguage = (lng: string) => {
  i18n.changeLanguage(lng);
};

// If you want to use the `useTranslation` hook
// export const useAppTranslation = () => useTranslation();

// Custom hook to provide `t` function
export const useAppTranslation = () => {
  const { t } = useTranslation();
  return t;
};
