import React, { useEffect, useState } from 'react';
import AppointmentSection from './AppointmentSection/AppointmentSection';
import AppointmentDetails from './AppointmentDetails/AppointmentDetails';
import './AppointmentPage.css';
import { Box, Typography, Grid, Container, Button } from '@mui/material';
import { ShopAPI } from '../../../src/hooks/shop';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { icons } from '../../../src/Assets/icons';
import toast from 'react-hot-toast';
import LatestAppointmentCard from './LatestAppointmentCard/LatestAppointmentCard';
import { images } from '../../Assets/images';
import Loading from '../../Common/Loader/Loading/Loading';
import { useNavigate } from 'react-router-dom';

interface Appointment {
  id: string;
  vendor_detail_id: string;
  status: string;
  payment_type: string;
  payment_status: string;
  is_online_book: string;
  subscription_plan_id: string;
  type: string;
  booking_notes: string;
  business: string;
  appointment_date: string;
  start_time: string;
  end_time: string;
  total_hours: string;
  total_amount: string;
  total_services: string;
  cover_photo: string;
  address: {
    address_id: string;
    vendor_detail_id: string;
    address_line_1: string;
    address_line_2: string;
    country_id: string;
    state_id: string;
    city_id: string;
    postal_code: string;
    lattitude: string;
    longitude: string;
    status: string;
    country_name: string;
    state_name: string;
    city_name: string;
    created_date: string;
  };
  currency: {
    id: number;
    currency_name: string;
    currency_code: string;
    currency_icon: string;
    is_base_currency: number;
  };
}

interface AppointmentData {
  latest?: Appointment;
  upcoming: Appointment[];
  past: Appointment[];
}

const AppointmentPage: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.scrollTop = 0; // For most browsers
    document.documentElement.scrollTop = 0; // For Firefox
  }, []);
  const { getAppointmentListVendorAPICall } = ShopAPI();
  // const [appointmentData, setAppointmentData] = useState<any>(null);
  const [appointmentData, setAppointmentData] =
    useState<AppointmentData | null>(null);
  const [appointmentDetails, setAppointmentDetails] = useState<any>({});
  const [latestAppointment, setLatestAppointment] =
    useState<Appointment | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [latestAppointmentSelected, setLatestAppointmentSelected] =
    useState<boolean>(true);

  const getAppointmentList = async () => {
    setLoading(true);
    if (customerData && customerData?.customer_id) {
      try {
        const result = await getAppointmentListVendorAPICall(
          customerData.customer_id,
        );

        if (
          result?.data &&
          typeof result.data === 'object' &&
          !Array.isArray(result.data) && // Ensure it's not an array
          'latest' in result.data &&
          'upcoming' in result.data &&
          'past' in result.data
        ) {
          const data: AppointmentData = result.data;

          setAppointmentData(data);
          setLatestAppointment(data?.latest || null);
          // setLatestAppointment(null);
          if (data?.latest) {
            setAppointmentDetails(data?.latest || null);
          } else {
            if (data?.upcoming[0]) {
              setAppointmentDetails(data?.upcoming[0] || null);
            } else if (data?.past[0]) {
              setAppointmentDetails(data?.past[0] || null);
            }
          }
        } else {
          toast.error('Error while fetching appointment list');
        }
      } catch (error) {
        toast.error('Failed to fetch appointments.');
      }
      setLoading(false); // Stop loading
    }
  };
  const [customerData, setCustomerData] = useState<any>(null);
  useEffect(() => {
    let customerInfo = localStorage.getItem('customer_details');
    if (customerInfo) {
      customerInfo = JSON.parse(customerInfo);
      setCustomerData(customerInfo);
      getAppointmentList();
    }
  }, []);
  useEffect(() => {
    if (customerData) {
      getAppointmentList();
    }
  }, [customerData]);
  const updateAppointment = (data: any) => {
    setAppointmentDetails(data);
  };
  const [value, setValue] = React.useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue); // Ensure newValue is a number
  };
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const handleAppointmentSelect = (appointment: any) => {
    setSelectedAppointment(appointment);
  };
  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }
  const isBlank = (obj: any) => Object.keys(obj).length === 0;
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  let appointment_id: string | undefined;
  if (isBlank(appointmentDetails) == true) {
    if (appointmentData) {
      if (appointmentData.upcoming.length > 0) {
        appointment_id = String(appointmentData.upcoming[0]?.id);
      } else {
        appointment_id = String(appointmentData?.latest?.id);
      }
    }
  } else {
    appointment_id = String(appointmentDetails.id);
  }

  const saloonSearch = () => {
    /* for glam9 build */
    navigate('/');

    /* for vreserv build */
    // navigate('/home');
  };

  return (
    <Box className="appointment-page">
      <Container component="main">
        <Grid container direction={{ xs: 'column', md: 'row' }}>
          <Grid item xs={12} md={4} className="leftAppoitment">
            <Typography variant="h4" fontWeight="bold" color="textPrimary">
              Appointments
            </Typography>
            <Box>
              <h4 className="latestAppointmentTitle">Latest</h4>

              {loading ? (
                <Loading />
              ) : Object.keys(latestAppointment || {}).length > 0 ? (
                <LatestAppointmentCard
                  image={latestAppointment?.cover_photo || images.glam9512}
                  title={latestAppointment?.business || ''}
                  lattitude={latestAppointment?.address?.lattitude || ''}
                  longitude={latestAppointment?.address?.longitude || ''}
                  date={latestAppointment?.appointment_date || ''}
                  total_hours={latestAppointment?.total_hours || ''}
                  price={latestAppointment?.total_amount || ''}
                  itemCount={
                    parseInt(latestAppointment?.total_services || '') || 0
                  }
                  currency_icon={
                    latestAppointment?.currency?.currency_icon || ''
                  }
                  updateAppointment={updateAppointment}
                  appointmentData={latestAppointment || {}}
                  latestAppointmentSelected={latestAppointmentSelected}
                  setLatestAppointmentSelected={setLatestAppointmentSelected}
                />
              ) : (
                <Box
                  className="latestAppointment"
                  alignItems="center"
                  gap={2.5}
                >
                  <center>
                    <img src={icons.noAppointment} />
                    <Typography variant="h1">No latest appointments</Typography>
                    <Typography variant="body2">
                      Make some appointments for schedule data to appear
                    </Typography>
                    <Button
                      className="searchSalon"
                      onClick={() => saloonSearch()}
                    >
                      Search Saloon
                    </Button>
                  </center>
                </Box>
              )}

              <Tabs
                value={value}
                onChange={handleChange}
                className="tabs"
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  label={
                    <span>
                      Upcoming{' '}
                      <span className="count-chip upcoming">
                        {appointmentData?.upcoming?.length || 0}
                      </span>
                    </span>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    <span>
                      Past{' '}
                      <span className="count-chip past">
                        {appointmentData?.past?.length || 0}
                      </span>
                    </span>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                {loading ? (
                  <Loading />
                ) : appointmentData && appointmentData?.upcoming?.length > 0 ? (
                  <AppointmentSection
                    title="Upcoming"
                    isPast={false}
                    count={appointmentData.upcoming.length}
                    data={appointmentData.upcoming}
                    updateAppointment={updateAppointment}
                    setLatestAppointmentSelected={setLatestAppointmentSelected}
                  />
                ) : (
                  <Box
                    className="noAppointment"
                    alignItems="center"
                    gap={2.5}
                    mt={4}
                  >
                    <center>
                      <img src={icons.noAppointment} />
                      <Typography variant="h1">
                        No upcoming appointments
                      </Typography>
                      <Typography variant="body2">
                        Make some appointments for schedule data to appear
                      </Typography>

                      <Button
                        className="searchSalon"
                        onClick={() => saloonSearch()}
                      >
                        Search Saloon
                      </Button>
                    </center>
                  </Box>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {loading ? (
                  <Loading />
                ) : appointmentData && appointmentData?.past?.length > 0 ? (
                  <AppointmentSection
                    title="Past"
                    count={appointmentData?.past?.length}
                    data={appointmentData?.past}
                    isPast={true}
                    updateAppointment={updateAppointment}
                    setLatestAppointmentSelected={setLatestAppointmentSelected}
                  />
                ) : (
                  <Box
                    className="noAppointment"
                    alignItems="center"
                    gap={2.5}
                    mt={4}
                  >
                    <center>
                      <img src={icons.noAppointment} />
                      <Typography variant="h1">No past appointments</Typography>
                      <Typography variant="body2">
                        You have no past appointments
                      </Typography>
                      <Button
                        className="searchSalon"
                        onClick={() => saloonSearch()}
                      >
                        Search Saloon
                      </Button>
                    </center>
                  </Box>
                )}
              </TabPanel>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            className="grid8 rightAppoitment"
            sx={{ mt: { xs: 10, md: 0 } }}
          >
            <Grid
              container
              className="rightAppointment"
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
            >
              <Grid item xs={12} md={9}>
                <AppointmentDetails
                  image={appointmentDetails?.cover_photo}
                  business={appointmentDetails.business || ''}
                  date={appointmentDetails.appointment_date}
                  appointment_date={appointmentDetails.appointment_date}
                  address={appointmentDetails.address || 'N/A'}
                  location={appointmentDetails?.address?.address_line_1}
                  lattitude={appointmentDetails?.address?.lattitude}
                  longitude={appointmentDetails?.address?.longitude}
                  address_line_2={appointmentDetails?.address?.address_line_2}
                  city={appointmentDetails?.address?.city_name}
                  country={appointmentDetails?.address?.country_name}
                  service={'N/A'}
                  services={appointmentDetails?.services}
                  packages={appointmentDetails?.packages}
                  service_name={'N/A'}
                  currency={'₹'}
                  status={appointmentDetails?.status}
                  processTime={appointmentDetails?.total_hours}
                  price={appointmentDetails?.total_amount}
                  priceIcon={appointmentDetails?.currency?.currency_icon}
                  total_services={appointmentDetails?.total_services}
                  bookingRef={''}
                  booking_ref={''}
                  customerId={customerData?.customer_id}
                  vendorDetailId={appointmentDetails.vendor_detail_id}
                  appointment_id={appointmentDetails?.id || ''}
                  total_amount={appointmentDetails?.total_amount}
                  is_review={appointmentDetails?.is_review || ''}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default AppointmentPage;
