import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
//import LoginForm from './loginForm/loginForm';
import LoginForm from './loginForm/loginForm';
import { useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import AuthService from '../../Services/auth.service';
import { images } from '../../../src/Assets/images';
import { icons } from '../../../src/Assets/icons';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import './Login.css';
import { validatePhoneNumber } from '../../Common/CommonFunctions/common';
import { boolean } from 'yup';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isBlurred, setIsBlurred] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // for glam9 build
  // const shopPath = location?.state?.from === '/shop' && '/booking';
  const shopPath = location?.state?.from || '/';

  // for vreserv build
  //const shopPath = location?.state?.from === '/';

  const authService = AuthService();
  const [loadingToast, setLoadingToast] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  let goBackPath = location?.state?.from || '/';

  function addEmail() {
    setEmailError(validatePhoneNumber(email));
    setIsBlurred(true);
    if (!validatePhoneNumber(email)) {
      verifyUser(); // Proceed only if there’s no error
      setEmailError('');
      setIsBlurred(false);
    }

    // const errorMessage = validatePhoneNumber(email);
    // setEmailError(errorMessage);

    // if (!errorMessage) {
    //   verifyUser(); // Proceed if no validation error
    // }

    // setEmailError('');

    // if (!email) {
    //   setEmailError('Please enter phone number login form');
    //   return;
    // }

    // if (email.length !== 10) {
    //   setEmailError('Phone number should be 10 digits login form');
    //   return;
    // }

    // verifyUser();

    // let isValid = true;

    // if (email.length !== 10) {
    //   setEmailError('Phone number should be 10 digits');
    //   isValid = false;
    // }
    // if (isNaN(parseInt(email))) {
    //   setEmailError('Phone number should be numeric');
    //   isValid = false;
    // }
    // if (email === '') {
    //   setEmailError('Please enter phone number');
    //   isValid = false;
    // }

    // if (isValid) {
    //   verifyUser();
    // }
  }
  const verifyUser = async () => {
    const fd = new FormData();
    fd.append('country_code', '91');
    fd.append('country_iso', 'in');
    fd.append('mobile', email || '');
    try {
      setLoading(true);
      const response = await authService().verifyUser(fd);
      if (response.data.success) {
        sendOtp();
        setLoading(false);
      } else {
        if (response.data.message == 'User is already exists.') {
          sendOtp();
        } else setOpenDialog(true);
        //toast.error('Invalid Credentials. Please check your mobile number and try again');
        //navigate('/register', { state: { email } });
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  const sendOtp = async () => {
    const fd = new FormData();
    fd.append('country_code', '91');
    fd.append('country_iso', 'in');
    fd.append('mobile', email || '');
    fd.append('is_web', '1');
    try {
      const response = await authService().sendOtp(fd);

      toast.success(response.data.message);
      //return false;
      if (response.data.success) {
        localStorage.setItem('otp_verification', 'true');
        navigate('/otp', {
          state: { number: email, prevPage: '/', from: shopPath },
        });
        //navigate('/otp');
      } else {
        navigate('/register', {
          state: { email: email },
        });
      }
    } catch (error: any) {}
  };

  const handleDialogClose = () => {
    setOpenDialog(false);

    // for glam9 build
    navigate('/register', { state: { email, from: shopPath } }); // Redirect to the register page

    // for vreserv build
    // navigate('/register', { state: { email } }); // Redirect to the register page
  };

  return (
    <div className="login">
      <Grid container spacing={2} className="grid-container">
        <Grid item xs={12} md={6} lg={6} className="login-section">
          <LoginForm
            addEmail={addEmail}
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            setEmailError={setEmailError}
            goBackPath={goBackPath}
            loading={loading}
            isBlurred={isBlurred}
            setIsBlurred={setIsBlurred}
          />
          <Dialog
            className="loginConfirmBox"
            open={openDialog}
            onClose={() => {}} // Prevents default closing on outside click or ESC key
            disableEscapeKeyDown
          >
            <DialogContent>
              Mobile number not found. Please register to continue.
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Okay</Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className="logo-section">
          <Box className="brand-container">
            <img
              loading="lazy"
              src={icons.loginLogo}
              alt=""
              className="brand-image"
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
export default LoginPage;

// const [cookies, setCookie] = useCookies([
//   CommonCookie.token,
//   CommonCookie.user,
//   CommonCookie.userType,
//   CommonCookie.profile,
//   CommonCookie.vendor_id,
// ]);
// useEffect(() => {
//   if (cookies.token) {
//     navigate("/");
//   }
// }, [])

// Get the "from" value or fallback to a default route
//  const from = location.state?.from || '/home';

// function backToLanding() {
//   navigate("/");
// }
