import React, { useEffect, useState } from 'react';
import Header from '../common/Header/Header';
import Footer from '../common/Footer/Footer';
import {
  Container,
  Grid,
  Box,
  Rating,
  Typography,
  Button,
} from '@mui/material';
import { TestimonialCard } from './TestimonialCard';
import { BreadcrumbNavigation } from './BreadcrumbNavigation';
import { ScoreCard } from './ScoreCard';
import { useLocation } from 'react-router-dom';
import './TestimonialCard.css';
import './BreadcrumbNavigation.css';
import './ScoreCard.css';
import './Reviews.css';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import { over } from 'lodash';
import { any } from 'prop-types';
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

interface ReviewList {
  id: number;
  customer_name: string;
  rating: string;
  created_date: string;
  description: string;
}

const Reviews = () => {
  useEffect(() => {
    document.body.scrollTop = 0; // For most browsers
    document.documentElement.scrollTop = 0; // For Firefox
  }, []);
  const vendor_detail_id = localStorage.getItem('shopID');
  //const [selectedScores, setSelectedScores] = useState<number[]>([]);
  const [selectedScores, setSelectedScores] = useState<number | null>(null);

  const [data, setData] = useState<ReviewList[]>([]);
  const [rightScoreData, setRightScoreData] = useState<ReviewList[]>([]);

  const handleRadioChange = async (score: number) => {
    setSelectedScores(score);
    try {
      const rating = score;
      const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}/get-all-reviews`;
      const response = await fetch(apiBaseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ rating, vendor_detail_id }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setData(result.data.review.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      handleClose();
    }
  };

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL + '/get-all-reviews';
  useEffect(() => {
    const fetchData = async () => {
      try {
        const vendor_detail_id = localStorage.getItem('shopID'); // Retrieve the vendor detail id from localStorage

        if (!vendor_detail_id) {
          throw new Error('Vendor detail ID is not available in localStorage');
        }

        const response = await fetch(apiBaseUrl, {
          method: 'POST', // Assuming POST is required
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ vendor_detail_id }), // Pass shopID in the request body
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setData(result.data.review.data); // Assuming setData updates your state with the fetched data
        setRightScoreData(result.data.review.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Runs only once on mount

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = useState('Sort by');
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  const { name, overallRating, totalReviews } = location.state || {};
  const breadcrumbItems = [
    { label: 'Home' },
    { label: name },
    { label: 'All Reviews', isLast: true },
  ];

  const handleMenuItemClick = async (value: string, label: any) => {
    try {
      const filter_type = value;
      const vendor_detail_id = localStorage.getItem('shopID');
      const apiBaseUrl =
        process.env.REACT_APP_API_BASE_URL + '/get-all-reviews';
      const response = await fetch(apiBaseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ filter_type, vendor_detail_id }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result.data.review.data);
      setSelectedValue(label);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Container maxWidth="lg" className="reviews-container reviews">
        <Grid container className="mainContainer" spacing={3}>
          {/* Sidebar */}
          <Grid item xs={12} md={6}>
            <BreadcrumbNavigation items={breadcrumbItems} />
            <Box
              className="reviews-section"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Typography variant="h2" className="reviews-title">
                Reviews
              </Typography>
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                className="reviewFilterButton"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {selectedValue}
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                disableScrollLock
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  value="1"
                  onClick={() => handleMenuItemClick('1', 'Top Review')}
                >
                  Top Review
                </MenuItem>
                <MenuItem
                  value="2"
                  onClick={() => handleMenuItemClick('2', 'Latest Review')}
                >
                  Latest Review
                </MenuItem>
                <MenuItem
                  value="3"
                  onClick={() => handleMenuItemClick('3', 'Highest to Lowest')}
                >
                  Highest to Lowest
                </MenuItem>
                <MenuItem
                  value="4"
                  onClick={() => handleMenuItemClick('4', 'Lowest to Highest')}
                >
                  Lowest to Highest
                </MenuItem>
              </StyledMenu>
            </Box>
            <hr className="divider" />
            <Grid container spacing={3}>
              {data.length > 0 ? (
                data.map((testimonial, index) => (
                  <Grid
                    className="testimonialContainer"
                    item
                    xl={12}
                    xs={12}
                    lg={12}
                    sm={12}
                    md={12}
                    key={index}
                  >
                    {/* <label>{testimonial.customer_name}</label> */}
                    <TestimonialCard
                      key={testimonial.id}
                      id={testimonial.id}
                      name={testimonial.customer_name}
                      description={testimonial.description}
                      rating={testimonial.rating}
                      createdDate={testimonial.created_date}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xl={12} xs={12} lg={12} sm={6}>
                  <p>No reviews found</p>
                </Grid>
              )}
            </Grid>
          </Grid>
          {/* Main Content */}
          <Grid xl={5} item xs={12} md={5}>
            <Box className="rating-box">
              <Rating
                value={parseFloat(overallRating)}
                precision={0.5}
                readOnly
                className="rating-stars"
              />
              <Typography className="ratings-summary">
                {overallRating} ({totalReviews} Reviews)
              </Typography>
              <hr className="divider" />
              <Box className="score-section">
                <ScoreCard
                  leftScore={5}
                  rightScore={
                    (Array.isArray(rightScoreData) &&
                      rightScoreData
                        .map(testimonial => testimonial.rating)
                        .filter(rating => rating === '5').length) ||
                    0
                  }
                  imageUrls={1}
                  totalRating={totalReviews}
                  isSelected={selectedScores === 5}
                  onSelect={() => handleRadioChange(5)}
                />
                <ScoreCard
                  leftScore={4}
                  rightScore={
                    (Array.isArray(rightScoreData) &&
                      rightScoreData
                        .map(testimonial => testimonial.rating)
                        .filter(rating => rating === '4').length) ||
                    0
                  }
                  imageUrls={0}
                  totalRating={totalReviews}
                  isSelected={selectedScores === 4}
                  onSelect={() => handleRadioChange(4)}
                />
                <ScoreCard
                  leftScore={3}
                  rightScore={
                    (Array.isArray(rightScoreData) &&
                      rightScoreData
                        .map(testimonial => testimonial.rating)
                        .filter(rating => rating === '3').length) ||
                    0
                  }
                  imageUrls={0}
                  totalRating={totalReviews}
                  isSelected={selectedScores === 3}
                  onSelect={() => handleRadioChange(3)}
                />

                <ScoreCard
                  leftScore={2}
                  rightScore={
                    (Array.isArray(rightScoreData) &&
                      rightScoreData
                        .map(testimonial => testimonial.rating)
                        .filter(rating => rating === '2').length) ||
                    0
                  }
                  imageUrls={0}
                  totalRating={totalReviews}
                  isSelected={selectedScores === 2}
                  onSelect={() => handleRadioChange(2)}
                />

                <ScoreCard
                  leftScore={1}
                  rightScore={
                    (Array.isArray(rightScoreData) &&
                      rightScoreData
                        .map(testimonial => testimonial.rating)
                        .filter(rating => rating === '1').length) ||
                    0
                  }
                  imageUrls={0}
                  totalRating={totalReviews}
                  isSelected={selectedScores === 1}
                  onSelect={() => handleRadioChange(1)}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Reviews;
