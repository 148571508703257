import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Button,
  Divider,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  FormHelperText,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { icons } from '../../Assets/icons';
import { useCookies } from 'react-cookie';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled, css } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CommonCookie from '../CommonCookie';
import './profileSidebar.css';
import { CustomerAPI } from '../../../src/hooks/customer';
import toast from 'react-hot-toast';
import moment from 'moment';
interface ProfileSidebarProps {
  customerData: any;
  getCustomerData: any;
  logoutModalOpen: boolean;
  handleLogoutDialogOpen: () => void;
  handleLogoutAccountDialogClose: () => void;
  handleLogoutAccount: () => void;
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const ProfileSidebar: React.FC<ProfileSidebarProps> = ({
  customerData,
  getCustomerData,
  logoutModalOpen,
  handleLogoutDialogOpen,
  handleLogoutAccountDialogClose,
  handleLogoutAccount,
}) => {
  const { updateCustomerProfileAPICall } = CustomerAPI();
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  const [file, setFile] = useState({} as any);
  const [fileURL, setFileURL] = useState('');
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFile(file);
      setFileURL(imageUrl);
      // Perform any action with the selected file, such as uploading
    }
  };
  const [addressData, setAddressData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setFileURL('');
    setFile({});
  };
  const handleClickEditOpen = (address: any) => {
    setAddressData(address);
    setOpenEdit(true);
  };
  const handleEditClose = () => {
    setOpenEdit(false);

    // removable
    formik.resetForm();
    setFileURL('');
    setFile({});
  };

  const updateProfielDataFunc = async (customerUpdateData: any) => {
    var data = new FormData();

    if (file instanceof File) {
      data.append('profile_image', file);
    }

    data.append('email', customerUpdateData.email);
    data.append('first_name', customerUpdateData.first_name);
    data.append('last_name', customerUpdateData.last_name);
    // data.append('profile_image', file);
    data.append('DOB', moment(customerUpdateData.DOB).format('YYYY-MM-DD'));
    data.append('anniversary_date', customerUpdateData.anniversary_date);
    data.append('gender_type', customerUpdateData.gender_type);
    data.append(
      'country_code',
      JSON.parse(localStorage.getItem('customer_details') || '91').country_code,
    );
    data.append(
      'country_iso',
      JSON.parse(localStorage.getItem('customer_details') || 'in').country_iso,
    );
    data.append('mobile', customerUpdateData.mobile);
    try {
      const result = await updateCustomerProfileAPICall(data);
      if (!result) {
        toast.error('Error while update profile data');
      }
      if (result?.status_code === 200) {
        toast.success('Profile data updated successfully');
        getCustomerData();
        handleClose();
      } else {
        toast.error(result?.message || 'Failed to update profile data', {
          duration: 1000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error('Error while update profile data');
    }
  };
  const [disabled, setDisabled] = useState(false);
  const getInitials = (name: string) => {
    if (!name) return '';
    const nameParts = name.split(' ');
    let initials =
      nameParts.length > 1
        ? nameParts[0][0] + nameParts[nameParts.length - 1][0]
        : nameParts[0][0]; // Use only the first initial if there's one word
    return initials.toUpperCase();
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Please enter first name'),
    last_name: Yup.string().required('Please enter last name'),
    mobile: Yup.string()
      .matches(/^\d+$/, 'Only digits are allowed') // Ensures only digits
      .length(10, 'Mobile number must be exactly 10 digits') // Ensures exactly 10 digits
      .required('Mobile number is required'),
    email: Yup.string()
      .email('Please a valid email address')
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Please a valid email address',
      )
      .required('Please enter email address'),
    gender_type: Yup.string().required('Please select the gender'),
    DOB: Yup.date().nullable().required('Please select the date of birth'),
    // anniversary_date: Yup.date().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      first_name: customerData.first_name || '',
      last_name: customerData.last_name || '',
      mobile: customerData.mobile || '',
      email: customerData.email || '',
      gender_type: customerData.gender_type || '',
      DOB: customerData.DOB_display ? dayjs(customerData.DOB_display) : null,
      anniversary_date: customerData.anniversary_date_display
        ? dayjs(customerData.anniversary_date_display)
        : null,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async values => {
      setLoading(true);

      const formData = new FormData();
      if (file instanceof File) formData.append('profile_image', file);
      // Object.keys(values).forEach((key) => {
      //   formData.append(key, key.includes('date') ? moment(values[key]).format('YYYY-MM-DD') : values[key]);
      // });

      let DOB = values.DOB?.format('YYYY-MM-DD') || '';
      let anniversary_date =
        values.anniversary_date?.format('YYYY-MM-DD') || '';

      if (anniversary_date) {
        formData.append('anniversary_date', anniversary_date);
      }

      formData.append('email', values.email.trim());
      formData.append('first_name', values.first_name.trim());
      formData.append('last_name', values.last_name.trim());
      // data.append('profile_image', file);
      formData.append('DOB', DOB);
      formData.append('gender_type', values.gender_type);
      formData.append(
        'country_code',
        JSON.parse(localStorage.getItem('customer_details') || '91')
          .country_code,
      );
      formData.append(
        'country_iso',
        JSON.parse(localStorage.getItem('customer_details') || 'in')
          .country_iso,
      );
      formData.append('mobile', values.mobile);

      try {
        const result = await updateCustomerProfileAPICall(formData);
        if (result?.status_code === 200) {
          toast.success('Profile updated successfully');
          getCustomerData();
          handleClose();
        } else {
          toast.error(result?.message || 'Failed to update profile data', {
            duration: 1000,
          });
        }
      } catch (error) {
        console.error('Form submission error:', error);
      } finally {
        setLoading(false);
        formik.resetForm();
      }
    },
  });

  return (
    <>
      <Box className="profile-sidebar">
        <Box className="profile-image-container">
          <BootstrapDialog
            onClose={handleEditClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className="editProfile"
          >
            <Box className="header-box">
              <Typography variant="h4" component="h1">
                Edit Profile
              </Typography>
              <img
                loading="lazy"
                src={icons.cancel}
                onClick={handleClose}
                className="address-image"
                alt="Edit Address"
              />
            </Box>

            {customerData.profile_image ? (
              <Avatar
                alt={`${customerData.first_name} ${customerData.last_name}'s profile picture`}
                src={fileURL || customerData.profile_image} // Corrected to use the profile_image dynamically
                className="profile-avatar"
              />
            ) : (
              <Avatar className="profile-avatar" src={fileURL}>
                {getInitials(
                  `${customerData.first_name} ${customerData.last_name}`,
                )}
              </Avatar>
            )}
            <div>
              <button onClick={handleClick} className="camera-upload-button">
                <img
                  alt="Upload"
                  src={icons.camera}
                  className="profile-edit-icon"
                />
              </button>
              {/* <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                onChange={handleFileChange}
              /> */}
              <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} sx={{ padding: '0.5rem 2rem' }}>
                <Grid item xs={6}>
                  <TextField
                    placeholder="First Name"
                    className="input-field firstName"
                    fullWidth
                    {...formik.getFieldProps('first_name')}
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                    helperText={
                      formik.touched.first_name
                        ? (formik.errors.first_name as string)
                        : undefined
                    }
                    // onChange={(event: any) => {
                    //   setCustomerUpdateData({
                    //     ...customerUpdateData,
                    //     first_name: event.target.value,
                    //   });
                    // }}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    placeholder="Last Name"
                    className="input-field lastName"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    {...formik.getFieldProps('last_name')}
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                    helperText={
                      formik.touched.last_name
                        ? (formik.errors.last_name as string)
                        : undefined
                    }
                    // value={customerUpdateData.last_name}
                    // onChange={(event: any) => {
                    //   setCustomerUpdateData({
                    //     ...customerUpdateData,
                    //     last_name: event.target.value,
                    //   });
                    // }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ padding: '0rem 2rem' }}>
                <Grid item xs={6}>
                  <TextField
                    placeholder="Moble Number"
                    className="input-field mobileNumber"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    // {...formik.getFieldProps('mobile')}
                    value={formik.values.mobile}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    placeholder="Email Address"
                    className="input-field email"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    {...formik.getFieldProps('email')}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={
                      formik.touched.email
                        ? (formik.errors.email as string)
                        : undefined
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ padding: '0.5rem 2rem' }}>
                <Grid item xs={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="gender"
                    error={Boolean(
                      formik.touched.gender_type && formik.errors.gender_type,
                    )}
                  >
                    <Select
                      {...formik.getFieldProps('gender_type')}
                      // value={customerUpdateData.gender_type}
                      // onChange={(event: any) => {
                      //   setCustomerUpdateData({
                      //     ...customerUpdateData,
                      //     gender_type: event.target.value,
                      //   });
                      // }}
                      displayEmpty
                      MenuProps={{
                        disableScrollLock: true,
                        PaperProps: {
                          style: { maxHeight: 200 },
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>Gender</em>
                      </MenuItem>
                      <MenuItem key={1} value={1}>
                        Male
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        Female
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.gender_type && formik.errors.gender_type && (
                    <p
                      style={{
                        color: '#d32f2f',
                        margin: 0,
                        padding: 0,
                        fontSize: '12px',
                        fontWeight: 400,
                      }}
                    >
                      {formik.errors.gender_type as string}
                    </p>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        label="Date of Birth"
                        // value={
                        //   customerUpdateData.DOB
                        //     ? dayjs(customerUpdateData.DOB)
                        //     : null
                        // } // Convert to Dayjs
                        value={formik.values.DOB}
                        onChange={value => formik.setFieldValue('DOB', value)}
                        slotProps={{
                          textField: {
                            error: Boolean(
                              formik.touched.DOB && formik.errors.DOB,
                            ),
                            helperText: formik.touched.DOB && formik.errors.DOB,
                          },
                        }}
                        disableFuture
                        format="YYYY-MM-DD"
                        // onChange={newValue => {
                        //   setCustomerUpdateData({
                        //     ...customerUpdateData,
                        //     DOB: newValue ? newValue.format('YYYY-MM-DD') : '', // Format as needed
                        //   });
                        // }}
                        className="input-field birthdate"
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ padding: '0.2rem 2rem' }}>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        label="Anniversary Date"
                        value={formik.values.anniversary_date}
                        onChange={value =>
                          formik.setFieldValue('anniversary_date', value)
                        }
                        disableFuture
                        format="YYYY-MM-DD"
                        // value={
                        //   customerUpdateData.anniversary_date
                        //     ? dayjs(customerUpdateData.anniversary_date)
                        //     : null
                        // } // Convert to Dayjs
                        // onChange={newValue => {
                        //   setCustomerUpdateData({
                        //     ...customerUpdateData,
                        //     anniversary_date: newValue
                        //       ? newValue.format('YYYY-MM-DD')
                        //       : '', // Format as needed
                        //   });
                        // }}
                        className="input-field birthdate anniversaryDate"
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <Grid item xs={6}></Grid>
                </Grid>
              </Grid>
              <Button
                className="continue"
                variant="contained"
                type="submit"
                disabled={loading}
                // onClick={() => {
                //   updateProfielDataFunc(customerUpdateData);
                //   setDisabled(true);
                //   setTimeout(() => {
                //     setDisabled(false);
                //   }, 2000); // 5 seconds delay
                // }}
              >
                Update Profile
              </Button>
            </form>
          </BootstrapDialog>

          {customerData.profile_image ? (
            <Avatar
              alt={`${customerData.first_name} ${customerData.last_name}'s profile picture`}
              src={customerData.profile_image} // Corrected to use the profile_image dynamically
              className="profile-avatar"
            />
          ) : (
            <Avatar className="profile-avatar">
              {getInitials(
                `${customerData.first_name} ${customerData.last_name}`,
              )}
            </Avatar>
          )}
          {/* <Link to="#">
            <img alt="" src={icons.camera} className="profile-edit-icon" />
          </Link> */}
        </Box>
        <Typography
          variant="h4"
          className="profile-name"
        >{`${customerData.first_name} ${' '} ${customerData.last_name}`}</Typography>
        <Button onClick={handleClickOpen} className="edit-info-btn">
          Edit basic info
        </Button>
        <Box className="profile-details">
          <Divider />
          <Typography className="profile-label">First Name</Typography>
          <Typography className="profile-value">
            {customerData.first_name}
          </Typography>
          <Typography className="profile-label">Last Name</Typography>
          <Typography className="profile-value">
            {customerData.last_name}
          </Typography>
          <Typography className="profile-label">Mobile Number</Typography>
          <Typography className="profile-value">
            {customerData.mobile}
          </Typography>
          <Typography className="profile-label">Email Address</Typography>
          <Typography className="profile-value">
            {customerData.email}
          </Typography>
          {customerData.DOB && (
            <>
              <Typography className="profile-label">Date of Birth</Typography>
              <Typography className="profile-value">
                {customerData.DOB}
              </Typography>
            </>
          )}
          {customerData.gender_type && (
            <>
              <Typography className="profile-label">Gender</Typography>
              <Typography className="profile-value">
                {customerData.gender_type === '1' && 'Male'}
                {customerData.gender_type === '2' && 'Female'}
                {customerData.gender_type === '3' && 'Other'}
              </Typography>
            </>
          )}
          {customerData.anniversary_date && (
            <>
              <Typography className="profile-label">
                Anniversary date
              </Typography>
              <Typography className="profile-value">
                {customerData.anniversary_date}
              </Typography>
            </>
          )}
        </Box>

        <Divider className="divider-bottom" />
        <Button className="logout-btn" onClick={handleLogoutDialogOpen}>
          <span>Log Out</span>
          <img alt="logout" src={icons.logout} className="logout-icon" />
        </Button>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={logoutModalOpen} onClose={handleLogoutAccountDialogClose}>
        <DialogContent>
          <Typography>Are you sure you want to logout.</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleLogoutAccountDialogClose}
            style={{ color: '#4E297B' }}
          >
            Cancel
          </Button>
          <Button onClick={handleLogoutAccount} color="error">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ProfileSidebar;
