import React, { useState, useEffect } from 'react';
import TeamMemberType from '../TamMemberType/TeamMember';
import { Box, Typography, Grid } from '@mui/material';
import './TeamSelection.css';
import { icons } from '../../../Assets/icons';
import Professional from '../../../Components/professional/professional';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbContext';
interface TeamSelectionProps {
  selectTeam: Function;
  teamSelectionType: number;
  vendorServiceMembers: any;
  getProfesstionalFunc: Function;
  data: any;
  selectProfesstionalFunc: Function;
  bookPage: any;
}
const TeamSelection: React.FC<TeamSelectionProps> = ({
  selectTeam,
  teamSelectionType,
  vendorServiceMembers,
  getProfesstionalFunc,
  data,
  selectProfesstionalFunc,
  bookPage,
}) => {
  const teamMembers = [
    {
      name: 'Any Professional',
      image: icons.users,
      description: 'For Maximum Availability',
      isSpecial: true,
      type: 1,
      isDefaultSelected: true,
    },
    {
      name: 'Select Professional',
      image: icons.usersPlus,
      description: 'Per Service',
      isSpecial: true,
      type: 2,
      isDefaultSelected: false,
    },
  ];

  const { setBreadcrumbs } = useBreadcrumbs();
  const [selectedMember, setSelectedMember] = useState(teamMembers.find(member => member.isDefaultSelected)?.name || teamMembers[0].name); // Initialize to default or first
  const handleMemberClick = (name: string) => {
    setSelectedMember(name);
  };
  useEffect(() => {
    setBreadcrumbs(prevBreadcrumbes =>
      prevBreadcrumbes.map(breadcrumb =>
        breadcrumb.id === bookPage
          ? { ...breadcrumb, isVisited: true }
          : breadcrumb,
      ),
    );
  }, [setBreadcrumbs]);

  return (
    <>
      <Grid item xs={12} md={9} className="team-selection">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          <Typography variant="h2" className="title">
            Select Team
          </Typography>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            className="team-member-grid"
          >
            {teamMembers.map((member, index) => (
              <Grid
                onClick={() => {
                  selectTeam(member.type);
                }}
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                key={index}
              >
                <TeamMemberType 
                key={member.name}
                 {...member}
                  isSelected={selectedMember === member.name} 
                  onClick={() => handleMemberClick(member.name)}
                   />
              </Grid>
            ))}
          </Grid>
        </Box>
        {teamSelectionType === 2 && (
          <Professional
            vendorServiceMembers={vendorServiceMembers}
            getProfesstionalFunc={getProfesstionalFunc}
            data={data}
            selectProfesstionalFunc={selectProfesstionalFunc}
          />
        )}
      </Grid>
    </>
  );
};

export default TeamSelection;
