// Importing necessary modules and components
import React from 'react';
import { createContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import CommonCookie from '../../Components/CommonCookie';

// Creating an authentication context using React's createContext
const AuthContext = createContext({});

// AuthProvider component responsible for managing authentication state
export const AuthProvider = ({ children }: any) => {
  // Using react-cookie's useCookies hook to manage cookies
  const [cookies, setCookie] = useCookies([
    CommonCookie.token,
    CommonCookie.user,
    CommonCookie.userType,
    CommonCookie.profile,
    CommonCookie.vendor_id,
  ]);
  // Setting up the initial authentication state using the cookies or default values
  const [auth, setAuth] = useState({
    token: cookies.token || '',
    user: cookies.user,
    user_type: cookies.user_type,
    profile: cookies.profile,
    vendor_id: cookies.vendor_id,
  });

  // Providing the AuthContext with the auth state and setAuth function to its children
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
