import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import './CouponInput.css'; // CSS file for custom styles

const CouponInput: React.FC = () => {
  return (
    <Box
      component="form"
      className="coupon-form"
      sx={{
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        px: 2,
        py: 2,
        mt: 4,
        bgcolor: 'white',
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'grey.800',
      }}
    >
      <TextField
        id="couponInput"
        variant="outlined"
        placeholder="Type Coupon Code Here"
        InputProps={{
          'aria-label': 'Type Coupon Code Here',
        }}
        sx={{ flexGrow: 1, color: 'text.primary' }}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{
          px: 3,
          py: 1.5,
          bgcolor: 'grey.800',
          color: 'white',
          whiteSpace: 'nowrap',
          borderRadius: 1,
        }}
      >
        Apply
      </Button>
    </Box>
  );
};

export default CouponInput;
