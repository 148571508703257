import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import HeroAbout from './HeroAbout';
import HeroMain from './HeroMain';
import './vendor.css';
import GrowthAdvisior from './GrowthAdvisior';
import ConnectionForm from './ConnectionForm';
import LeaderShip from './Leadership';

const Vendor: React.FC = () => {
  useEffect(() => {
    document.body.scrollTop = 0; // For most browsers
    document.documentElement.scrollTop = 0; // For Firefox
  }, []);
  return (
    <>
      <Box className="hero-section">
        <HeroMain />
        <HeroAbout />
      </Box>
      <LeaderShip />
      <Box className="hero-section">
        {/* <GrowthAdvisior /> */}
        <ConnectionForm />
      </Box>
    </>
  );
};

export default Vendor;
