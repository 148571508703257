import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Avatar,
} from '@mui/material';
import './salonCard.css';
import { icons } from '../../Assets/icons';
import { images } from '../../Assets/images';
import toast from 'react-hot-toast';
import { ShopAPI } from '../../../src/hooks/shop';
import { useNavigate } from 'react-router/dist';
import { ApiResponse } from '../../../src/api/types/common';
type SalonCardProps = {
  data: any;
  onRemoveFavorite: (vendorId: string) => void; // Add prop for removing favorite
};

const SalonCard: React.FC<SalonCardProps> = ({ data, onRemoveFavorite }) => {
  const navigate = useNavigate();
  const gotoShop = (vendor_detail_id: string) => {
    localStorage.setItem('shopID', vendor_detail_id);
    navigate('/shop');
  };

  let vendor_detail_id = data.vendor_detail_id;

  const { addRemoveFavVendorAPICall } = ShopAPI();

  const handleUnfavorite = async (e: React.MouseEvent) => {
    e.stopPropagation();
    let is_favorite = '1';
    const result: ApiResponse = await addRemoveFavVendorAPICall(
      parseInt(vendor_detail_id),
      is_favorite,
    );
    if (result.success) {
      toast.success(result.message);

      onRemoveFavorite(data.vendor_detail_id);
    } else {
      toast.error('Something went wrong.');
    }
  };

  return (
    <Card className="salon-card" onClick={() => gotoShop(vendor_detail_id)}>
      <Box className="image-section">
        <CardMedia
          component="img"
          height="250"
          image={data.vendor_media.media_name || images.glam9512}
          alt={`${data.business_name}`}
          className="salon-image"
        />
        <Avatar
          src={icons.redHeart}
          alt="Favorite icon"
          className="favorite-icon"
          sx={{ height: '70px', width: '70px' }}
          onClick={handleUnfavorite}
        />
      </Box>
      <CardContent className="content-section">
        <div className="salonTitleRating">
          <Box className="salon-info">
            <Typography
              variant="body1"
              style={{
                fontSize: '18px',
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
              }}
              className="favouriteTitle"
            >
              {data.business_name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginTop: '0px',
                fontSize: '14px',
                color: 'rgba(35, 31, 32, 1)',
                opacity: '30%',
                fontWeight: '400',
                height: '25px',
              }}
              className="favouriteLocation"
            >
              {data.distance !== '' ? (
                <>
                  <span>{data.distance}</span>
                  <span className="dividerLine">|</span>
                </>
              ) : (
                <></>
              )}
              {/* {data.vendor_address.address_line_1} */}
              {data.vendor_address.address_line_1.length > 100
                ? data.vendor_address.address_line_1.slice(0, 100) + '...'
                : data.vendor_address.address_line_1}
              {/* {data.vendor_address?.city_name},{' '}
              {data.vendor_address?.state_name} */}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="body2"
                style={{ color: 'rgba(35, 31, 32, 1)' }}
                className="favouriteRating"
              >
                {data?.rating == '0 (0)' ? (
                  <img src={icons.noStar} alt="Rating" />
                ) : (
                  <img src={icons.rating} alt="Rating" />
                )}

                <span className="ratingCount">{data.rating} </span>
              </Typography>

              {data?.gender_type !== '' ? (
                <span className="favouriteTypes">
                  {data?.gender_type === '1'
                    ? 'Male'
                    : data?.gender_type === '2'
                      ? 'Female'
                      : 'Unisex'}
                </span>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </div>
      </CardContent>
    </Card>
  );
};

export default SalonCard;

{
  /* <Box className="services-section">
        <Grid container spacing={1}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box className="service-card">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8} md={8} lg={8} key={index}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: {
                          xs: '12px', // small screen
                          sm: '7px', // small to medium screen
                          md: '10px', // medium to large screen
                          lg: '10px', // large screen
                          xl: '11px', // extra large screen
                        },
                        lineHeight: {
                          xs: '12px', // small screen
                          sm: '10px', // small to medium screen
                          md: '10px', // medium to large screen
                          lg: '12px', // large screen
                          xl: '12px', // extra large screen
                        },
                      }}
                      className="service-name"
                    >
                      {service.name}
                    </Typography>

                    <Typography
                      variant="caption"
                      color="textSecondary"
                      sx={{
                        fontSize: {
                          xs: '12px', // small screen
                          sm: '10px', // small to medium screen
                          md: '9px', // medium to large screen
                          lg: '8px', // large screen
                          xl: '11px', // extra large screen
                        },
                        lineHeight: {
                          xs: '12px', // small screen
                          sm: '10px', // small to medium screen
                          md: '11px', // medium to large screen
                          lg: '12px', // large screen
                          xl: '12px', // extra large screen
                        },
                      }}
                      className="service-duration"
                    >
                      {service.duration}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: '12px', // small screen
                          sm: '10px', // small to medium screen
                          md: '10px', // medium to large screen
                          lg: '11px', // large screen
                          xl: '11px', // extra large screen
                        },
                        lineHeight: {
                          xs: '12px', // small screen
                          sm: '10px', // small to medium screen
                          md: '6px', // medium to large screen
                          lg: '12px', // large screen
                          xl: '12px', // extra large screen
                        },
                        color: '#0178FA',
                      }}
                      className="service-price"
                    >
                      ₹{service.price}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
          <Button variant="text" className="see-more-btn">
            See More
          </Button>
        </Grid>
      </Box> */
}
