import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { icons } from '../../Assets/icons';
import { useNavigate } from 'react-router-dom';
import BeautySpot from './BeautySpot';
import './SearchItems.css';
import { images } from '../../../src/Assets/images';

interface VendorAddress {
  [key: string]: string | undefined; // Dynamic keys with string values
}
interface SearchItemObject {
  [key: string]: any; // Allows any number of properties with string keys and any type of value
}
// Define the props interface
interface SearchItems {
  dataForPagination: SearchItemObject[]; // An array of dynamic objects
  setPlotData: Function; // purpose to add is for display same location in pagination and map marker
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  updateData: any;
}
const SearchItemsCopy: React.FC<SearchItems> = ({
  dataForPagination,
  setPlotData,
  currentPage,
  totalPages,
  onPageChange,
  updateData,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    setPlotData(dataForPagination);
  }, [dataForPagination]);
  // Total pages
  /* const totalPages = useMemo(() => {
    return Math.ceil(dataForPagination?.length / rowsPerPage);
  }, [dataForPagination, rowsPerPage]);
  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  }; */
  function gotoShop(vendor_detail_id: any) {
    try {
      localStorage.setItem('shopID', vendor_detail_id.toString());
      navigate('/shop');
    } catch (error) {
      console.error('Error navigating to shop:', error);
    }
  }
  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > totalPages) return;

    // Check if updateData is non-empty
    if (Object.keys(updateData).length > 0) {
      // If updateData is NOT empty, create the payload and navigate
      onPageChange(newPage);
      const payload = {
        ...updateData, // Additional data
        page: newPage, // Updated page number
      };
      navigate('/search', {
        state: {
          searchReq: payload, // Pass the payload with updateData included
        },
      });
    } else {
      // Else, if updateData is empty, just update the page number
      onPageChange(newPage);
    }
  };
  // Function to construct the address
  const constructAddress = (address?: VendorAddress): string => {
    if (!address) return ''; // Return empty if no address
    const parts: string[] = [];
    // Iterate over the keys of the address object
    for (const key in address) {
      if (key !== 'lattitude' && key !== 'longitude' && address[key]) {
        // Check if the value is not empty
        parts.push(address[key]!); // Use non-null assertion since we checked for undefined
      }
    }
    return parts.join(', '); // Join the parts with a comma
  };
  return (
    <>
      {dataForPagination.length > 0 ? (
        <>
          {dataForPagination[0] && (
            <BeautySpot
              photo={
                dataForPagination[0]?.vendor_media?.media_name ||
                images.glam9512
              }
              name={dataForPagination[0]?.business_name}
              rating={dataForPagination[0]?.rating || 0}
              gender={dataForPagination[0]?.gender_type}
              address={dataForPagination[0]?.vendor_address}
              vendor_detail_id={dataForPagination[0]?.vendor_detail_id || 0}
              distance={dataForPagination[0]?.distance || ''}
            />
          )}
          {dataForPagination.slice(1).map((item, idx) => {
            const hasVendorAddress =
              item.vendor_address &&
              Object.keys(item?.vendor_address).length > 0;
            let locationAddress = '';
            if (hasVendorAddress) {
              locationAddress = constructAddress(item?.vendor_address);
            }
            return (
              <Card
                className="appointmentCard"
                onClick={() => gotoShop(item.vendor_detail_id)}
                key={idx + 1}
              >
                <Box className="cardContainer">
                  <Box className="imageContainer">
                    <img
                      loading="lazy"
                      src={item?.vendor_media?.media_name || images.glam9512}
                      alt={item?.business_name}
                      className="appointment-image"
                    />
                  </Box>
                  <CardContent className="contentContainer">
                    <div className="contentFirst">
                      <div className="contentTitle fullWidth">
                        <Typography
                          variant="h6"
                          className="title"
                          style={{
                            fontSize: '18px',
                            fontWeight: '600',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {item?.business_name}
                        </Typography>
                        {/* <Typography variant="body2" className="price">
                          {item?.gender_type === '1'
                            ? 'Male Salon'
                            : item?.gender_type === '2'
                              ? 'Female Salon'
                              : item?.gender_type === '3'
                                ? 'Unisex Salon'
                                : ''}
                        </Typography> */}
                      </div>
                      {/* <div className="contentRating">
                        <Typography variant="body1" className="ratingText">
                          <span>{item.rating !== '' ? item.rating : ''} </span>
                          <img
                            loading="lazy"
                            src={icons.rating}
                            alt="Rating star icon"
                            className="rating-star-icon"
                          />
                        </Typography>
                      </div> */}
                    </div>
                    {hasVendorAddress && (
                      <div className="contentAddress">
                        <Typography
                          variant="body2"
                          className="location"
                          sx={{
                            marginTop: '0px',
                            fontSize: '14px',
                            color: 'rgba(35, 31, 32, 1)',
                            opacity: '30%',
                            fontWeight: '400',
                            height: '25px',
                          }}
                        >
                          {item?.distance !== '' ? (
                            <>
                              <span className="kmRange">{item?.distance}</span>
                              <span className="lineDivider">|</span>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* {item.vendor_address?.city_name}, {item.vendor_address?.state_name} */}
                          {/* <span className="searchAdd">
                            {item.vendor_address.address_line_1}
                          </span> */}
                          <span className="searchAdd">
                          {item.vendor_address.address_line_1.length > 80
                            ? item.vendor_address.address_line_1.slice(0, 80) + '...'
                            : item.vendor_address.address_line_1}
                          </span>
                          {/* <img
                            loading="lazy"
                            src={images.location}
                            className="locationIcon"
                            alt="Location pin icon"
                          />
                          <span>{locationAddress}</span> */}
                        </Typography>
                        {/* <Typography variant="body2" className="nextIcon">
                          <img src={icons.blackRightArrow} />
                        </Typography> */}
                      </div>
                    )}
                    <div className="ratingContainer">
                      <Typography
                        className="ratingContent"
                        variant="body2"
                        style={{ color: 'rgba(35, 31, 32, 1)' }}
                      >
                        {item?.rating == '0 (0)' ? (
                          <img
                            className="salonRating"
                            src={icons.noStar}
                            alt="Rating"
                          />
                        ) : (
                          <img
                            className="salonRating"
                            src={icons.rating}
                            alt="Rating"
                          />
                        )}
                        <span className="ratingCount">{item?.rating} </span>
                        {/* <img
                                            className="salonRating"
                                            src={icons.rating}
                                            alt="Rating"
                                          /> */}
                      </Typography>

                      {item?.gender_type !== '' ? (
                        <span className="salonTypes">
                          {item?.gender_type === '1'
                            ? 'Male'
                            : item?.gender_type === '2'
                              ? 'Female'
                              : 'Unisex'}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </CardContent>
                </Box>
              </Card>
            );
          })}
          {totalPages > 1 && (
            <Box
              display="flex"
              className="onlyBottom"
              justifyContent="flex-end"
              alignItems="center"
              mt={4}
            >
              <Button
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                sx={{ marginRight: '8px' }}
              >
                Previous
              </Button>
              <Typography variant="body1" sx={{ margin: '0 16px' }}>
                Page {currentPage} of {totalPages}
              </Typography>
              <Button
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages || totalPages === 0}
              >
                Next
              </Button>
            </Box>
          )}
        </>
      ) : (
        <Typography variant="h6" textAlign="center">
          No Results found
        </Typography>
      )}
    </>
  );
};
export default SearchItemsCopy;
