import React, { useEffect, useState } from 'react';
import Header from '../common/Header/Header';
import Footer from '../common/Footer/Footer';
import { Container, Grid, Typography, Box } from '@mui/material';
import { images } from '../../../src/Assets/images';
import './Refund.css';
const Terms = () => {
  useEffect(() => {
    document.body.scrollTop = 0; // For most browsers
    document.documentElement.scrollTop = 0; // For Firefox
  }, []);
  return (
    <>
      <Grid container spacing={2} className="cmdBanner">
        <Typography variant="h2">
          Terms and <span>Conditions</span>
        </Typography>
      </Grid>
      <Grid container spacing={2} className="cmdContent termsContent">
        <Typography variant="h2">Introduction</Typography>
        <Typography variant="body1">
          {' '}
          This Platform is owned and operated by Glam9 Solutions Private
          Limited (the "Glam9" "Company" or "Us" or "We", which expression would
          mean and include its officers, successors and permitted assigns). The
          Website is an online platform for providing such services as mentioned
          on the Website ("Services").
        </Typography>
        <Typography variant="h3">
          1. APPLICABILITY AND AMENDMENT OF TERMS:
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          These terms and conditions of use ("Terms of Use" or "Terms" and the
          privacy policy available on website("Privacy Policy") apply to all
          visitors and users of the Website(s) ("Users" or "You"). We request
          You to carefully go through these Terms and the Privacy Policy before
          you decide to access this Website or use the Services. These Terms and
          the Privacy Policy need to be read together and constitute the
          agreement ("Agreement") between You and the Company in connection with
          your visit to the Website and Your use of the Services.
        </Typography>
        <Typography variant="body1">
          Your use of the Website or the Services will signify your acceptance
          of the Agreement. If the terms of the Agreement are not acceptable to
          you, please avoid visiting the Site or availing the Services.
        </Typography>
        <Typography variant="body1">
          If a booked service is not delivered as promised, is unsatisfactory,
          or if there is a significant discrepancy in the service received,
          customers are required to report the issue to Glam9 within 48 hours of
          the service completion.
        </Typography>
        <Typography variant="body1">
          We reserve the right to modify or terminate any portion of the Website
          or the Services or amend the Agreement, without notice and without
          liability to you or any third party. To make sure you are aware of any
          changes, please review these Terms periodically. The Company may
          require you to provide your consent to the Agreement or the Terms, as
          updated, in a specified manner before any further use of the Website
          and the Services. If no such separate consent is sought, your
          continued use of the Website will constitute your acceptance of such
          changes.
        </Typography>
        <Typography variant="body1">
          Nothing in the Agreement should be construed to confer any rights to
          third party beneficiaries.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent termsContent">
        <Typography variant="h3">2. REGISTRATION AND ACCESS</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          Some Services will need you to register on the Website and open and
          maintain an account ("Account"). Some will not. You are free to use
          the Services that do not require you to open or maintain an Account
          without having to open one.
        </Typography>
        <Typography variant="body1">
          Opening and operating Accounts will require You to apply rules in
          determining who will have access and the nature of the access
          available to each such person.
        </Typography>
        <Typography variant="body1">
          When you use the Website, you are responsible for maintaining the
          confidentiality of your Account and password and for restricting
          access to devices that You use to access the Account to prevent
          unauthorized access to your account. It is your responsibility to keep
          your Account information current and accurate. Please (a) ensure that
          You exit from your account at the end of each session, and (b)
          immediately Notify Us of any unauthorized use of your Account  or any
          other breach of security. While we cannot, and will not, be liable for
          any loss or damage arising from your failure to comply with these
          conditions. You may be held liable for losses incurred by Us or any
          other user of or visitor to the Website due to authorized or
          unauthorized use of your Account as a result of your failure to keep
          your Account information secure and confidential.
        </Typography>
        <Typography variant="body1">
          We reserve the right to refuse access to the Website, to terminate
          Accounts, remove or edit content at our discretion.
        </Typography>
        <Typography variant="body1">
          We will try to make the Website error-free. Your access to the Website
          may be occasionally suspended or restricted to allow for repairs,
          maintenance, or for introduction of new facilities or services.
          However, we do not take responsibility for internet related issues at
          your end.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent termsContent">
        <Typography variant="h3">3. WEBSITE CONTENT</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          We grant You a limited license to access and make personal use of this
          Website. However, all information, content and material contained in
          the Website is and continues to be Company’s intellectual property.
          Further, all trademarks, services marks, trade names and trade secrets
          are proprietary to the Company. No information, content or material
          from the Website may be copied, reproduced, republished, uploaded,
          posted, transmitted or distributed in any way without our express
          written permission. Any unauthorised use terminates the permission or
          license granted by us in terms of the Agreement.
        </Typography>
        <Typography variant="body1">
          You expressly understand and agree that:
        </Typography>
        <Typography variant="body1">
          The Company is not a law firm/ chartered accounted/ company secretary
          or any such specialized and expertise person. Please consult with such
          experts before implementing our advice. The nature of the material on
          the Website and operation of Accounts is a matter of opinion in many
          ways. The material on Website is by no means complete and
          comprehensive.
        </Typography>
        <Typography variant="body1">
          The information, content and material on the Website and / or Service
          is provided on an "as is" and "as available" basis. The Company and
          its subsidiaries, affiliates, officers, employees, agents and
          partners, if any, disclaim all warranties of any kind, either express
          or implied, including but not limited to, implied warranties of
          merchantability, fitness for a particular purpose and
          non-infringement;
        </Typography>
        <Typography variant="body1">
          The Company does not warrant that{' '}
        </Typography>
        <ul className="termsList">
          <li>
            (i) the functions contained in any content, information and material
            on the Website, including, without limitation any third party sites
            or services linked to the Website and / or that the Service will be
            uninterrupted, timely or error-free,{' '}
          </li>
          <li>
            (ii) the defects will be rectified, or that the Website or the
            servers that make such content, information and materials available
            are free of viruses or other harmful components
          </li>
        </ul>
        <Typography variant="body1">
          Any material downloaded or otherwise obtained through the Website is
          accessed at your own risk, and you will be solely responsible for any
          damage or loss of data that results from such download to your
          computer system; and
        </Typography>
        <Typography variant="body1">
          The Company cannot and will not assure you that other users of the
          Website are or will be complying with the foregoing rules or any other
          provisions of these Terms of Use. As between you and the Company, you
          hereby assume all risk of harm or injury resulting from any such lack
          of compliance
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent termsContent">
        <Typography variant="h3">
          4. REPRESENTATIONS AND WARRANTIES BY THE USERS
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          By using the Website, you represent and warrant that{' '}
        </Typography>
        <ul className="termsList">
          <li>
            (a) You are competent to contract, (which in the case of individuals
            implies that you are a "major"),{' '}
          </li>
          <li>
            (b) You are eligible to avail of the Services (which in the case of
            corporate persons assumes that you have necessary internal
            approvals),
          </li>
          <li>
            (c) Your use of the Services conforms to law as applicable to You
            and{' '}
          </li>
          <li>
            (d) all information provided to Us You is true, factual and accurate
            and you agree to maintain the accuracy of such information.
          </li>
        </ul>

        <Typography variant="body1">
          The Company reserves the right to terminate the Services including
          suspending your Account if You are in breach of Your obligations to
          Us, violate any law in availing the Services or breach your
          representations and warranties to us.  
        </Typography>
        <Typography variant="body1">
          You shall indemnify, defend and hold the Company, the Company’s
          agents, affiliates, representatives, authorized users, employees and
          assigns harmless from and against any and all losses, damages,
          liabilities and costs arising from your use of the Website or the
          Services and / or the violation of the Agreement by you.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent termsContent">
        <Typography variant="h3">5. CONDITIONS OF USE</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          You shall use this Website for reasonable and lawful purposes only,
          and shall not indulge in any activity that is not envisaged through
          the Website.
        </Typography>
        <Typography variant="body1">
          You will not modify any content of the Website unless you are
          authorised to do so; and decompile, reverse engineer or disassemble
          the content
        </Typography>
        <Typography variant="body1">
          use the Service in any way that is unlawful or harms the Company or
          any other person or entity, as determined in Company’s sole discretion
        </Typography>
        <Typography variant="body1">
          make false or malicious statements against the Services or Website or
          the Company
        </Typography>
        <Typography variant="body1">
          post, copy, submit, upload, distribute or otherwise transmit or make
          available any software or other computer files that contain a virus or
          other harmful components, or otherwise impair or damage the Website
          and / or Services or any connected network, or otherwise interfere
          with any person or entity’s use or enjoyment of the Website and / or
          the Services
        </Typography>
        <Typography variant="body1">
          engage in any form of antisocial, disruptive or destructive acts,
          including "flaming," "spamming," "flooding," "trolling," and
          "griefing" as those terms are commonly understood and used on the
          internet
        </Typography>
        <Typography variant="body1">
          post or upload any content that is libelous, defamatory, abusive,
          threatening, harassing, hateful, offensive or otherwise violates any
          law or right of any third party;
        </Typography>
        <Typography variant="body1">
          delete or modify any content of the Website and / or Services,
          including but not limited to, legal notices, disclaimers or
          proprietary notices such as copyright or trademark symbols, logos,
          that you do not own or have express permission to modify; and
        </Typography>
        <Typography variant="body1">
          host, display, upload, modify, publish, transmit, update or share any
          information that
        </Typography>
        <Typography variant="body1">
          belongs to another person and to which the user does not have any
          right;
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent termsContent">
        <Typography variant="h3">6. LINKS TO THIRD PARTY WEBSITES</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          The Website includes links to other websites whose terms and privacy
          practices may differ from those of the Company. The inclusion of a
          link does not imply any endorsement by the Company of the third party
          website, the website’s provider, or the information on the third party
          website. If the users access or submit personal information to any of
          those websites, such access and information will be governed by the
          terms of use and privacy policies of such third party websites and the
          Company disclaims all responsibility or liability with respect to the
          terms, policies or the websites. The users are encouraged to carefully
          read the terms and privacy policy of any website that they visit.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent termsContent">
        <Typography variant="h3">7. LIMITATION OF LIABILITY</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          You acknowledge and undertake that you are accessing the Services
          through the Website, transacting at your own risk and are using your
          best and prudent judgment before entering into any transactions
          through the Website.
        </Typography>
        <Typography variant="body1">
          To the fullest extent permitted by law, under no circumstances will
          the Company be liable to you or any other person or entity for any
          direct, indirect, incidental, special, remote or consequential
          damages, including but not limited to damages for loss of profits,
          goodwill, data or other intangible losses, resulting from any
          circumstances, including
        </Typography>

        <ul className="termsList">
          <li>(a) the use or the inability to use the Services; or </li>
          <li>
            (b) unauthorized access to or alteration of your transmissions or
            data;
          </li>
          <li>
            (c) any other matter relating to the Website and / or Services.
          </li>
        </ul>
        <Typography variant="body1">
          The above limitations shall apply irrespective of whether the Company
          was aware (whether by Notification by You or otherwise) or could have
          foreseen the damages and losses You would suffer.
        </Typography>
        <Typography variant="body1">
          Without prejudice to the generality of the above, the maximum
          liability of the Company for any damages to You under all heads
          (whether related or unrelated and together with any person claiming
          through, with or under You) shall be limited to the fee and charges,
          if any paid by You to the Company for a period of one year prior to
          Your claims. 
        </Typography>
        <Typography variant="body1">
          We shall neither be liable nor responsible for any actions or
          inactions of the other users of the Website nor any breach of
          conditions, representations or warranties by them. We do not take any
          obligation to mediate or resolve any dispute or disagreement between
          you and the other users of the Website.
        </Typography>
        <Typography variant="body1">
          Further, none of the directors, officers or employees of the Company
          shall be personally liable for any action in connection with the
          Website or the Services.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent termsContent">
        <Typography variant="h3">8. COPYRIGHT AND DATABASE RIGHTS</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          All content included on the Website, such as photographs, text,
          graphics, logos, button icons, images and software, is the exclusive
          property of the Company, its affiliates or its content suppliers.
        </Typography>
        <Typography variant="body1">
          The compilation of all content on the Website is the exclusive
          property of the Company.
        </Typography>
        <Typography variant="body1">
          All software used on this Website is the property of the Company or
          its software suppliers. You shall not systematically extract and/or
          re-utilize parts of the contents of the Website without the Company’s
          express written consent. In particular, you shall not utilize any data
          mining, robots, or similar data gathering and extraction tools to
          extract (either once or a number of times) for re-utilization of any
          substantial parts of this Website, without the Company’s prior and
          express written consent.
        </Typography>
        <Typography variant="body1">
          You shall not create and/or publish your own database that features
          substantial parts of this Website [(e.g. prices and product listings)]
          without the Company’s express written consent. You further agree not
          to access or use this Website in any manner that may be harmful to the
          operation of this Website or its content.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent termsContent">
        <Typography variant="h3">9. TRADENAMES</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          Synerger and other marks indicated on the Website are trademarks to be
          registered or registered trademarks of the Company in India. All other
          trademarks not owned by the Company that appear on this Website are
          the property of their respective owners, who may or may not be
          affiliated with, connected to, or sponsored by the Company.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent termsContent">
        <Typography variant="h3">10. TERMINATION</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          In case of non-compliance with the Terms of Use or Privacy Policy or
          in the event any other user reports violation of any of their rights
          as a result of your use of the Services, the Company reserves the
          right to immediatelysuspend or terminate your access to the Website
          with or without notice to you; andto remove such non-compliant
          information from the Website; andto exercise any other remedy
          available under law.
        </Typography>
        <Typography variant="body1">
          Any suspected illegal, fraudulent or abusive activity will be grounds
          for terminating your access to the Website. Upon suspension or
          termination, your right to avail the Services and access to the
          Website will immediately cease and Company reserves the right to
          remove or delete your information that is available, including but not
          limited to login, account information and information posted by you.
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent termsContent">
        <Typography variant="h3">
          11. ACCESS OUTSIDE THE REPUBLIC OF INDIA
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          The Company makes no representation that the content contained on the
          Website is appropriate to be used or accessed outside the Republic of
          India. If the users use or access the Website from outside the
          Republic of India, they do so at their own risk and are responsible
          for compliance with the laws of such jurisdiction. These Terms of Use
          do not constitute, nor should they be used for or in connection with,
          any promotional activities or solicitation by anyone in any
          jurisdiction in which such promotional activities or solicitation is
          not authorized or to any person to whom it is unlawful to promote or
          solicit. 
        </Typography>
      </Grid>

      <Grid container spacing={2} className="cmdContent termsContent">
        <Typography variant="h3">12. GOVERNING LAW AND JURISDICTION</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          The Agreement and all transactions entered into and the relationship
          between you and the Company shall be governed in accordance with the
          laws of India without reference to any conflict of laws principles.
          You agree that subject to other sections of this paragraph, courts in
          Ahmedabad India shall have exclusive jurisdiction over any disputes
          arising under or in connection with your use of the Website or the
          Services.
        </Typography>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ marginBottom: '100px' }}
        className="cmdContent termsContent"
      >
        <Typography variant="h3">13. MISCELLANEOUS</Typography>
        <br></br>
        <br></br>
        <Typography variant="body1">
          Severability. If any provision of the Agreement is held by a court of
          competent jurisdiction or arbitral tribunal to be unenforceable under
          applicable law, then such provision will be excluded from the
          Agreement and the remainder of the Agreement will be interpreted as if
          such provision were so excluded and will be enforceable in accordance
          with its terms; provided however that, in such event the Agreement
          will be interpreted so as to give effect, to the greatest extent
          consistent with and permitted by applicable law, to the meaning and
          intention of the excluded provision as determined by such court of
          competent jurisdiction or arbitral tribunal. The Company may also
          replace such offending provision with an alternate one that is as
          close to the original provision as possible but yet valid and
          enforceable.  The alternate provision shall take effect from the date
          on which the original offending provision has been included. 
        </Typography>
        <Typography variant="body1">
          Abuse. If You come across any abuse or violation of the Terms of Use
          or if you become aware of any objectionable content on the Website,
          please contact us on information provided on Website.
        </Typography>
        <Typography variant="body1">
          The Privacy Policy (which forms a part of the Agreement)  sets out the
          privacy practices of the Website. The Privacy Policy has been
          published in accordance with the provisions of the Information
          Technology Act, 2000 and the applicable rules made thereunder. In
          accessing this Website and availing the Services provided herein, you
          confirm that you have read, fully understand and accept the Privacy
          Policy.
        </Typography>
        <Typography variant="body1">
          Newsletters and Communications. You hereby expressly agree to receive
          communications and newsletters from the Company by SMS and e-mails.
          You can unsubscribe / opt-out from receiving communications and
          newsletters from the Company at any time by following the procedure
          set forth in the Website.
        </Typography>
        <Typography variant="body1">
          Waiver. No term of the Agreement will be deemed waived and no breach
          excused, unless such waiver or consent is in writing and signed by the
          Company. Any consent by Company, or waiver of a breach, whether
          expressed or implied, will not constitute consent to, waiver of, or
          excuse for any other different or subsequent breach.
        </Typography>
        <Typography variant="body1">
          Force Majeure. We will not be responsible for any delay or failure to
          comply with our obligations under the Agreement if the delay or
          failure arises from any cause which is beyond our reasonable control.
        </Typography>
        <Typography variant="body1">
          Electronic Record. This document is an electronic record in terms of
          the Information Technology Act, 2000 ("IT Act") and the rules made
          thereunder as may be applicable, and the amended provisions pertaining
          to electronic records in various statutes as amended by the IT Act.
          This electronic record is generated by a computer system and does not
          require any physical or digital signatures. This document is published
          in accordance with the provisions of the IT Act and the rules made
          thereunder that require publishing the rules and regulations, privacy
          policy and terms of use of the Website.
        </Typography>
      </Grid>
    </>
  );
};

export default Terms;
