import React from 'react';
import { Toaster } from 'react-hot-toast';

import Route from './Routes';

const App: React.FC = () => (
  <React.Fragment>
    <Route />
    <Toaster />
  </React.Fragment>
);

export default App;
