import React, { useState } from 'react'; // Import useState
import { Typography, Card, CardMedia, Grid } from '@mui/material';
import './ReviewConfirm.css';
import { icons } from '../../../Assets/icons';
import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import CouponSection from '../CoupenSection/CouponSection';
import toast from 'react-hot-toast';
interface reviewProps {
  setDiscountAmount: Function;
  setBookingNotes: (notes: string) => void; // Add this prop
}
const ReviewConfirm: React.FC<reviewProps> = ({
  setDiscountAmount,
  setBookingNotes,
}) => {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  const [open, setOpen] = useState(false);
  const [couponColor, setCouponColor] = useState('black'); // State to manage text color
  const [showArrow, setShowArrow] = useState(true);
  const handleClickOpen = () => {
    setOpen(true);
    setShowArrow(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function gotoVenue() {
    // toast.success('at venue');
  }
  function gotoOnline() {
    toast.success('online');
  }

  // Function to change the coupon text color
  const handleCouponApply = (amount: number) => {
    setCouponColor('#0178FA');
    setDiscountAmount(amount);
  };
  const handleColorChange = () => {
    setCouponColor('#000');
    setShowArrow(true);
  };
  const handleCrossClick = (e: any) => {
    e.stopPropagation(); // Prevent triggering the card click event
    handleColorChange(); // Reset color and arrow when cross is clicked
  };

  // Booking notes
  const [bookingNotes, setLocalBookingNotes] = useState('');

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const notes = event.target.value;
    setLocalBookingNotes(notes);
    setBookingNotes(notes); // Call parent callback
  };

  return (
    <>
      <Grid item xs={12} md={5}>
        <Typography variant="h4" className="heading">
          Review and Confirm
        </Typography>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="appt"
        >
          <CouponSection
            handleClose={handleClose}
            onApplyCoupon={handleCouponApply}
          />
        </BootstrapDialog>
        <Grid container spacing={2}>
          {/* First Card */}
          <Grid item xs={12}>
            <Card className="reviewCard" onClick={gotoVenue}>
              <Grid container spacing={2}>
                <Grid item>
                  <CardMedia
                    component="img"
                    loading="lazy"
                    src={icons.payVenue}
                    alt=""
                    className="image-small"
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="h6" className="payVenue">
                    Pay at Venue
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {/* Second Card */}
          {/* <Grid item xs={6}>
            <Card className="reviewCard payOnline" onClick={gotoOnline}>
              <Grid container spacing={2}>
                <Grid item>
                  <CardMedia
                    component="img"
                    loading="lazy"
                    src={icons.payonline}
                    alt=""
                    className="image-small"
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="h6" className="payVenue">
                    Pay Online
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid> */}
        </Grid>
        {/* <Card
          className="couponCard"
          onClick={handleClickOpen}
          style={{ background: couponColor }}
        >
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item className="first">
              <Grid container spacing={2}>
                <Grid item>
                  <CardMedia
                    component="img"
                    loading="lazy"
                    src={icons.coupen}
                    alt=""
                    className="image-medium"
                  />
                </Grid>
                <Grid className="black" item xs>
                  <Typography variant="h6">Apply Coupon Code</Typography>
                  <Typography variant="body2" className="exploreOffers">
                    Explore Offers
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="second">
              {showArrow ? (
                <CardMedia
                  component="img"
                  loading="lazy"
                  src={icons.coupenArrow}
                  alt=""
                  className="image-icon"
                />
              ) : (
                <CardMedia
                  component="img"
                  loading="lazy"
                  src={icons.close}
                  alt=""
                  className="image-icon"
                  onClick={handleCrossClick}
                />
              )}
            </Grid>
          </Grid>
        </Card> */}
        <textarea
          placeholder="Booking notes"
          value={bookingNotes}
          onChange={handleNotesChange}
        ></textarea>
      </Grid>
      <Grid item xs={12} md={4}></Grid>
    </>
  );
};
export default ReviewConfirm;
